import styled from 'styled-components';
import { PageWrapper } from 'pages/styles';
import { generateSpacing } from 'styles/spaces';
import TextInput from 'components/Input/TextInput';
import Textarea from 'components/Textarea';
import NeutralButton from 'components/NeutralButton';

export const ContactWrapper = styled(PageWrapper)`
  display: flex;
  flex-direction: column;
  max-width: 450px;
`;

export const FieldWrapper = styled.div`
  margin-bottom: ${generateSpacing(6)};
`;

export const ContactLabel = styled.label`
  display: block;
  margin-bottom: ${generateSpacing(6)};
  font-family: 'Montserrat', 'sans-serif';
  font-weight: 500;
`;

export const ContactInput = styled(TextInput)`
  width: 100%;
`;

export const ContactTextarea = styled(Textarea)`
  width: 100%;
  height: 180px;
  padding: ${generateSpacing(5, 7)};
`;

export const SubmitButton = styled(NeutralButton)`
  margin: ${generateSpacing(14, 0, 6)};
  width: 100%;
  font-family: 'Montserrat', 'sans-serif';
  font-weight: 500;
  font-size: 1.05rem;
`;

export const MoreContactsWrapper = styled.div`
  margin: ${generateSpacing(15)} auto 0;
  width: 270px;
  text-align: center;
`;

export const MoreContactsText = styled.div`
  margin-top: ${generateSpacing(4)};
`;

export const SuccessfulMessageWrapper = styled.div`
  background-color: #f8f8f8;
  padding: ${generateSpacing(5, 8)};
  border-radius: 8px;
  text-align: center;
`;

export const MarkIconWrapper = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-right: ${generateSpacing(5)};
  border-radius: 50%;
  width: 21px;
  height: 21px;
  font-size: 13px;
  background-color: #13ab87;
`;

export const SuccessfulMessageText = styled.span`
  font-family: 'Montserrat', 'sans-serif';
  font-weight: 500;
`;
