import React, { useCallback, useEffect, useRef } from 'react';
import { useFormikContext } from 'formik';
import NumberFormat from 'react-number-format';
import cep, { CEP } from 'cep-promise';
import { Zipcode } from 'contexts/CartContext';
import useDebounce from 'hooks/useDebounce';
import useCart from 'hooks/useCart';
import { Form } from '../../form';
import { FieldWrapper, PaymentInput, Divider } from '../../styles';
import PaymentField from '../PaymentField';

interface CepError {
  name: string;
  message: string;
  type: string;
  errors: { message: string; service: string }[];
}

type Props = {
  fieldSuffix?: string;
  zipcode?: string;
};

const Address = ({ fieldSuffix, zipcode }: Props) => {
  const { zipcode: cartZipcode } = useCart();
  const cartZipcodeRef = useRef<Zipcode | undefined>(undefined);
  const { values, setFieldValue, setFieldError } = useFormikContext<Form>();
  const currentZipcode = zipcode || values.zipcode;
  const zipcodeRef = useRef(currentZipcode);

  const getFieldName = useCallback(
    (name: string) => `${fieldSuffix ? `${fieldSuffix}.` : ''}${name}`,
    [fieldSuffix],
  );

  const debouncedCep = useDebounce(
    (newZipcode: string) =>
      cep(newZipcode)
        .then(({ state, street, city, neighborhood }: CEP) => {
          setFieldValue(getFieldName('address'), street);
          setFieldValue(getFieldName('state'), state);
          setFieldValue(getFieldName('city'), city);
          setFieldValue(getFieldName('neighborhood'), neighborhood);
        })
        .catch((error: CepError) => setFieldError(getFieldName('zipcode'), error.message)),
    1000,
  );

  useEffect(() => {
    if (!cartZipcode || cartZipcode === cartZipcodeRef.current) {
      return;
    }

    const { cep: newZipcode, street, state, city, neighborhood } = cartZipcode;
    setFieldValue(getFieldName('zipcode'), newZipcode);
    setFieldValue(getFieldName('address'), street);
    setFieldValue(getFieldName('state'), state);
    setFieldValue(getFieldName('city'), city);
    setFieldValue(getFieldName('neighborhood'), neighborhood);

    cartZipcodeRef.current = cartZipcode;
  }, [cartZipcode, getFieldName, setFieldValue]);

  useEffect(() => {
    if (zipcodeRef.current !== currentZipcode && currentZipcode.length >= 8) {
      debouncedCep(currentZipcode);
      zipcodeRef.current = currentZipcode;
    }
  }, [currentZipcode, debouncedCep]);

  return (
    <>
      <FieldWrapper>
        <PaymentField
          label="CEP"
          name={getFieldName('zipcode')}
          placeholder="00000-000"
          format="#####-###"
          mask="_"
          as={NumberFormat}
          customInput={PaymentInput}
        />
      </FieldWrapper>
      <FieldWrapper>
        <PaymentField
          label="Endereço"
          name={getFieldName('address')}
          as={PaymentInput}
          placeholder="Digite aqui seu endereço..."
        />
        <PaymentField
          label="Número"
          name={getFieldName('addressNumber')}
          as={PaymentInput}
          placeholder="Digite aqui seu número..."
        />
      </FieldWrapper>
      <FieldWrapper>
        <PaymentField
          label="Complemento (opcional)"
          name={getFieldName('additionalInfo')}
          as={PaymentInput}
          placeholder="Ex.: Sala 01"
        />
      </FieldWrapper>
      <FieldWrapper>
        <PaymentField
          label="Bairro"
          name={getFieldName('neighborhood')}
          as={PaymentInput}
          placeholder="Digite aqui seu bairro..."
        />
        <PaymentField
          label="Cidade"
          name={getFieldName('city')}
          as={PaymentInput}
          placeholder="Digite aqui sua cidade..."
        />
      </FieldWrapper>
      <FieldWrapper>
        <PaymentField
          label="Estado"
          name={getFieldName('state')}
          as={PaymentInput}
          placeholder="Digite aqui seu estado..."
        />
      </FieldWrapper>
      <Divider />
    </>
  );
};

Address.defaultProps = {
  fieldSuffix: undefined,
  zipcode: undefined,
};

export default Address;
