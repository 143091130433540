import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import UserMenu from 'components/UserMenu';
import useWindowDimensions from 'hooks/useWindowDimensions';
import useQueryParam from 'hooks/useQueryParam';
import useCart from 'hooks/useCart';
import useUser from 'hooks/useUser';
import useOutsideClick from 'hooks/useOutsideClick';

import SearchInput from '../../Input/Search';

import {
  NavBarContainer,
  LogoWrapper,
  SearchWrapper,
  CartWrapper,
  ProfileWrapper,
  ABLALogoImage,
  ABLANameImage,
  CartButton,
  CartIcon,
  CartText,
  ProfileIcon,
  ProfileText,
  ArrowDownIcon,
  LeftContent,
  RightContentWrapper,
  MenuWrapper,
  MenuBox,
} from './styles';

const RightContent = () => {
  const menuRef = useRef<HTMLDivElement>(null);
  const [showMenu, setShowMenu] = useState(false);
  const { count } = useCart();
  const { user } = useUser();
  const { isMobile } = useWindowDimensions();

  const closeMenu = () => setShowMenu(false);

  useOutsideClick<HTMLDivElement>(menuRef, closeMenu);

  const handleMenuOpening = () => setShowMenu(prevState => !prevState);

  if (isMobile) {
    return null;
  }

  return (
    <RightContentWrapper>
      <Link to="/carrinho">
        <CartWrapper>
          <CartButton>
            <CartIcon />
            <CartText>Carrinho ({count})</CartText>
          </CartButton>
        </CartWrapper>
      </Link>

      <MenuWrapper>
        <ProfileWrapper onClick={handleMenuOpening}>
          <ProfileIcon />
          <ProfileText>Olá, {user?.username}!</ProfileText>
          <ArrowDownIcon />
        </ProfileWrapper>
        <MenuBox isOpen={showMenu} ref={menuRef} onClick={closeMenu}>
          <UserMenu />
        </MenuBox>
      </MenuWrapper>
    </RightContentWrapper>
  );
};

const NavBar = () => {
  const [searchText, setSearchText] = useState('');
  const { isMobile } = useWindowDimensions();
  const setQueryParam = useQueryParam();

  const onSearch = (event: React.FormEvent) => {
    event.preventDefault();

    setQueryParam([{ search: searchText }], '/produtos');
  };

  const handleChangeOnSearchInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target?.value);
  };

  return (
    <NavBarContainer>
      <LeftContent>
        <Link to="/">
          <LogoWrapper>
            <ABLALogoImage />
            <ABLANameImage />
          </LogoWrapper>
        </Link>

        {!isMobile && (
          <SearchWrapper>
            <SearchInput
              value={searchText}
              onSearch={onSearch}
              onChange={handleChangeOnSearchInput}
              placeholder="Procure por código, nome, marca..."
            />
          </SearchWrapper>
        )}
      </LeftContent>
      <RightContent />
    </NavBarContainer>
  );
};

export default NavBar;
