interface PossibleLoading {
  isLoading: boolean;
}
export const defineIsLoading = (...objects: PossibleLoading[]): boolean =>
  objects.some(object => object.isLoading);

interface PossibleError {
  error: unknown;
}

export const getFirstError = (...objects: PossibleError[]): unknown =>
  objects.find(object => object.error);
