import styled from 'styled-components';
import NeutralButton from 'components/NeutralButton';
import { generateSpacing } from 'styles/spaces';

export const ButtonsWrapper = styled.div`
  margin-top: ${generateSpacing(25)};
  display: flex;
  justify-content: space-between;
`;

export const ClearButton = styled(NeutralButton)`
  width: 45%;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  height: 45px;
  font-size: 1rem;
`;
