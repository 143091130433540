import useModal from 'hooks/useFilterModal';
import useWindowDimensions from 'hooks/useWindowDimensions';
import React from 'react';
import { useLocation } from 'react-router-dom';
import ModalProvider from 'contexts/FilterModalContext';
import UserModal from 'components/UserModal';
import BottomTabItem, { BottomTabItemProps } from './BottomTabItem';
import { BottomTabItemContainer, IconWrapper, LabelWrapper, Label } from './BottomTabItem/styles';
import {
  BottomTabNavigationContainer,
  HomeImage,
  CartImage,
  HeartImage,
  SearchImage,
  HomeImageGray,
  CartImageGray,
  SearchImageGray,
  HeartImageGray,
  AccountImageGray,
  BottomTabNavigationItems,
} from './styles';

type Props = {
  children?: React.ReactNode;
};

const menuItems: Array<BottomTabItemProps> = [
  {
    route: '/',
    name: 'Início',
    Icon: <HomeImageGray />,
    SelectedIcon: <HomeImage />,
  },
  {
    route: '/carrinho',
    name: 'Carrinho',
    Icon: <CartImageGray />,
    SelectedIcon: <CartImage />,
  },
  {
    route: '/produtos',
    name: 'Busca',
    Icon: <SearchImageGray />,
    SelectedIcon: <SearchImage />,
  },
  {
    route: '/favoritos',
    name: 'Favoritos',
    Icon: <HeartImageGray />,
    SelectedIcon: <HeartImage />,
  },
];

const AccountMenuItem = () => {
  const { openModal } = useModal();

  return (
    <BottomTabItemContainer onClick={() => openModal()}>
      <IconWrapper>
        <AccountImageGray />
      </IconWrapper>
      <LabelWrapper>
        <Label isFocused={false}>Conta</Label>
      </LabelWrapper>
    </BottomTabItemContainer>
  );
};

const BottomTabNavigation = ({ children }: Props) => {
  const { isMobile } = useWindowDimensions();
  const location = useLocation();
  function getCurrentTabFocused(route: string) {
    return route === location.pathname;
  }
  const mappedMenuItems = menuItems.map(item => (
    <BottomTabItem {...item} key={item.name} isFocused={getCurrentTabFocused(item.route)} />
  ));

  if (!isMobile) {
    return null;
  }

  return (
    <ModalProvider>
      <UserModal />
      <BottomTabNavigationContainer>
        {children}
        <BottomTabNavigationItems>
          {mappedMenuItems}
          <AccountMenuItem />
        </BottomTabNavigationItems>
      </BottomTabNavigationContainer>
    </ModalProvider>
  );
};

BottomTabNavigation.defaultProps = {
  children: undefined,
};

export default BottomTabNavigation;
