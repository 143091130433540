import styled from 'styled-components';
import { generateSpacing } from 'styles/spaces';

export const RecommendedProductsContainer = styled.div`
  width: 100%;

  padding-bottom: ${generateSpacing(3)};
  /* background-color: red; */
`;
export const SectionTitleWrapper = styled.div`
  margin-bottom: 23px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: 900px) {
    flex-direction: column;
  }
`;
export const RecommendedProductModeSelectorWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  @media (max-width: 900px) {
    margin-top: 8px;
  }
`;
