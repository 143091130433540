import WooCommerceRestApi from '@woocommerce/woocommerce-rest-api';

const axiosConfig = {
  transformRequest: [
    (data: any, headers: { 'User-Agent': any }) => {
      if (headers['User-Agent']) {
        // eslint-disable-next-line no-param-reassign
        delete headers['User-Agent'];
      }

      return data;
    },
  ],
};

const api = new WooCommerceRestApi({
  url: process.env.REACT_APP_WOOCOMERCE_URL as string,
  consumerKey: process.env.REACT_APP_WOOCOMERCE_CONSUMER_KEY as string,
  consumerSecret: process.env.REACT_APP_WOOCOMERCE_CONSUMER_SECRET as string,
  version: 'wc/v3',
  axiosConfig,
  queryStringAuth: true,
});

export default api;
