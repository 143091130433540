import styled from 'styled-components';
import FormButton from 'components/FormButton';
import colors from 'styles/colors';
import { CustomRadio, RadioLabel } from 'components/Radio/styles';
import { generateSpacing } from 'styles/spaces';

export const RadioWrapper = styled(FormButton)`
  margin-bottom: ${generateSpacing(5)};
  padding: 20px 10px;
  border: ${({ isChecked }: { isChecked?: boolean }) =>
    isChecked ? `1px solid ${colors.focusedBorder}` : 'none'};

  width: 100%;
`;

export const CategoryRadioLabel = styled(RadioLabel)`
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.8rem;
  line-height: 15px;
`;

export const CategoryCustomRadio = styled(CustomRadio)`
  margin: 0;
`;
