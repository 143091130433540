/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { Field, Formik, FormikHelpers, useFormikContext } from 'formik';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import useCart from 'hooks/useCart';
import useUser from 'hooks/useUser';
import useModal from 'hooks/useFilterModal';
import useWindowDimensions from 'hooks/useWindowDimensions';
import ModalProvider from 'contexts/FilterModalContext';
import WooCommerceApi from 'woocommerceApi/base';
import { defineIsLoading, getFirstError } from 'utils/useQueryUtils';
import unnormalizeOrder from 'utils/unnormalizeOrder';
import { CheckboxFormik } from 'components/Checkbox';
import TotalPriceTab from 'components/TotalPriceTab';
import NestedHeader from 'components/Header/NestedHeader';
import { BuyButton } from 'components/TotalPriceTab/styles';
import { BottomTabNavigationContainer } from 'components/BottomTabNavigation/styles';
import ScreenLoading from 'pages/Home/components/ScreenLoading';
import { PaymentMethod, Product, NormalizedProduct } from '../../@types/woocommerce';
import {
  TitleWrapper,
  Title,
  PaymentForm,
  FieldsWrapper,
  FieldWrapper,
  Divider,
  PaymentTextarea,
  PaymentPageWrapper,
  PaymentRadio,
  BuyButtonWrapper,
  PaymentLabel,
} from './styles';
import Personal from './components/Personal';
import Address from './components/Address';
import Contact from './components/Contact';
import ResumeSection from './components/ResumeSection';
import ShippingAddress from './components/ShippingAddress';
import ResumeModal from './components/ResumeModal';
import { Form, initialValues, schema } from './form';

const PaymentBuyButton = () => {
  const { openModal, isOpen } = useModal();
  const { submitForm, isSubmitting } = useFormikContext<Form>();

  const handleOnClick = isOpen ? submitForm : () => openModal();

  return (
    <BuyButtonWrapper type="button" onClick={handleOnClick} disabled={isSubmitting}>
      <BuyButton>{isOpen ? 'FINALIZAR COMPRA' : 'CONTINUAR'}</BuyButton>
    </BuyButtonWrapper>
  );
};

const Payment = () => {
  const { isMobile } = useWindowDimensions();
  const { items, count, coupon, emptyCart, reduceFetchedProducts } = useCart();
  const history = useHistory();
  const { user } = useUser();
  const paymentMethodQuery = useQuery<PaymentMethod[]>('paymentMethods', () =>
    WooCommerceApi.get('payment_gateways').then(response => response.data),
  );
  const itemIds = items.map(({ productId }) => productId);

  const productsQuery = useQuery<Product[]>(['cartProducts', count], () =>
    count === 0
      ? []
      : WooCommerceApi.get('products', { include: itemIds }).then(response => response.data),
  );

  const isLoading = defineIsLoading(paymentMethodQuery, productsQuery);
  const error = getFirstError(paymentMethodQuery, productsQuery);

  if (isLoading || error) {
    return <ScreenLoading />;
  }

  const paymentMethodsData = paymentMethodQuery.data || [];
  const products = productsQuery.data || [];

  const transformedItems = items.reduce<{ product: NormalizedProduct; quantity: number }[]>(
    (acc, item) => reduceFetchedProducts(acc, item, products),
    [],
  );

  const paymentMethodRadios = paymentMethodsData.reduce<React.ReactNode[]>(
    (acc, { id, title, enabled }) => {
      if (!enabled) {
        return [...acc];
      }
      return [...acc, <PaymentRadio key={id} name="paymentMethod" value={id} labelText={title} />];
    },
    [],
  );

  const handleSubmit = (values: Form, { setSubmitting }: FormikHelpers<Form>) => {
    const paymentMethodObject = paymentMethodsData.find(({ id }) => id === values.paymentMethod);
    const paymentTitle = paymentMethodObject?.title || '';
    const data = unnormalizeOrder({
      ...values,
      cartItems: items,
      paymentTitle,
      coupon,
      customerId: user?.id || 0,
    });

    WooCommerceApi.post('orders', data)
      .then(() => {
        history.push(`/pedido-sucesso?email=${values.email}`);
        emptyCart();
        setSubmitting(false);
      })
      .catch(e => console.warn(e));
  };

  return (
    <ModalProvider>
      {isMobile && <NestedHeader name="Faturamento" />}
      <PaymentPageWrapper>
        <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={schema}>
          <PaymentForm>
            <FieldsWrapper>
              {!isMobile && (
                <TitleWrapper>
                  <Title>Faturamento</Title>
                </TitleWrapper>
              )}
              <FieldWrapper>{paymentMethodRadios}</FieldWrapper>
              <Divider />
              <Personal />
              <Address />
              <Contact />
              <FieldWrapper>
                <label htmlFor="hasShippingAddress">
                  <CheckboxFormik id="hasShippingAddress" name="hasShippingAddress" />
                  Entregar para um endereço diferente?
                </label>
              </FieldWrapper>
              <ShippingAddress />
              <PaymentLabel htmlFor="orderNotes">Nota do pedido (opcional)</PaymentLabel>
              <Field
                id="orderNotes"
                name="orderNotes"
                as={PaymentTextarea}
                placeholder="Escreva aqui as observações do pedido"
              />
            </FieldsWrapper>
            {!isMobile && <ResumeSection products={transformedItems} />}
            <ResumeModal products={transformedItems} />
            {isMobile && (
              <BottomTabNavigationContainer>
                <TotalPriceTab products={transformedItems} actionComponent={<PaymentBuyButton />} />
              </BottomTabNavigationContainer>
            )}
          </PaymentForm>
        </Formik>
      </PaymentPageWrapper>
    </ModalProvider>
  );
};

export default Payment;
