import React from 'react';
import { StyledComponentProps, DefaultTheme } from 'styled-components';
import { InputWrapper, Input } from './styles';

type Props = {
  inputStyles?: React.CSSProperties;
};

const TextInput = <T extends { type: 'text' | 'number' | 'password' }>({
  className,
  inputStyles,
  ...props
}: StyledComponentProps<'input', DefaultTheme, T, 'type'> & Props) => (
  <InputWrapper className={className}>
    <Input {...props} style={inputStyles} />
  </InputWrapper>
);

TextInput.defaultProps = {
  inputStyles: {},
};

export default TextInput;
