import React from 'react';
import BadgeItem from './BadgeItem';

import { BadgesContainer } from './styles';

const Badges = () => {
  return (
    <BadgesContainer>
      <BadgeItem icon="truck" title="Entrega grátis" subtitle="Pedidos acima de R$499,00" />
      <BadgeItem
        icon="return"
        title="7 dias de Retorno Grátis"
        subtitle="Caso algum produto chegue
        com problema"
      />
      <BadgeItem icon="shield" title="Pagamento Protegido" subtitle="100% pagamento seguro" />
      <BadgeItem icon="suport" title="24/7 Suporte" subtitle="Suporte dedicado 24 horas" />
    </BadgesContainer>
  );
};

export default Badges;
