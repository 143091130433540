import React from 'react';
import { SectionTitle } from 'styles/typographies';
import Carousel from 'components/Carousel';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { ClientsFeedbackContainer, SectionTitleWrapper, ProductsWrapper } from './styles';
import ClientFeedbackItem, { ClientFeedbackItemProps } from './ClientFeedbackItem';

interface ClientsFeedbackProps {
  testimonies: Array<{
    imageUrl: string;
    title: string;
    subtitle: string;
    content: string;
  }>;
}
const ClientsFeedback = ({ testimonies }: ClientsFeedbackProps) => {
  const { isMobile } = useWindowDimensions();
  const filteredTestimonies = testimonies.slice(0, 2);
  const mappedClientsFeedback = filteredTestimonies.map(testimony => (
    <ClientFeedbackItem key={testimony.title} {...testimony} />
  ));
  const renderSlideItem = (testimony: ClientFeedbackItemProps) => (
    <ClientFeedbackItem key={testimony.title} {...testimony} />
  );
  return (
    <ClientsFeedbackContainer>
      <SectionTitleWrapper>
        <SectionTitle>O que as locadoras estão dizendo</SectionTitle>
      </SectionTitleWrapper>
      {isMobile ? (
        <Carousel data={filteredTestimonies} renderItem={renderSlideItem} />
      ) : (
        <ProductsWrapper>{mappedClientsFeedback}</ProductsWrapper>
      )}
    </ClientsFeedbackContainer>
  );
};

export default ClientsFeedback;
