import React, { useState, useEffect, useRef } from 'react';
import Modal from 'react-modal';
import { useInfiniteQuery } from 'react-query';
import useModal from 'hooks/useFilterModal';
import WooCommerceRestApi from 'woocommerceApi/base';
import useCompare from 'hooks/useCompare';
import useWindowDimensions from 'hooks/useWindowDimensions';
import normalizeProduct from 'utils/normalizeProduct';
import { SectionTitle } from 'styles/typographies';
import SearchInput from 'components/Input/Search';
import { Spinner } from 'pages/Home/components/ScreenLoading/styles';
import { SpinnerCentralized } from 'pages/ProductPage/components/Ratings/styles';
import { SeeMoreProductsButton, NotFoundText } from 'pages/Products/styles';
import { ReactComponent as PlusIcon } from 'assets/icons/icon_plus_large.svg';
import { Headers, Product } from '../../../@types/woocommerce';
import ProductItem from '../ProductItem';
import {
  EmptyProductWrapper,
  SectionTitleWrapper,
  ProductsList,
  SearchWrapper,
  AddProduct,
  PlusWrapper,
} from './styles';

Modal.setAppElement('#root');

type Props = {
  onClickItem: (productId: number) => void;
};

const ProductSearch = ({ onClickItem }: Props) => {
  const { product } = useCompare();
  const [searchText, setSearchText] = useState('');
  const hasSubmittedRef = useRef(false);

  const fetchProducts = ({ pageParam = 1 }): any =>
    WooCommerceRestApi.get('products', {
      search: searchText,
      per_page: 15,
      page: pageParam,
    }).then(response => {
      hasSubmittedRef.current = true;
      return { ...response, pageParam };
    });

  const {
    data,
    isLoading,
    error,
    refetch,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery<{
    headers: Headers;
    data: Product[];
    pageParam: number;
  }>('searchCompareProducts', fetchProducts, {
    enabled: false,
    cacheTime: 0,
    getNextPageParam: ({ pageParam, headers }) =>
      headers['x-wp-totalpages'] > pageParam ? pageParam + 1 : undefined,
  });

  const transformedProducts =
    data?.pages?.flatMap(({ data: page }) => page.map(normalizeProduct)) || [];
  const mappedProducts = transformedProducts.reduce<JSX.Element[]>((acc, actualProduct) => {
    const { id } = actualProduct;
    if (product?.id === actualProduct.id) {
      return [...acc];
    }

    return [
      ...acc,
      <ProductItem key={id} product={actualProduct} onClick={() => onClickItem(id)} />,
    ];
  }, []);

  const hasLoaded = !!(error || isLoading);

  const handleChangeOnSearchInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target?.value);
  };

  const onSearch = (event: React.FormEvent) => {
    event.preventDefault();

    if (searchText) {
      refetch();
    }
  };

  return (
    <>
      <SearchWrapper>
        <SearchInput
          onChange={handleChangeOnSearchInput}
          onSearch={onSearch}
          placeholder="Procure por código, nome, marca..."
        />
      </SearchWrapper>
      <ProductsList>
        {hasLoaded ? (
          <SpinnerCentralized>
            <Spinner />
          </SpinnerCentralized>
        ) : (
          <>
            {mappedProducts}
            {hasNextPage && !isFetchingNextPage ? (
              <SeeMoreProductsButton onClick={() => fetchNextPage()}>
                Veja mais
              </SeeMoreProductsButton>
            ) : null}
            {!mappedProducts.length && searchText && hasSubmittedRef.current ? (
              <div>
                <NotFoundText>Nenhum resultado foi encontrado</NotFoundText>
              </div>
            ) : null}
          </>
        )}
      </ProductsList>
      {isFetchingNextPage && (
        <SpinnerCentralized>
          <Spinner />
        </SpinnerCentralized>
      )}
    </>
  );
};

const SearchProductBox = ({ onClickItem }: Props) => {
  const { setModalStyles } = useModal();

  const componentHeight = '400px';
  useEffect(() => {
    setModalStyles(({ overlay, content }) => ({
      overlay,
      content: {
        ...content,
        transform: `translateY(-${componentHeight})`,
        height: componentHeight,
      },
    }));
  }, [setModalStyles]);

  return <ProductSearch onClickItem={onClickItem} />;
};

const ResponsiveProductSearch = ({ onClickItem }: Props) => {
  const { width } = useWindowDimensions();
  const {
    isOpen,
    modalStyles: { overlay, content },
    openModal,
    closeModal,
  } = useModal();

  const modalStyles = { overlay: { ...overlay, zIndex: 1002 }, content };

  if (width > 600) {
    return <ProductSearch onClickItem={onClickItem} />;
  }

  return (
    <>
      <EmptyProductWrapper onClick={() => openModal()}>
        <PlusWrapper>
          <PlusIcon />
        </PlusWrapper>
        <AddProduct>ADICIONAR PRODUTO</AddProduct>
      </EmptyProductWrapper>
      <Modal
        isOpen={isOpen}
        onRequestClose={() => closeModal()}
        style={modalStyles}
        closeTimeoutMS={350}
      >
        <SectionTitleWrapper>
          <SectionTitle>Adicionar produto 2</SectionTitle>
        </SectionTitleWrapper>
        <SearchProductBox onClickItem={onClickItem} />
      </Modal>
    </>
  );
};

export default ResponsiveProductSearch;
