import styled from 'styled-components';
import ButtonReset from 'styles/buttonReset';
import { generateSpacing } from 'styles/spaces';

export const CategoriesHeader = styled.div`
  margin-bottom: ${generateSpacing(16)};
`;

export const CategoryItem = styled(ButtonReset)<{ isSelected: boolean }>`
  display: block;
  margin-bottom: ${generateSpacing(8)};
  border-radius: 8px;
  padding: 5px 15px 5px 5px;
  background-color: ${({ isSelected }) =>
    isSelected ? 'rgba(196, 196, 196, 0.2)' : 'transparent'};

  &:hover {
    background-color: rgba(196, 196, 196, 0.2);
  }
`;
