import styled from 'styled-components';
import buttonReset from 'styles/buttonReset';
import { generateSpacing } from 'styles/spaces';
import LogoutIconSVG from 'assets/icons/icon_logout.svg';
import PhoneIconSVG from 'assets/icons/icon_phone.svg';
import HeartIconSVG from 'assets/icons/icon_heart.svg';

export const MenuItem = styled(buttonReset)`
  margin-bottom: ${generateSpacing(10)};
  width: 100%;
  text-align: start;

  & * {
    vertical-align: middle;
  }

  @media (max-width: 900px) {
    font-size: 1rem;
  }
`;

export const MenuIcon = styled.img`
  margin-right: ${generateSpacing(8)};
`;

export const LogoutIcon = styled(MenuIcon).attrs({ src: LogoutIconSVG })``;

export const PhoneIcon = styled(MenuIcon).attrs({ src: PhoneIconSVG })``;

export const HeartIcon = styled(MenuIcon).attrs({ src: HeartIconSVG })``;
