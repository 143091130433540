import styled from 'styled-components';
import FormatNumber from 'react-number-format';
import colors from 'styles/colors';
import { generateSpacing } from 'styles/spaces';
import { detailsText, SectionTitle } from 'styles/typographies';

export const ProductInfoSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  min-height: 550px;

  margin-bottom: ${generateSpacing(30)};
`;

export const ProductImagesInfo = styled.div`
  flex: 11;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ProductTextInfo = styled.div`
  flex: 9 1 220px;
  display: flex;
  flex-direction: column;

  @media (min-width: 700px) {
    flex: 9;
  }
`;

export const ProductName = styled(SectionTitle)`
  font-weight: 500;
  font-size: 1.5rem;
  color: ${colors.emptyText};
`;

export const InfoTextWrapper = styled.div`
  margin-bottom: ${generateSpacing(3)};
`;

export const DetailsText = styled.span`
  ${detailsText}
`;

export const SubSectionWrapper = styled.div`
  margin-top: ${generateSpacing(10)};
`;

export const PriceText = styled(FormatNumber)`
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 1.4rem;
`;

export const ProductRegularPriceText = styled(FormatNumber)`
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 0.9rem;
  color: #c4c4c4;
  text-decoration-line: line-through;
  margin-left: 2px;
`;

export const InjectedHTMLWrapper = styled.div`
  * {
    color: ${colors.emptyText};
  }

  h3 {
    margin-bottom: ${generateSpacing(7)};
  }

  img {
    width: 100%;
    height: auto;
    margin-bottom: ${generateSpacing(12)};
  }
`;

export const Description = styled(InjectedHTMLWrapper)`
  margin: 0 auto;
  width: 80%;
`;

export const AddCartSubSection = styled.div`
  margin: ${generateSpacing(13, 0)};
`;
