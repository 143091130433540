import { BaseButton } from 'components/Button';
import styled from 'styled-components';
import { generateSpacing } from 'styles/spaces';

export const FeaturedProductsContainer = styled.div`
  width: 100%;

  padding-bottom: ${generateSpacing(3)};
  /* background-color: red; */
`;
export const SectionTitleWrapper = styled.div`
  margin-bottom: 23px;
`;
export const ProductsWrapper = styled.div`
  margin-bottom: 23px;
  display: flex;
  justify-content: space-evenly;

  @media (max-width: 900px) {
    flex-direction: column;
    align-items: center;
  }
`;
export const FeaturedProductItemContainer = styled(BaseButton)`
  flex: 1;
  max-width: 500px;

  @media (max-width: 900px) {
    margin-bottom: 16px;
  }
`;
export const FeaturedProductImage = styled.img`
  width: 100%;
`;
