import styled from 'styled-components';
import buttonReset from 'styles/buttonReset';
import { generateSpacing } from 'styles/spaces';
import { SectionTitle } from 'styles/typographies';

export const NestedHeaderWrapper = styled.div`
  padding: ${generateSpacing(10, 13, 0)};
  line-height: 0;
`;

export const ArrowLeftIconWrapper = styled(buttonReset)`
  margin-right: ${generateSpacing(5)};
  font-size: 20px;

  & * {
    vertical-align: middle;
  }
`;

export const HeaderText = styled(SectionTitle)`
  font-size: 1.35rem;
`;
