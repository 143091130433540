import styled from 'styled-components';
import { generateSpacing } from '../../../styles/spaces';

import TruckIconSVG from '../../../assets/icons/icon_truck.svg';
import PhoneIconSVG from '../../../assets/icons/icon_phone_yellow.svg';

import { BoldText, RegularText } from '../../../styles/typographies';

export const TopHeaderContainer = styled.div`
  width: 100%;
  padding: ${generateSpacing(5, 7)};

  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  background: #111111;

  @media (max-width: 900px) {
    justify-content: center;
    flex-direction: column;
    text-align: center;
  }
`;

export const LeftContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const TruckIcon = styled.img.attrs({
  src: TruckIconSVG,
})`
  width: 34px;
  height: 21.75px;
`;

export const TextWrapper = styled.div`
  margin-left: ${generateSpacing(6)};
`;
export const Text = styled(RegularText)`
  color: #fff;
`;
export const BoldedText = styled(BoldText)`
  color: #fff;
`;

export const CenterContent = styled.div`
  margin-left: ${generateSpacing(6)};
  margin-right: ${generateSpacing(6)};
`;

export const PointIcon = styled.div`
  width: ${generateSpacing(2)};
  height: ${generateSpacing(2)};
  border-radius: ${generateSpacing(1)};

  background: #fcf051;
`;

export const PhoneIcon = styled.img.attrs({
  src: PhoneIconSVG,
})`
  width: 24px;
  height: 24px;
`;
