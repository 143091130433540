import styled from 'styled-components';
import { generateSpacing } from 'styles/spaces';
import PhoneIconSVG from '../../assets/icons/icon_phone.svg';

export const FooterContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: #f8f8f8;
  padding: ${generateSpacing(16, 1)};

  @media (max-width: 900px) {
    padding-bottom: 96px;
  }
`;
export const ContentWrapper = styled.div`
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 12px 0;

  @media (max-width: 900px) {
    align-items: center;
    flex-direction: column-reverse;
  }
`;
export const CompanyInfo = styled.span`
  @media (max-width: 900px) {
    text-align: center;
  }
`;
export const PhoneSalesWrapper = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 900px) {
    margin-bottom: 16px;
  }
`;
export const PhoneImage = styled.img.attrs({
  src: PhoneIconSVG,
})``;
export const PhoneSalesTitle = styled.span`
  margin-left: 8px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: 1rem;
  margin-right: 2px;
`;
export const PhoneSalesText = styled.span``;
