import React from 'react';

import { BannerButtonWrapper, BannerContainer, SmallBannerImage } from './styles';

const SmallBanner = ({ bannerSrc }: { bannerSrc: string }) => {
  return (
    <BannerContainer>
      <BannerButtonWrapper>
        <SmallBannerImage src={bannerSrc} />
      </BannerButtonWrapper>
    </BannerContainer>
  );
};

export default SmallBanner;
