import { CartItem } from 'contexts/CartContext';
import { Form } from 'pages/Payment/form';

type UnnormalizeOrderArgs = Form & {
  customerId: number;
  cartItems: CartItem[];
  paymentTitle: string;
  coupon: { code: string; amount: number } | undefined;
};

const normalizeCartItem = ({ productId, quantity }: CartItem) => ({
  product_id: productId,
  quantity,
});

const unnormalizeOrder = ({
  customerId,
  email,
  name,
  lastName,
  company,
  cnpj,
  address,
  addressNumber,
  additionalInfo,
  zipcode,
  neighborhood,
  city,
  state,
  telephone,
  cellphone,
  cartItems,
  paymentMethod,
  paymentTitle,
  orderNotes,
  hasShippingAddress,
  shipping,
  termsOfAcceptance,
  coupon,
}: UnnormalizeOrderArgs) => {
  let unnormalizedShipping = {
    country: 'BR',
    first_name: name,
    last_name: lastName,
    address_1: address,
    address_2: additionalInfo,
    postcode: zipcode,
    city,
    state,
    company,
  };
  const billing = { ...unnormalizedShipping, phone: telephone, email };

  if (hasShippingAddress) {
    unnormalizedShipping = {
      country: 'BR',
      first_name: shipping.name,
      last_name: shipping.lastName,
      address_1: shipping.address,
      address_2: shipping.additionalInfo,
      postcode: shipping.zipcode,
      city: shipping.city,
      state: shipping.state,
      company: shipping.company,
    };
  }

  const metaData = [
    { key: '_billing_cellphone', value: cellphone },
    { key: '_billing_cnpj', value: cnpj },
    { key: '_billing_neighborhood', value: neighborhood },
    { key: '_billing_number', value: addressNumber },
    {
      key: '_shipping_neighborhood',
      value: hasShippingAddress ? shipping.neighborhood : neighborhood,
    },
    {
      key: '_shipping_number',
      value: hasShippingAddress ? shipping.addressNumber : addressNumber,
    },
  ];

  const shippingLines = [
    {
      method_id: 'free_shipping',
      method_title: 'Frete grátis',
      total: '0.00',
    },
  ];

  const lineItems = cartItems.map(normalizeCartItem);
  const couponLines = coupon ? [coupon] : [];

  return {
    currency: 'BRL',
    set_paid: false,
    customer_id: customerId,
    payment_method: paymentMethod,
    payment_method_title: paymentTitle,
    billing,
    shipping: unnormalizedShipping,
    shipping_lines: shippingLines,
    meta_data: metaData,
    customer_note: orderNotes,
    line_items: lineItems,
    coupon_lines: couponLines,
  };
};

export default unnormalizeOrder;
