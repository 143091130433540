import styled from 'styled-components';
import { BottomTabNavigationContainer } from 'components/BottomTabNavigation/styles';
import NumberFormat from 'react-number-format';

export const BottomTabProduct = styled(BottomTabNavigationContainer)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const BottomTabPrice = styled(NumberFormat)`
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 1.1rem;
`;
