import React from 'react';
import WooCommerceRestApi from 'woocommerceApi/base';
import { useQuery, useInfiniteQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import ProductSlideItem from 'components/ProductSlideItem';
import FilterModalProvider from 'contexts/FilterModalContext';
import Badges from 'components/Badges';
import normalizeProducts from 'utils/normalizeProduct';
import { Category, Headers, Product } from '../../@types/woocommerce';
import { defineIsLoading, getFirstError } from '../../utils/useQueryUtils';
import { Spinner } from '../Home/components/ScreenLoading/styles';
import SearchHeader from './components/SearchHeader';
import Categories from './components/Categories';
import PriceFilter from './components/PriceFilter';
import {
  ProductsWrapper,
  Sidebar,
  Body,
  ProductsLoadingWrapper,
  ProductsList,
  SeeMoreProductsButton,
  BadgesWrapper,
  NotFoundText,
} from './styles';

export type PageProps = {
  categories: Category[];
  currentCategory: Category | undefined;
  mappedProducts: JSX.Element[];
  searchQuery: string | null;
  hasLoaded: boolean;
  isFetchingNextPage: boolean;
  hasNextPage: boolean | undefined;
  fetchNextPage: () => void;
};

export const ProductsPage = ({ children }: { children: (props: PageProps) => React.ReactNode }) => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const fetchProducts = ({ pageParam = 1 }) =>
    WooCommerceRestApi.get('products', {
      ...Object.fromEntries(query.entries()),
      per_page: 15,
      page: pageParam,
    }).then(response => ({ ...response, pageParam }));

  const productsQuery = useInfiniteQuery<{ headers: Headers; data: Product[]; pageParam: number }>(
    ['searchProducts', query.toString()],
    fetchProducts,
    {
      getNextPageParam: ({ pageParam, headers }) =>
        headers['x-wp-totalpages'] > pageParam ? pageParam + 1 : undefined,
    },
  );
  const categoriesQuery = useQuery<Category[]>('categories', () =>
    WooCommerceRestApi.get('products/categories', { per_page: 100 }).then(
      response => response.data,
    ),
  );

  const isLoading = defineIsLoading(productsQuery, categoriesQuery);
  const error = getFirstError(productsQuery, categoriesQuery);

  const { data, hasNextPage, fetchNextPage, isFetchingNextPage } = productsQuery;
  const hasLoaded = !!(error || isLoading);

  const categories = categoriesQuery.data || [];

  const transformedProducts =
    data?.pages?.flatMap(({ data: page }) => page.map(normalizeProducts)) || [];
  const mappedProducts = transformedProducts.map(product => (
    <ProductSlideItem key={product.id} product={product} />
  ));

  const searchQuery = query.get('search');
  const currentCategoryId = parseInt(query.get('category') || '0', 10);
  const currentCategory = categories.find(({ id }) => currentCategoryId === id);

  const pageProps = {
    hasNextPage,
    categories,
    currentCategory,
    searchQuery,
    hasLoaded,
    isFetchingNextPage,
    mappedProducts,
    fetchNextPage: () => fetchNextPage(),
  };

  return <FilterModalProvider>{children(pageProps)}</FilterModalProvider>;
};

export const ProductsLoading = () => (
  <ProductsLoadingWrapper>
    <Spinner />
    <span>Carregando...</span>
  </ProductsLoadingWrapper>
);

const Products = () => (
  <ProductsPage>
    {({
      categories,
      currentCategory,
      searchQuery,
      hasLoaded,
      isFetchingNextPage,
      mappedProducts,
      hasNextPage,
      fetchNextPage,
    }) => (
      <>
        <ProductsWrapper>
          <Sidebar>
            <Categories categories={categories} />
            <PriceFilter />
          </Sidebar>
          <Body>
            <SearchHeader categoryName={currentCategory?.name} search={searchQuery} />
            {hasLoaded ? <ProductsLoading /> : <ProductsList>{mappedProducts}</ProductsList>}
            {isFetchingNextPage && <ProductsLoading />}
            {hasNextPage && !isFetchingNextPage ? (
              <SeeMoreProductsButton onClick={fetchNextPage}>Veja mais</SeeMoreProductsButton>
            ) : null}
            {!hasLoaded && !mappedProducts.length ? (
              <div>
                <NotFoundText>Nenhum resultado foi encontrado</NotFoundText>
              </div>
            ) : null}
          </Body>
        </ProductsWrapper>
        <BadgesWrapper>
          <Badges />
        </BadgesWrapper>
      </>
    )}
  </ProductsPage>
);

export default Products;
