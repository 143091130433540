import React from 'react';
import NumberFormat from 'react-number-format';
import { FieldWrapper, PaymentInput, Divider } from '../../styles';
import PaymentField from '../PaymentField';

const Personal = () => (
  <>
    <FieldWrapper>
      <PaymentField
        label="Nome"
        name="name"
        as={PaymentInput}
        placeholder="Digite aqui seu nome..."
      />
      <PaymentField
        label="Sobrenome"
        name="lastName"
        as={PaymentInput}
        placeholder="Digite aqui seu sobrenome..."
      />
    </FieldWrapper>
    <FieldWrapper>
      <PaymentField
        label="Nome da empresa (opcional)"
        name="company"
        as={PaymentInput}
        placeholder="Digite aqui o nome da empresa..."
      />
    </FieldWrapper>
    <FieldWrapper>
      <PaymentField
        label="CNPJ"
        name="cnpj"
        placeholder="00.000.000/0001-00"
        format="##.###.###/####-##"
        mask="_"
        as={NumberFormat}
        customInput={PaymentInput}
      />
    </FieldWrapper>
    <Divider />
  </>
);

export default Personal;
