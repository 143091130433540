import React from 'react';
import ReactModal from 'react-modal';
import { getType } from 'typesafe-actions';
import { openModalActions, ModalEvent } from 'contexts/FilterModalContext';
import useFilterModal from 'hooks/useFilterModal';
import PriceFilterModal from './PriceFilterModal';
import CategoriesFilterModal from './CategoriesFilterModal';

ReactModal.setAppElement('#root');

const renderModal = (modal?: ModalEvent) => {
  if (!modal) {
    return null;
  }

  switch (modal.type) {
    case getType(openModalActions.openCategoryFilter):
      return <CategoriesFilterModal categories={modal.payload} />;
    case getType(openModalActions.openPriceFilter):
      return <PriceFilterModal />;
    default:
      return null;
  }
};

const FilterModal = (props: Partial<ReactModal.Props>) => {
  const { modal, isOpen, closeModal, modalStyles, hasAnimation, setModal } = useFilterModal();
  const filterModalStyles = {
    overlay: { ...modalStyles.overlay, zIndex: 1001 },
    content: modalStyles.content,
  };

  const resetModal = () => setModal(undefined);

  return (
    <ReactModal
      {...props}
      isOpen={isOpen}
      onRequestClose={() => closeModal()}
      onAfterClose={resetModal}
      style={filterModalStyles}
      closeTimeoutMS={hasAnimation ? 350 : 0}
    >
      {renderModal(modal)}
    </ReactModal>
  );
};

export default FilterModal;
