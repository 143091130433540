import React from 'react';
import useCart from 'hooks/useCart';
import { ResponsiveActionButton } from './styles';

type Props = {
  productId: number;
  quantity: number;
  quantityOnChange: (quantity: number) => void;
};

const CartButton = ({ productId, quantity, quantityOnChange }: Props) => {
  const { upsertItem } = useCart();

  const handleClickOnCartButton = () => {
    if (!quantity) {
      quantityOnChange(1);
    }
    upsertItem({ productId, quantity: quantity || 1 });
  };

  return (
    <ResponsiveActionButton type="button" onClick={handleClickOnCartButton}>
      ADICIONAR AO CARRINHO
    </ResponsiveActionButton>
  );
};

export default CartButton;
