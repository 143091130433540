import React, { useState } from 'react';
import NumberFormat from 'react-number-format';
import useCart from 'hooks/useCart';
import WooCommerceApi from 'woocommerceApi/base';
import { ReactComponent as MarkIcon } from 'assets/icons/check_mark_white.svg';
import { Coupon } from '../../../../@types/woocommerce';
import {
  ActionSection,
  ActionInput,
  ActionTitle,
  ActionInputLabel,
  ActionSectionButton,
  ErrorText,
} from '../../styles';
import { CouponInfoText, CouponInfoWrapper, MarkIconWrapper } from './styles';

const CouponInfo = () => {
  const { coupon } = useCart();

  if (!coupon) {
    return null;
  }

  const { amount, discountType } = coupon;

  return (
    <CouponInfoWrapper>
      <CouponInfoText>
        <MarkIconWrapper>
          <MarkIcon />
        </MarkIconWrapper>
        {discountType ? (
          `${amount}%`
        ) : (
          <NumberFormat
            value={amount}
            displayType="text"
            thousandSeparator="."
            decimalSeparator=","
            prefix="R$ "
            fixedDecimalScale
            decimalScale={2}
          />
        )}{' '}
        de desconto aplicado
      </CouponInfoText>
    </CouponInfoWrapper>
  );
};

const CouponSection = () => {
  const [couponText, setCouponText] = useState('');
  const [error, setError] = useState<string>();
  const { coupon, setCoupon } = useCart();

  const handleCouponInputOnChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setCouponText(event.target.value);

  const handleButtonOnClick = () => {
    WooCommerceApi.get('coupons', { code: couponText }).then(({ data }: { data: Coupon[] }) => {
      if (!data.length) {
        setError('O código não existe');
        return;
      }

      const couponData = data[0];
      const { amount, code } = couponData;
      setCoupon({ amount: parseFloat(amount), code, discountType: couponData.discount_type });
      setError(undefined);
    });
  };

  return (
    <ActionSection>
      <ActionTitle>Cupom de desconto</ActionTitle>
      <ActionInputLabel>Código:</ActionInputLabel>
      <ActionInput
        value={coupon ? coupon.code : couponText}
        disabled={!!coupon}
        onChange={handleCouponInputOnChange}
        placeholder="Digite aqui o código do cupom"
      />
      <ErrorText>{error}</ErrorText>
      {coupon ? (
        <CouponInfo />
      ) : (
        <ActionSectionButton onClick={handleButtonOnClick}>Validar cupom</ActionSectionButton>
      )}
    </ActionSection>
  );
};

export default CouponSection;
