import styled from 'styled-components';
import colors from 'styles/colors';
import { generateSpacing } from 'styles/spaces';

export const FormButton = styled.div`
  border: 1px solid
    ${({ isChecked }: { isChecked?: boolean }) =>
      isChecked ? colors.focusedBorder : colors.border};
  padding: ${generateSpacing(10)};
  background-color: ${({ isChecked }: { isChecked?: boolean }) => (isChecked ? '#fffee6' : '#fff')};
  width: 320px;
  border-radius: 6px;
  cursor: pointer;
`;

export default FormButton;
