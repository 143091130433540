import React from 'react';
import Footer from 'components/Footer';
import {
  EmphaticText,
  HomeLink,
  HomeLinkWrapper,
  LogoWrapper,
  LogoIcon,
  LogoName,
  NotFoundPageWrapper,
  NotFoundText,
} from './styles';

const NotFoundPage = () => (
  <>
    <NotFoundPageWrapper>
      <LogoWrapper>
        <LogoIcon />
        <LogoName />
      </LogoWrapper>
      <div>
        <EmphaticText>Ops!</EmphaticText>
      </div>
      <div>
        <NotFoundText>Página não encontrada.</NotFoundText>
      </div>
      <HomeLinkWrapper>
        <HomeLink to="/">Voltar a tela inicial</HomeLink>
      </HomeLinkWrapper>
    </NotFoundPageWrapper>
    <Footer />
  </>
);

export default NotFoundPage;
