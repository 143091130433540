import { useContext } from 'react';
import { WishlistContext } from 'contexts/WishlistContext';
import WooCommerceApi from 'woocommerceApi/base';
import normalizeWishlistItem from 'utils/normalizeWishItem';

const useWishlist = () => {
  const context = useContext(WishlistContext);

  if (context === null) {
    throw Error('useWishlist must be within a WishlistProvider');
  }

  const { hashKey, setWishlist } = context;

  const addWish = (productId: number) =>
    WooCommerceApi.post(`wishlist/${hashKey}/add_product`, {
      product_id: productId,
    }).then(response =>
      setWishlist(prevState => [...prevState, ...response.data.map(normalizeWishlistItem)]),
    );

  const removeWish = (itemId: number) =>
    WooCommerceApi.get(`wishlist/remove_product/${itemId}`).then(() =>
      setWishlist(prevState => {
        const wishIndex = prevState.findIndex(wish => wish.itemId === itemId);

        return [...prevState.slice(0, wishIndex), ...prevState.slice(wishIndex + 1)];
      }),
    );

  return { ...context, addWish, removeWish };
};

export default useWishlist;
