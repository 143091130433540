import styled from 'styled-components';
import { Form } from 'formik';
import LogoNameSVG from 'assets/icons/name_abla.svg';
import NeutralButton from 'components/NeutralButton';
import TextInput from 'components/Input/TextInput';
import { generateSpacing } from 'styles/spaces';

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const LogoWrapper = styled.div`
  margin: ${generateSpacing(15, 0)};
  text-align: center;
`;

export const LogoName = styled.img.attrs({ src: LogoNameSVG })``;

export const Instruction = styled.p`
  text-align: center;
  font-family: 'Montserrat', 'sans-serif';
  font-weight: 700;
  font-size: 1.5rem;
`;

export const LoginFormWrapper = styled(Form)`
  margin: ${generateSpacing(15)} auto 0;
  width: 300px;
`;

export const LoginField = styled.div`
  margin-bottom: ${generateSpacing(7)};
`;

export const LoginLabel = styled.label`
  display: block;
  margin-bottom: ${generateSpacing(4)};
`;

export const LoginInput = styled(TextInput)`
  width: 100%;
`;

export const SubmitWrapper = styled.div`
  margin-top: ${generateSpacing(15)};
  text-align: center;
`;

export const SubmitButton = styled(NeutralButton)`
  width: 100%;
  font-family: 'Montserrat', 'sans-serif';
  font-weight: 500;
  font-size: 1rem;
`;

export const HelpInstructions = styled.p`
  text-align: center;
  font-family: 'Montserrat', 'sans-serif';
  font-weight: 500;
  font-size: 0.8rem;
  color: #747474;
`;

export const HelpAnchor = styled.a`
  color: #747474;
  text-decoration: underline;
`;

export const ErrorMessageWrapper = styled.div`
  color: #ff0000;
`;
