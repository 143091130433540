import React from 'react';
import { SectionTitle } from 'styles/typographies';
import Carousel from 'components/Carousel';
import SlideItem from 'components/ProductSlideItem';
import { NormalizedProduct } from '../../../../@types/woocommerce';
import { RecommendedProductsContainer, SectionTitleWrapper } from './styles';

const renderSlideItem = (item: NormalizedProduct) => <SlideItem product={item} />;

const RecommendedProducts = ({ products }: { products: NormalizedProduct[] }) => {
  return (
    <RecommendedProductsContainer>
      <SectionTitleWrapper>
        <SectionTitle>Recomendados pra você</SectionTitle>
      </SectionTitleWrapper>
      <Carousel data={products} renderItem={renderSlideItem} />
    </RecommendedProductsContainer>
  );
};

export default RecommendedProducts;
