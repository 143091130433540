import styled from 'styled-components';
import ActionButton from 'components/ActionButton';

export const ResponsiveActionButton = styled(ActionButton)`
  width: 225px;

  @media (max-width: 540px) {
    padding: 0 10px;
    width: 65%;
    max-width: 250px;
    font-size: 0.8rem;
  }
  @media (max-width: 310px) {
    padding: 0 10px;
    width: 130px;
    font-size: 0.8rem;
  }
`;
