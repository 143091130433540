import styled from 'styled-components';
import { generateSpacing } from 'styles/spaces';
import { SectionTitle } from 'styles/typographies';

export const CapitalizedText = styled.span`
  text-transform: capitalize;
`;

export const SearchTitle = styled(SectionTitle)`
  display: inline-block;
  font-size: 1.4rem;

  margin-bottom: ${generateSpacing(14)};
`;
