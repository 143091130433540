import React from 'react';

import {
  ClientFeedbackItemContainer,
  ClientFeedbackItemImage,
  ContentWrapper,
  ContentTitleWrapper,
  ContentTitle,
  ContentSubTitleWrapper,
  ContentSubTitle,
  ContentTextWrapper,
  ContentText,
  ContentQuotesWrapper,
  ContentQuotesImage,
} from './styles';

export interface ClientFeedbackItemProps {
  imageUrl: string;
  title: string;
  subtitle: string;
  content: string;
}
const ClientFeedbackItem = ({ imageUrl, title, subtitle, content }: ClientFeedbackItemProps) => {
  return (
    <ClientFeedbackItemContainer>
      <ClientFeedbackItemImage src={imageUrl} />
      <ContentWrapper>
        <ContentQuotesWrapper>
          <ContentQuotesImage />
        </ContentQuotesWrapper>
        <ContentTitleWrapper>
          <ContentTitle>{title}</ContentTitle>
        </ContentTitleWrapper>

        <ContentSubTitleWrapper>
          <ContentSubTitle>{subtitle}</ContentSubTitle>
        </ContentSubTitleWrapper>

        <ContentTextWrapper>
          <ContentText>{content}</ContentText>
        </ContentTextWrapper>
      </ContentWrapper>
    </ClientFeedbackItemContainer>
  );
};

export default ClientFeedbackItem;
