import { useContext } from 'react';
import { CartContext, CartItem } from 'contexts/CartContext';
import normalizeProduct from 'utils/normalizeProduct';
import { NormalizedProduct, Product } from '../@types/woocommerce';

const useCart = () => {
  const context = useContext(CartContext);

  if (context === null) {
    throw new Error('useCart must be within a CartProvider');
  }

  const { items, setItems, setZipcode, setCoupon, setShipping } = context;
  const count = items.length;

  const addItem = (item: CartItem) => setItems(prevState => [...prevState, item]);

  const updateItem = (item: CartItem, newItem: Partial<CartItem>) => {
    const itemIndex = items.findIndex(cartItem => cartItem.productId === item.productId);
    if (itemIndex !== -1) {
      setItems(prevState => [
        ...prevState.slice(0, itemIndex),
        { ...item, ...newItem },
        ...prevState.slice(itemIndex + 1),
      ]);
    }
  };

  const removeItem = ({ productId }: CartItem) => {
    const itemIndex = items.findIndex(cartItem => cartItem.productId === productId);
    if (itemIndex !== -1) {
      setItems(prevState => [...prevState.slice(0, itemIndex), ...prevState.slice(itemIndex + 1)]);
    }
  };

  const upsertItem = (newItem: CartItem) => {
    const item = items.find(cartItem => cartItem.productId === newItem.productId);

    if (item) {
      updateItem(item, newItem);
    } else {
      addItem(newItem);
    }
  };

  const reduceFetchedProducts = (
    acc: { product: NormalizedProduct; quantity: number }[],
    currentItem: CartItem,
    products: Product[],
  ) => {
    const { productId, quantity } = currentItem;
    const item = products.find(({ id }) => id === productId);

    if (!item) {
      return [...acc];
    }

    const product = normalizeProduct(item);

    return [...acc, { product, quantity }];
  };

  const emptyCart = () => {
    setItems([]);
    setZipcode(undefined);
    setShipping(0);
    setCoupon(undefined);
  };

  return {
    ...context,
    count,
    addItem,
    removeItem,
    updateItem,
    upsertItem,
    reduceFetchedProducts,
    emptyCart,
  };
};

export default useCart;
