import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { RangeProps } from 'rc-slider';
import 'rc-slider/assets/index.css';
import colors from 'styles/colors';

const initialMaxPrice = 10000;

const handleStyle = {
  backgroundColor: colors.primary,
  borderColor: '#000',
};

type Props = {
  render: (
    [newMinPrice, newMaxPrice]: number[],
    rangeProps: RangeProps,
    reset: () => void,
  ) => JSX.Element;
};

const PriceSlider = ({ render }: Props) => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const minPriceQuery = query.get('min_price');
  const maxPriceQuery = query.get('max_price');

  const [minPrice, setMinPrice] = useState(minPriceQuery ? parseInt(minPriceQuery, 10) : 0);
  const [maxPrice, setMaxPrice] = useState(
    maxPriceQuery ? parseInt(maxPriceQuery, 10) : initialMaxPrice,
  );

  const onChange = ([newMinPrice, newMaxPrice]: number[]) => {
    setMinPrice(newMinPrice);
    setMaxPrice(newMaxPrice);
  };

  const reset = () => {
    setMinPrice(0);
    setMaxPrice(initialMaxPrice);
  };

  const rangeProps = {
    allowCross: false,
    min: 0,
    max: initialMaxPrice,
    value: [minPrice, maxPrice],
    onChange,
    trackStyle: [{ backgroundColor: '#000' }],
    handleStyle: [handleStyle, handleStyle],
  };

  return render([minPrice, maxPrice], rangeProps, reset);
};

export default PriceSlider;
