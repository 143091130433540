import NumberFormat from 'react-number-format';
import styled from 'styled-components';
import buttonReset from 'styles/buttonReset';
import { generateSpacing } from 'styles/spaces';

export const CompareBoxWrapper = styled.div`
  overflow: auto;
  height: 100%;
`;

export const CompareWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: ${generateSpacing(15)};
  height: 90%;

  @media (min-width: 700px) {
    height: 85%;
  }
`;

export const CloseTextWrapper = styled(buttonReset)`
  display: block;
  position: absolute;
  right: 12px;
  top: -30px;
`;

export const CloseText = styled.span`
  color: #fff;
  font-size: 1.1rem;
  font-family: 'Montserrat', 'sans-serif';
`;

export const CloseIconWrapper = styled(buttonReset)`
  margin-right: ${generateSpacing(7)};
`;

export const ProductComparedWrapper = styled.div`
  flex: 0 1 48%;
  min-width: 48%;
  display: flex;
  flex-direction: column;
`;

export const ProductTitleWrapper = styled.div`
  position: relative;
  margin-bottom: ${generateSpacing(12)};
`;

export const ProductTitle = styled.span`
  font-size: 1rem;
  font-family: 'Montserrat', 'sans-serif';
  font-weight: 500;
`;

export const TrashIconWrapper = styled(buttonReset)`
  position: absolute;
  top: 0;
  right: 0;
  font-size: 20px;
  line-height: 0;
`;

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${generateSpacing(0, 5, 5)};
  min-height: 85px;

  @media (min-width: 700px) {
    padding: ${generateSpacing(5, 7, 5, 0)};
  }
`;

export const ProductImage = styled.img`
  width: 80px;
`;

export const ProductPrice = styled(NumberFormat)`
  font-size: 1rem;
  font-family: 'Montserrat', 'sans-serif';
  font-weight: 600;
`;
