import { useContext } from 'react';
import { CompareContext } from 'contexts/CompareContext';
import { NormalizedProduct } from '../@types/woocommerce';

const useUser = () => {
  const context = useContext(CompareContext);

  if (context === null) {
    throw new Error('useCompare must be within a CompareProvider');
  }

  const { setIsOpen, setProduct } = context;

  const openModal = (product: NormalizedProduct) => {
    setIsOpen(true);
    setProduct(product);
  };

  const closeModal = () => {
    setIsOpen(false);
    setProduct(undefined);
  };

  return { ...context, openModal, closeModal };
};

export default useUser;
