import styled from 'styled-components';
import colors from 'styles/colors';
import { generateSpacing } from 'styles/spaces';

export const ProductCardWrapper = styled.div`
  padding: ${generateSpacing(4)};
  border: 1px solid ${colors.border};
  border-radius: 7px;
  flex: 1;

  @media (min-width: 360px) {
    padding: ${generateSpacing(8)};
  }
`;

export const ProductHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${generateSpacing(7)};
  flex-direction: column;
  height: 240px;

  @media (min-width: 700px) {
    height: 100px;
    flex-direction: row;
    justify-content: flex-start;
  }
`;

export const ProductInfoWrapper = styled.div``;

export const ProductNameWrapper = styled.div`
  font-weight: 500;
  font-family: 'Montserrat', 'sans-serif';
  margin-bottom: ${generateSpacing(4)};
`;

export const ProductVendorWrapper = styled.div`
  font-size: 0.7rem;
  color: ${colors.emptyText};
  font-family: 'Montserrat', 'sans-serif';
`;

export const ProductCardSpecifications = styled.div`
  margin-top: ${generateSpacing(20)};
`;
