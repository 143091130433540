import React from 'react';
import { StarIconWrapper, StarIcon } from './styles';

const getStars = (coloredIndex: number, onClick: (index: number) => void = () => {}) =>
  Array.from({ length: 5 }, (_value, index) => {
    if (index <= coloredIndex) {
      return (
        <StarIconWrapper key={index} hasColor onClick={() => onClick(index)}>
          <StarIcon />
        </StarIconWrapper>
      );
    }
    return (
      <StarIconWrapper key={index} hasColor={false} onClick={() => onClick(index)}>
        <StarIcon />
      </StarIconWrapper>
    );
  });

export default getStars;
