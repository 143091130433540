import styled from 'styled-components';
import { PageWrapper } from 'pages/styles';

export const HomeContainer = styled(PageWrapper)`
  margin: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const DefaultWrapper = styled.div`
  margin-bottom: 60px;

  @media (max-width: 900px) {
    margin-bottom: 16px;
  }
`;
export const BannerWrapper = styled.div`
  width: 100%;
`;
export const CategorySelectorWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const TopSellingProductsWrapper = styled(DefaultWrapper)`
  margin-top: 30px;
`;
export const ContentContainer = styled.div`
  width: 90%;
  overflow: hidden;
`;
export const FeaturedProductsWrapper = styled(DefaultWrapper)``;
export const TodayDealsWrapper = styled(DefaultWrapper)``;
export const RecommendedProductsWrapper = styled(DefaultWrapper)``;
export const PartnersWrapper = styled(DefaultWrapper)`
  margin-top: 60px;
  @media (max-width: 900px) {
    margin-top: 16px;
  }
`;
export const ClientsFeedbackWrapper = styled(DefaultWrapper)``;
export const BadgesWrapper = styled(DefaultWrapper)``;
