import styled from 'styled-components';
import { generateSpacing } from 'styles/spaces';
import { SectionTitle } from 'styles/typographies';

export const FilterName = styled(SectionTitle)`
  font-size: 1.5rem;
`;

export const FilterNameWrapper = styled.div`
  margin-bottom: ${generateSpacing(15)};
`;
