import React from 'react';
import { BannerButtonWrapper, BannerContainer, BannerImage } from './styles';

interface BannerProps {
  mainBanner: {
    src: string;
    onClick: () => void;
  };
}

const Banner = ({ mainBanner }: BannerProps) => {
  return (
    <BannerContainer onClick={mainBanner.onClick}>
      <BannerButtonWrapper>
        <BannerImage src={mainBanner.src} />
      </BannerButtonWrapper>
    </BannerContainer>
  );
};

export default Banner;
