import React, { useState } from 'react';
import * as yup from 'yup';
import { ErrorMessage, Field, Formik, FormikHelpers } from 'formik';
import { useHistory } from 'react-router-dom';
import wp from 'wpApi/base';
import Footer from 'components/Footer';
import { portugueseLocale, TOKEN_KEY } from 'utils/constants';
import normalizeUser from 'utils/normalizeUser';
import useUser from 'hooks/useUser';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { Spinner } from 'pages/Home/components/ScreenLoading/styles';
import { ReactComponent as LogoIcon } from 'assets/icons/logo_abla.svg';
import { User } from '../../@types/wordpress';
import {
  HelpAnchor,
  HelpInstructions,
  Instruction,
  LoginField,
  LoginFormWrapper,
  LoginInput,
  LoginLabel,
  LogoName,
  LogoWrapper,
  PageWrapper,
  SubmitButton,
  ErrorMessageWrapper,
  SubmitWrapper,
} from './styles';

interface ILoginForm {
  username: string;
  password: string;
}

const initialValues = {
  username: '',
  password: '',
};

const storeToken = (token: string) => localStorage.setItem(TOKEN_KEY, token);

yup.setLocale(portugueseLocale);

const schema = yup.object().shape({
  username: yup.string().required(),
  password: yup.string().required(),
});

const LoginForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { setUser } = useUser();
  const history = useHistory();

  const handleSubmit = async (values: ILoginForm, { setFieldError }: FormikHelpers<ILoginForm>) => {
    setIsLoading(true);
    try {
      const { token, ...user }: User = await wp.login().create(values);
      storeToken(token);
      wp.setHeaders({ Authorization: `Bearer ${token}` });
      const { id }: { id: number } = await wp.users().me();
      setUser(normalizeUser({ id, ...user }));
      setIsLoading(false);

      history.push('/');
    } catch (error) {
      if (error.data.status === 403) {
        setFieldError('password', 'Email ou senha inválidos');
      }

      setIsLoading(false);
    }
  };

  return (
    <Formik initialValues={initialValues} validationSchema={schema} onSubmit={handleSubmit}>
      <LoginFormWrapper>
        <LoginField>
          <LoginLabel htmlFor="usernameInput">Usuário</LoginLabel>
          <Field
            as={LoginInput}
            id="usernameInput"
            name="username"
            placeholder="Digite seu nome de usuário aqui..."
          />
          <ErrorMessageWrapper>
            <ErrorMessage name="username" />
          </ErrorMessageWrapper>
        </LoginField>
        <LoginField>
          <LoginLabel htmlFor="passwordInput">Senha</LoginLabel>
          <Field
            as={LoginInput}
            id="passwordInput"
            name="password"
            type="password"
            placeholder="Digite aqui sua senha..."
          />
          <ErrorMessageWrapper>
            <ErrorMessage name="password" />
          </ErrorMessageWrapper>
        </LoginField>
        <HelpInstructions>
          Precisa de ajuda? <HelpAnchor href="/login">Entre em contato</HelpAnchor>
        </HelpInstructions>
        <SubmitWrapper>
          {isLoading ? <Spinner /> : <SubmitButton type="submit">Entrar</SubmitButton>}
        </SubmitWrapper>
      </LoginFormWrapper>
    </Formik>
  );
};

const Login = () => {
  const { isMobile } = useWindowDimensions();

  return (
    <PageWrapper>
      <div>
        <LogoWrapper>
          <div>
            <LogoIcon />
          </div>
          <div>
            <LogoName />
          </div>
        </LogoWrapper>
        {!isMobile && (
          <>
            <Instruction>Olá!</Instruction>
            <Instruction>Para continuar, faça seu login.</Instruction>
          </>
        )}
        <LoginForm />
      </div>
      <Footer />
    </PageWrapper>
  );
};

export default Login;
