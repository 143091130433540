import React from 'react';
import { SectionTitle } from 'styles/typographies';
import Carousel from 'components/Carousel';
import { PartnersContainer, SectionTitleWrapper } from './styles';
import PartnerItem, { PartnerProps } from './PartnerItem';

const renderSlideItem = (item: PartnerProps) => <PartnerItem key={item.imageUrl} partner={item} />;

const Partners = ({ partners }: { partners: PartnerProps[] }) => {
  return (
    <PartnersContainer>
      <SectionTitleWrapper>
        <SectionTitle>Nossos parceiros</SectionTitle>
      </SectionTitleWrapper>
      <Carousel data={partners} renderItem={renderSlideItem} />
    </PartnersContainer>
  );
};

export default Partners;
