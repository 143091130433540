import useUser from 'hooks/useUser';
import React, { useState, useEffect, createContext, useMemo } from 'react';
import { WISHLIST_KEY } from 'utils/constants';
import normalizeWishlistItem from 'utils/normalizeWishItem';
import WooCommerceApi from 'woocommerceApi/base';
import { WishlistItem } from '../@types/woocommerce';

type Wishes = { productId: number; itemId: number }[];

interface ContextValue {
  wishlist: Wishes;
  setWishlist: React.Dispatch<React.SetStateAction<Wishes>>;
  hashKey: string | null;
}

export const WishlistContext = createContext<ContextValue | null>(null);

const Provider = ({ children }: { children: React.ReactNode }) => {
  const [wishlist, setWishlist] = useState<Wishes>([]);
  const { user } = useUser();
  const hashKey = localStorage.getItem(WISHLIST_KEY);

  useEffect(() => {
    const getWishlist = async () => {
      let wishlistHash = hashKey;

      if (!wishlistHash) {
        const { data: wishlists } = await WooCommerceApi.get(`wishlist/get_by_user/${user?.id}`);

        wishlistHash = wishlists[0].share_key;
        localStorage.setItem(WISHLIST_KEY, wishlistHash || '');
      }

      const { data } = (await WooCommerceApi.get(`wishlist/${wishlistHash}/get_products`)) as {
        data: WishlistItem[];
      };

      const wishIds = data.map(normalizeWishlistItem);

      setWishlist(wishIds);
    };

    getWishlist();
  }, [user, hashKey]);

  const context = useMemo(
    () => ({
      wishlist,
      setWishlist,
      hashKey,
    }),
    [wishlist, hashKey],
  );

  return <WishlistContext.Provider value={context}>{children}</WishlistContext.Provider>;
};

export default Provider;
