import React, { forwardRef } from 'react';
import { ErrorMessage as ErrorMessageFormik, ErrorMessageProps } from 'formik';
import { ErrorMessageWrapper } from './styles';

const ErrorMessage = forwardRef(
  (props: ErrorMessageProps, ref: React.ForwardedRef<HTMLDivElement>) => (
    <ErrorMessageWrapper ref={ref}>
      <ErrorMessageFormik {...props} />
    </ErrorMessageWrapper>
  ),
);

export default ErrorMessage;
