import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import useUser from 'hooks/useUser';
import ScreenLoading from 'pages/Home/components/ScreenLoading';
import Header from 'components/Header';
import Footer from 'components/Footer';

export type Props = {
  hasExtraComponents?: boolean;
};

export default function PrivateRoute({
  hasExtraComponents,
  component: Component,
  ...rest
}: RouteProps & Props) {
  const { user, isLoading } = useUser();

  if (!Component) {
    return null;
  }

  if (isLoading) {
    return <ScreenLoading />;
  }

  if (hasExtraComponents) {
    return (
      <Route
        {...rest}
        render={props =>
          user?.id ? (
            <>
              <Header />
              <Component {...props} />
              <Footer />
            </>
          ) : (
            <Redirect to="/login" />
          )
        }
      />
    );
  }

  return (
    <Route
      {...rest}
      render={props => (user?.id ? <Component {...props} /> : <Redirect to="/login" />)}
    />
  );
}

PrivateRoute.defaultProps = {
  hasExtraComponents: false,
};
