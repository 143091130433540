import React from 'react';
import { toFloat } from 'utils/normalizeProduct';
import Specifications from 'components/Specifications';
import { NormalizedProduct } from '../../../@types/woocommerce';
import { ProductImage, ImageWrapper, ProductPrice } from '../styles';
import {
  ProductCardWrapper,
  ProductHeaderWrapper,
  ProductNameWrapper,
  ProductVendorWrapper,
  ProductCardSpecifications,
  ProductInfoWrapper,
} from './styles';

type Props = {
  product?: NormalizedProduct;
};

const ProductCard = ({ product }: Props) => {
  if (!product) {
    return null;
  }

  return (
    <ProductCardWrapper>
      <ProductHeaderWrapper>
        <ImageWrapper>
          <ProductImage src={product.imageUrl} alt="Produto sendo comparado" />
        </ImageWrapper>
        <ProductInfoWrapper>
          <ProductNameWrapper>
            <span>{product.name}</span>
          </ProductNameWrapper>
          <ProductNameWrapper>
            <ProductPrice
              value={toFloat(product.price)}
              defaultValue="0"
              displayType="text"
              thousandSeparator="."
              decimalSeparator=","
              prefix="R$ "
              fixedDecimalScale
              decimalScale={2}
            />
          </ProductNameWrapper>
          <ProductVendorWrapper>
            <span>{product.vendor}</span>
          </ProductVendorWrapper>
        </ProductInfoWrapper>
      </ProductHeaderWrapper>
      <ProductCardSpecifications>
        <Specifications dimensions={product.dimensions} weight={product.weight} />
      </ProductCardSpecifications>
    </ProductCardWrapper>
  );
};

ProductCard.defaultProps = {
  product: undefined,
};

export default ProductCard;
