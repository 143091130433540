import NumberFormat from 'react-number-format';
import styled from 'styled-components';
import buttonReset from 'styles/buttonReset';
import colors from 'styles/colors';
import { generateSpacing } from 'styles/spaces';

export const CartItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto ${generateSpacing(15)} auto;
  border: 1px solid ${colors.border};
  border-radius: 10px;
  padding: ${generateSpacing(5, 5, 12)};
  max-width: 400px;
`;

export const CartItemDesktopWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${generateSpacing(15)};
`;

export const ProductInfo = styled.div`
  display: flex;
  align-items: center;

  @media (min-width: 900px) {
    width: 300px;
  }

  @media (min-width: 1020px) {
    width: 400px;
  }

  @media (min-width: 1200px) {
    width: 500px;
  }
`;

export const ProductImageWrapper = styled.div`
  width: 80px;
  height: 80px;

  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: ${generateSpacing(12)};
`;

export const ProductImage = styled.img`
  width: 80px;
`;

export const TextInfo = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 400px) {
    max-width: 190px;
  }

  @media (min-width: 400px) and (max-width: 900px) {
    max-width: 230px;
  }
`;

export const StrongText = styled.span`
  font-family: 'Montserrat', 'sans-serif';
  font-weight: 600;
`;

export const DetailText = styled.span`
  font-size: 0.8rem;
  color: ${colors.emptyText};
`;

export const CartItemQuantity = styled.div`
  margin: ${generateSpacing(8)} auto 0;
`;

export const PriceSection = styled.div`
  min-width: 110px;

  @media (max-width: 899px) {
    margin-top: ${generateSpacing(8)};
    min-width: 100%;
    display: flex;
    justify-content: space-evenly;
  }
`;

export const Price = styled(NumberFormat)`
  font-family: 'Montserrat', 'sans-serif';
  font-weight: 600;
  font-size: 1.025rem;
`;

export const PriceDescription = styled.span`
  font-family: 'Montserrat', 'sans-serif';
  font-weight: 500;
`;

export const TrashIconWrapper = styled(buttonReset)`
  line-height: 0;
  font-size: 15px;
  margin: ${generateSpacing(0, 0, 2)} auto;

  @media (min-width: 900px) {
    margin: 0;
    font-size: 21px;
  }
`;
