import React from 'react';

import {
  BadgeItemContainer,
  ContentWrapper,
  IconProps,
  ReturnImage,
  ShieldImage,
  SuportImage,
  TruckImage,
  ImageWrapper,
  ContentTitleWrapper,
  ContentTitle,
  ContentSubTitleWrapper,
  ContentSubTitle,
} from './styles';

export interface BadgeItemProps {
  icon: IconProps;
  title: string;
  subtitle: string;
}

function selectIcon(icon: IconProps) {
  switch (icon) {
    case 'truck':
      return <TruckImage />;
    case 'return':
      return <ReturnImage />;
    case 'shield':
      return <ShieldImage />;
    case 'suport':
      return <SuportImage />;
    default:
      return <TruckImage />;
  }
}

const BadgeItem = ({ icon, title, subtitle }: BadgeItemProps) => {
  return (
    <BadgeItemContainer>
      <ImageWrapper>{selectIcon(icon)}</ImageWrapper>
      <ContentWrapper>
        <ContentTitleWrapper>
          <ContentTitle>{title}</ContentTitle>
        </ContentTitleWrapper>

        <ContentSubTitleWrapper>
          <ContentSubTitle>{subtitle}</ContentSubTitle>
        </ContentSubTitleWrapper>
      </ContentWrapper>
    </BadgeItemContainer>
  );
};

export default BadgeItem;
