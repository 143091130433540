import styled from 'styled-components';
import { ReactComponent as IconStarSVG } from 'assets/icons/icon_star.svg';
import colors from 'styles/colors';

export const StarIcon = styled(IconStarSVG)``;

export const StarIconWrapper = styled.div`
  display: inline-block;
  cursor: pointer;
  line-height: 0;
  svg path {
    fill: ${({ hasColor }: { hasColor: boolean }) =>
      hasColor ? '#ffD600' : colors.backgroundPrimary};
  }
`;
