import { BaseButton } from 'components/Button';
import styled from 'styled-components';

export const BannerContainer = styled.div`
  width: 100%;
  overflow: hidden;
`;
export const BannerButtonWrapper = styled(BaseButton)`
  width: 100%;
`;

export const BannerImage = styled.img.attrs(props => ({
  src: props.src,
}))`
  height: 350px;

  @media (min-width: 500px) {
    width: 100%;
    min-height: 200px;
    object-fit: cover;
    height: auto;
  }
`;

export const SmallBannerImage = styled(BannerImage)`
  @media (min-width: 500px) {
    object-fit: contain;
  }
`;
