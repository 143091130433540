import React, { useLayoutEffect } from 'react';
import { Range, RangeProps } from 'rc-slider';
import NumberFormat from 'react-number-format';
import { PriceFilterValues } from 'pages/Products/styles';
import ContrastButton from 'components/ContrastButton';
import useQueryParam from 'hooks/useQueryParam';
import { getWindowDimensions } from 'hooks/useWindowDimensions';
import useModal from 'hooks/useFilterModal';
import PriceSlider from '../../PriceSlider';
import { FilterNameWrapper, FilterName } from '../styles';
import { ButtonsWrapper, ClearButton } from './styles';

const PriceFilterModal = () => {
  const setQueryParam = useQueryParam();
  const { closeModal, setModalStyles } = useModal();
  const { width } = getWindowDimensions();

  const componentHeight = '250px';

  useLayoutEffect(() => {
    let contentStyles: React.CSSProperties;

    if (width < 550) {
      contentStyles = {
        transform: `translateY(-${componentHeight})`,
        height: componentHeight,
      };
    } else {
      contentStyles = {
        maxWidth: '450px',
        height: componentHeight,
        top: '50%',
        borderRadius: '12px',
        transform: `translateY(-50%)`,
        transition: 'none',
      };
    }
    setModalStyles(({ overlay, content }) => ({
      overlay,
      content: {
        ...content,
        ...contentStyles,
      },
    }));
  }, [setModalStyles, width]);

  const handleFilter = ([newMinPrice, newMaxPrice]: number[]) => {
    setQueryParam([{ min_price: `${newMinPrice}` }, { max_price: `${newMaxPrice}` }], '/produtos');
    closeModal();
  };
  const resetFilter = () => {
    setQueryParam([{ min_price: '' }, { max_price: '' }], '/produtos');
    closeModal();
  };

  const renderPriceRange = (
    [minPrice, maxPrice]: number[],
    rangeProps: RangeProps,
    reset: () => void,
  ) => (
    <>
      <Range {...rangeProps} />
      <PriceFilterValues>
        <NumberFormat
          value={minPrice}
          displayType="text"
          thousandSeparator="."
          decimalSeparator=","
          prefix="R$ "
          fixedDecimalScale
          decimalScale={2}
        />
        <NumberFormat
          value={maxPrice}
          displayType="text"
          thousandSeparator="."
          decimalSeparator=","
          prefix="R$ "
          fixedDecimalScale
          decimalScale={2}
        />
      </PriceFilterValues>
      <ButtonsWrapper>
        <ClearButton
          onClick={() => {
            reset();
            resetFilter();
          }}
        >
          LIMPAR
        </ClearButton>
        <ContrastButton onClick={() => handleFilter([minPrice, maxPrice])}>APLICAR</ContrastButton>
      </ButtonsWrapper>
    </>
  );

  return (
    <>
      <FilterNameWrapper>
        <FilterName>Faixa de preço</FilterName>
      </FilterNameWrapper>
      <PriceSlider render={renderPriceRange} />
    </>
  );
};

export default PriceFilterModal;
