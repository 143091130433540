import styled from 'styled-components';
import ButtonReset from 'styles/buttonReset';
import colors from 'styles/colors';

const ActionButton = styled(ButtonReset)`
  color: ${colors.primary};
  background-color: ${colors.secondary};
  width: 250px;
  height: 40px;
  border-radius: 8px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
`;

export default ActionButton;
