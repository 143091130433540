import React, { useState, createContext, useMemo } from 'react';
import { NormalizedProduct } from '../@types/woocommerce';

interface CompareContextValues {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  product: NormalizedProduct | undefined;
  setProduct: React.Dispatch<React.SetStateAction<NormalizedProduct | undefined>>;
}

export const CompareContext = createContext<CompareContextValues | null>(null);

const Provider = ({ children }: { children: React.ReactNode }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [product, setProduct] = useState<NormalizedProduct>();

  const context = useMemo(
    () => ({
      isOpen,
      setIsOpen,
      product,
      setProduct,
    }),
    [isOpen, product],
  );

  return <CompareContext.Provider value={context}>{children}</CompareContext.Provider>;
};

export default Provider;
