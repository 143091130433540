import styled from 'styled-components';
import ButtonReset from 'styles/buttonReset';
import colors from 'styles/colors';

const ContrastButton = styled(ButtonReset)`
  color: ${colors.secondary};
  background-color: ${colors.primary};
  width: 45%;
  height: 45px;
  border-radius: 8px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 1rem;
`;

export default ContrastButton;
