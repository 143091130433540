import styled from 'styled-components';
import colors from 'styles/colors';
import { generateSpacing } from 'styles/spaces';

export const Wrapper = styled.div`
  height: 100%;
`;

export const UserInfo = styled.div`
  margin-bottom: ${generateSpacing(20)};
`;

export const UserEmail = styled.span`
  color: ${colors.emptyText};
  font-size: 0.85rem;
`;
