import React, { useRef } from 'react';
import Slider, { Settings } from 'react-slick';
import styled from 'styled-components';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { BaseButton } from 'components/Button';
import useWindowDimensions, { getWindowDimensions } from 'hooks/useWindowDimensions';
import ArrowRightIconSVG from '../../assets/icons/icon_arrow_right.svg';

const CarouselContainer = styled.section`
  display: flex;
  flex-direction: row;
  width: 100%;
`;
const SliderWrapper = styled.div`
  flex: 1;
  min-width: 80%;
`;
const ArrowWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Arrow = styled(BaseButton)`
  height: 40px;
  width: 40px;
  border-radius: 4px;
  background: #e8eced;
`;
const ArrowLeftImage = styled.img.attrs({
  src: ArrowRightIconSVG,
})`
  transform: rotateY(180deg);
`;
const ArrowRightImage = styled.img.attrs({
  src: ArrowRightIconSVG,
})``;
interface CarouselProps<T> {
  data: Array<T>;
  renderItem: (item: T) => React.ReactNode;
}

const Carousel = <T,>({ data, renderItem }: CarouselProps<T>) => {
  const { isMobile } = useWindowDimensions();

  function selectSlidesToShowFromDimensions() {
    const { width } = getWindowDimensions();

    if (width < 425) {
      return 1;
    }
    if (width < 680) {
      return 2;
    }
    if (width < 1100) {
      return 3;
    }

    return 4;
  }
  const settings: Settings = {
    dots: false,
    infinite: false,
    arrows: false,
    speed: 500,
    slidesToShow: Math.min(selectSlidesToShowFromDimensions(), data.length),
    slidesToScroll: 1,
  };
  const carouselRef = useRef<Slider>(null);
  const handleClickNextItem = () => {
    carouselRef.current?.slickNext();
  };
  const handleClickPrevItem = () => {
    carouselRef.current?.slickPrev();
  };
  return (
    <CarouselContainer>
      {!isMobile && (
        <ArrowWrapper>
          <Arrow onClick={handleClickPrevItem}>
            <ArrowLeftImage />
          </Arrow>
        </ArrowWrapper>
      )}

      <SliderWrapper>
        <Slider ref={carouselRef} {...settings}>
          {data.map(item => renderItem(item))}
        </Slider>
      </SliderWrapper>

      {!isMobile && (
        <ArrowWrapper>
          <Arrow onClick={handleClickNextItem}>
            <ArrowRightImage />
          </Arrow>
        </ArrowWrapper>
      )}
    </CarouselContainer>
  );
};

export default Carousel;
