import styled from 'styled-components';
import { generateSpacing } from 'styles/spaces';

import HomeIconSVG from '../../assets/icons/icon_home.svg';
import CartIconSVG from '../../assets/icons/icon_cart.svg';
import SearchIconSVG from '../../assets/icons/icon_search.svg';
import HeartIconSVG from '../../assets/icons/icon_heart.svg';
import AccountIconSVG from '../../assets/icons/icon_account.svg';
import HomeIconGraySVG from '../../assets/icons/icon_home_gray.svg';
import CartIconGraySVG from '../../assets/icons/icon_cart_gray.svg';
import SearchIconGraySVG from '../../assets/icons/icon_search_gray.svg';
import HeartIconGraySVG from '../../assets/icons/icon_heart_gray.svg';
import AccountIconGraySVG from '../../assets/icons/icon_account_gray.svg';

export const BottomTabNavigationContainer = styled.div`
  padding: ${generateSpacing(8, 10)};
  width: 100%;
  overflow: hidden;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fff;

  z-index: 1000;
  box-shadow: 0px -5px 20px rgba(229, 229, 229, 0.6);
`;

export const BottomTabNavigationItems = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const HomeImage = styled.img.attrs({
  src: HomeIconSVG,
})``;
export const CartImage = styled.img.attrs({
  src: CartIconSVG,
})``;
export const SearchImage = styled.img.attrs({
  src: SearchIconSVG,
})``;
export const HeartImage = styled.img.attrs({
  src: HeartIconSVG,
})``;
export const AccountImage = styled.img.attrs({
  src: AccountIconSVG,
})``;
export const HomeImageGray = styled.img.attrs({
  src: HomeIconGraySVG,
})``;
export const CartImageGray = styled.img.attrs({
  src: CartIconGraySVG,
})``;
export const SearchImageGray = styled.img.attrs({
  src: SearchIconGraySVG,
})``;
export const HeartImageGray = styled.img.attrs({
  src: HeartIconGraySVG,
})``;
export const AccountImageGray = styled.img.attrs({
  src: AccountIconGraySVG,
})``;
