import React from 'react';
import useWindowDimensions from 'hooks/useWindowDimensions';
import BottomTabNavigation from 'components/BottomTabNavigation';
import Badges from 'components/Badges';
import { NormalizedProduct } from '../../@types/woocommerce';
import Banner from './components/Banner';
import SmallBanner from './components/Banner/SmallBanner';
import CategorySelector from './components/CategorySelector';
import { ICategoryItem } from './components/CategorySelector/@types';
import ClientsFeedback from './components/ClientsFeedback';
import FeaturedProducts from './components/FeaturedProducts';
import Partners from './components/Partners';
import RecommendedProducts from './components/RecommendedProducts';
import TopSellingProducts from './components/TopSellingProducts';
import {
  HomeContainer,
  BannerWrapper,
  CategorySelectorWrapper,
  TopSellingProductsWrapper,
  ContentContainer,
  FeaturedProductsWrapper,
  RecommendedProductsWrapper,
  PartnersWrapper,
  ClientsFeedbackWrapper,
  BadgesWrapper,
  TodayDealsWrapper,
} from './styles';
import TodayDeals from './components/TodayDeals';

interface HomeProps {
  categories: ICategoryItem[];
  bestSellingProducts: NormalizedProduct[];
  mainBannerData: {
    src: string;
    onClick: () => void;
  };
  featuredBanner1: {
    src: string;
    onClick: () => void;
    id: number;
  };
  featuredBanner2: {
    src: string;
    onClick: () => void;
    id: number;
  };
  smallBanner: {
    src: string;
    onClick: () => void;
  };
  partners: Array<{
    imageUrl: string;
  }>;
  testimonies: Array<{
    imageUrl: string;
    title: string;
    subtitle: string;
    content: string;
  }>;
}

const Home = ({
  categories,
  bestSellingProducts,
  mainBannerData,
  featuredBanner1,
  featuredBanner2,
  partners,
  smallBanner,
  testimonies,
}: HomeProps) => {
  const { isMobile } = useWindowDimensions();
  return (
    <HomeContainer>
      <BannerWrapper>
        <Banner mainBanner={mainBannerData} />
      </BannerWrapper>

      {!isMobile && (
        <CategorySelectorWrapper>
          <CategorySelector categories={categories} />
        </CategorySelectorWrapper>
      )}

      <ContentContainer>
        <TopSellingProductsWrapper>
          <TopSellingProducts products={bestSellingProducts} />
        </TopSellingProductsWrapper>

        <FeaturedProductsWrapper>
          <FeaturedProducts featuredBanners={[featuredBanner1, featuredBanner2]} />
        </FeaturedProductsWrapper>

        <TodayDealsWrapper>
          <TodayDeals products={bestSellingProducts} />
        </TodayDealsWrapper>

        <RecommendedProductsWrapper>
          <RecommendedProducts products={bestSellingProducts} />
        </RecommendedProductsWrapper>

        <BannerWrapper>
          <SmallBanner bannerSrc={smallBanner.src} />
        </BannerWrapper>

        <PartnersWrapper>
          <Partners partners={partners} />
        </PartnersWrapper>

        <ClientsFeedbackWrapper>
          <ClientsFeedback testimonies={testimonies} />
        </ClientsFeedbackWrapper>

        <BadgesWrapper>
          <Badges />
        </BadgesWrapper>
      </ContentContainer>
      <BottomTabNavigation />
    </HomeContainer>
  );
};

export default Home;
