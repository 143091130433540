import React from 'react';
import { Range, RangeProps } from 'rc-slider';
import 'rc-slider/assets/index.css';
import NumberFormat from 'react-number-format';
import useDebounce from 'hooks/useDebounce';
import useQueryParam from 'hooks/useQueryParam';
import { FilterName, FilterWrapper, PriceFilterValues } from '../../styles';
import PriceSlider from '../PriceSlider';
import { FilterNameWrapper } from './styles';

const PriceFilter = () => {
  const setQueryParam = useQueryParam();
  const onFilter = useDebounce(([newMinPrice, newMaxPrice]: number[]) => {
    setQueryParam([{ min_price: `${newMinPrice}` }, { max_price: `${newMaxPrice}` }], '/produtos');
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, 1500);

  const renderPriceRange = ([minPrice, maxPrice]: number[], rangeProps: RangeProps) => (
    <>
      <Range {...rangeProps} onAfterChange={onFilter} />
      <PriceFilterValues>
        <NumberFormat
          value={minPrice}
          displayType="text"
          thousandSeparator="."
          decimalSeparator=","
          prefix="R$ "
          fixedDecimalScale
          decimalScale={2}
        />
        <NumberFormat
          value={maxPrice}
          displayType="text"
          thousandSeparator="."
          decimalSeparator=","
          prefix="R$ "
          fixedDecimalScale
          decimalScale={2}
        />
      </PriceFilterValues>
    </>
  );

  return (
    <FilterWrapper>
      <FilterNameWrapper>
        <FilterName>Filtrar por faixa de preço</FilterName>
      </FilterNameWrapper>
      <PriceSlider render={renderPriceRange} />
    </FilterWrapper>
  );
};

export default PriceFilter;
