import React from 'react';
import { SectionTitle } from 'styles/typographies';
import { ProductSlideItemWithDatails } from 'components/ProductSlideItem';
import { NormalizedProduct } from '../../../../@types/woocommerce';
import { TodayDealsContainer, SectionTitleWrapper, ProductsWrapper } from './styles';

const TodayDeals = ({ products }: { products: NormalizedProduct[] }) => {
  const renderSlideItem = products
    .slice(0, 2)
    .map((product: NormalizedProduct) => (
      <ProductSlideItemWithDatails key={product.id} product={product} />
    ));
  return (
    <TodayDealsContainer>
      <SectionTitleWrapper>
        <SectionTitle>Ofertas do dia</SectionTitle>
      </SectionTitleWrapper>
      <ProductsWrapper>{renderSlideItem}</ProductsWrapper>
    </TodayDealsContainer>
  );
};

export default TodayDeals;
