import styled from 'styled-components';
import colors from 'styles/colors';
import buttonReset from 'styles/buttonReset';
import { generateSpacing } from 'styles/spaces';

export const ProductItemWrapper = styled(buttonReset)`
  display: flex;
  align-items: center;
  margin-bottom: ${generateSpacing(10)};
  border: 1px solid ${colors.borderButton};
  border-radius: 8px;
  padding: ${generateSpacing(0, 10)};
  height: 100px;
  width: 100%;

  &:hover {
    border-color: ${colors.primary};
  }

  @media (max-width: 300px) {
    height: auto;
    flex-direction: column;
    padding: ${generateSpacing(5, 10)};
  }
`;
