import styled from 'styled-components';
import { generateSpacing } from 'styles/spaces';
import colors from 'styles/colors';
import TotalPriceTab from 'components/TotalPriceTab';
import { FieldWrapper } from '../../styles';

export const ResumeWrapper = styled.div`
  position: relative;
`;

export const ResumeSectionWrapper = styled.div`
  overflow: auto;
  height: 320px;
  border-radius: 8px;
  padding: ${generateSpacing(10)};
  font-family: 'Montserrat', 'sans-serif';

  @media (min-width: 900px) {
    min-width: 400px;
    height: auto;
    background-color: #f8f8f8;
  }
`;

export const ResumeFieldWrapper = styled(FieldWrapper)`
  margin-bottom: ${generateSpacing(10)};
`;

export const ProductInfo = styled.span`
  font-weight: 500;
  flex: 0 0 250px;
`;

export const PaymentMethod = styled.div`
  margin-top: ${generateSpacing(18)};
  border-radius: 8px;
  padding: 5px;
  background-color: ${colors.backgroundPrimary};
  font-weight: 500;
  font-size: 0.8rem;
  text-align: center;
`;

export const AcceptanceTerms = styled.div`
  margin: ${generateSpacing(8, 0)};
`;

export const AcceptanceTermsText = styled.label`
  vertical-align: middle;
  font-family: inherit;
  font-size: 0.9rem;
  font-weight: 700;
`;

export const OpenedTotalPriceTab = styled(TotalPriceTab)`
  margin-bottom: 0;
`;
