import React from 'react';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import WishlistProvider from './contexts/WishlistContext';
import CompareModal from './components/CompareModal';
import CartProvider from './contexts/CartContext';
import UserProvider from './contexts/UserContext';
import CompareProvider from './contexts/CompareContext';
import Routes from './routes';
import theme from './styles';

import GlobalStyle from './globalStyle';

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <UserProvider>
          <CartProvider>
            <BrowserRouter>
              <WishlistProvider>
                <CompareProvider>
                  <GlobalStyle />
                  <Routes />
                  <CompareModal />
                </CompareProvider>
              </WishlistProvider>
            </BrowserRouter>
          </CartProvider>
        </UserProvider>
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export default App;
