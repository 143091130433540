import styled from 'styled-components';
import colors from 'styles/colors';
import { generateSpacing } from 'styles/spaces';

export const PartnerItemContainer = styled.div`
  border: 1px solid ${colors.border};
  padding: ${generateSpacing(5, 2)};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  border-radius: ${({ theme }) => theme.spaces.defaultBorderRadius};
`;
export const PartnerImage = styled.img`
  width: 127px;
  height: 93px;
`;
