import React, { useState } from 'react';
import useWindowDimensions from 'hooks/useWindowDimensions';
import useWishlist from 'hooks/useWishlist';
import useCompare from 'hooks/useCompare';
import getStars from 'components/Stars';
import {
  CompareImage,
  CompareText,
  CompareTextWrapper,
  ProductCompareWrapper,
} from 'components/ProductSlideItem/styles';
import { NormalizedProduct } from '../../../../@types/woocommerce';
import {
  ProductInfoHeader,
  ReviewsInfo,
  ReviewsCounter,
  StarsWrapper,
  HeaderActions,
  HeartIcon,
  HeartIconFill,
  WishButton,
} from './styles';

type Props = {
  product: NormalizedProduct;
};

const ProductHeader = ({ product }: Props) => {
  const { id, averageRating, ratingCount } = product;
  const { isMobile } = useWindowDimensions();
  const { openModal } = useCompare();
  const { wishlist, addWish, removeWish } = useWishlist();
  const [isLoading, setIsLoading] = useState(false);

  const wishItem = wishlist.find(({ productId }) => productId === id);

  const handleWishOnClick = async () => {
    setIsLoading(true);
    await (wishItem ? removeWish(wishItem.itemId) : addWish(id));
    setIsLoading(false);
  };

  return (
    <ProductInfoHeader>
      <ReviewsInfo>
        <StarsWrapper>{getStars(Math.floor(averageRating) - 1)}</StarsWrapper>
        <ReviewsCounter>{`(${ratingCount})`}</ReviewsCounter>
      </ReviewsInfo>
      {!isMobile && (
        <HeaderActions>
          <ProductCompareWrapper isVisible onClick={() => openModal(product)}>
            <CompareImage />
            <CompareTextWrapper>
              <CompareText>Comparar</CompareText>
            </CompareTextWrapper>
          </ProductCompareWrapper>
          <WishButton disabled={isLoading} onClick={handleWishOnClick}>
            {wishItem ? <HeartIconFill /> : <HeartIcon />}
          </WishButton>
        </HeaderActions>
      )}
    </ProductInfoHeader>
  );
};

export default ProductHeader;
