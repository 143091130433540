import React from 'react';
import {
  SpecificationContent,
  SpecificationName,
  SpecificationsWrapper,
  SpecificationWrapper,
} from './styles';

type Props = {
  dimensions: { length: string; width: string; height: string };
  weight: string;
};

const Specifications = ({ dimensions, weight }: Props) => {
  const hasWeight = !!weight;
  const hasDimensions = Object.values(dimensions).every(value => !!value);

  if (!hasWeight && !hasDimensions) {
    return <SpecificationWrapper>Nenhuma especificação informada.</SpecificationWrapper>;
  }

  return (
    <SpecificationsWrapper>
      {hasWeight ? (
        <SpecificationWrapper>
          <SpecificationName>PESO</SpecificationName>
          <SpecificationContent>{weight}kg</SpecificationContent>
        </SpecificationWrapper>
      ) : null}
      {hasDimensions ? (
        <SpecificationWrapper>
          <SpecificationName>DIMENSÕES</SpecificationName>
          <SpecificationContent>{`${dimensions.height}x${dimensions.length}x${dimensions.width}cm`}</SpecificationContent>
        </SpecificationWrapper>
      ) : null}
    </SpecificationsWrapper>
  );
};

export default Specifications;
