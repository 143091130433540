import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import useQueryParam from 'hooks/useQueryParam';
import { FilterName, FilterWrapper, ItemName, SeeMoreButton } from '../../styles';
import { CategoriesHeader, CategoryItem } from './styles';
import { Category } from '../../../../@types/woocommerce';

type Props = {
  categories: Category[];
};

const Categories = ({ categories }: Props) => {
  const location = useLocation();
  const setQueryParam = useQueryParam();
  const [shouldShowMore, setShouldShowMore] = useState(false);
  const query = new URLSearchParams(location.search);
  const currentCategory = query.get('category');

  const handleClickOnSeeMore = () => setShouldShowMore(prevState => !prevState);
  const handleClickOnCategory = (id: number) => {
    setQueryParam([{ category: `${id}` }], '/produtos');
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const selectedCategories = categories.slice(0, shouldShowMore ? undefined : 15);
  const mappedCategories = selectedCategories.map(({ id, name }) => {
    const isSelected = !!currentCategory && id === parseInt(currentCategory, 10);

    return (
      <CategoryItem key={id} isSelected={isSelected} onClick={() => handleClickOnCategory(id)}>
        <ItemName isSelected={isSelected}>{name.toLowerCase()}</ItemName>
      </CategoryItem>
    );
  });

  return (
    <FilterWrapper>
      <CategoriesHeader>
        <FilterName>Categorias</FilterName>
      </CategoriesHeader>
      {mappedCategories}
      <SeeMoreButton onClick={handleClickOnSeeMore}>
        {shouldShowMore ? 'Ver menos' : 'Ver mais categorias +'}
      </SeeMoreButton>
    </FilterWrapper>
  );
};

export default Categories;
