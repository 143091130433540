import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';
import useCart from 'hooks/useCart';
import useWindowDimensions from 'hooks/useWindowDimensions';
import useModal from 'hooks/useFilterModal';
import { toFloat } from 'utils/normalizeProduct';
import { CheckboxFormik } from 'components/Checkbox';
import ErrorMessage from 'components/ErrorMessage';
import { BuyButton } from 'components/TotalPriceTab/styles';
import { PriceField } from 'pages/CartPage/components/TotalPriceSection';
import { Price } from 'pages/CartPage/components/CartItem/styles';
import { SpinnerCentralized } from 'pages/ProductPage/components/Ratings/styles';
import { Spinner } from 'pages/Home/components/ScreenLoading/styles';
import { BuyButtonWrapper, Divider, ErrorMessageWrapper, Title, TitleWrapper } from '../../styles';
import { NormalizedProduct } from '../../../../@types/woocommerce';
import {
  PaymentMethod,
  ProductInfo,
  ResumeWrapper,
  AcceptanceTermsText,
  AcceptanceTerms,
  ResumeFieldWrapper,
  ResumeSectionWrapper,
} from './styles';
import { Form } from '../../form';

type Props = {
  products: { product: NormalizedProduct; quantity: number }[];
};

const formatPaymentMethod = (method: string) => {
  switch (method) {
    case 'cod':
      return 'Pagamento em dinheiro na entrega';
    default:
      return null;
  }
};

const ResumeSection = ({ products }: Props) => {
  const { shipping } = useCart();
  const { isMobile } = useWindowDimensions();
  const { values, submitForm, isSubmitting } = useFormikContext<Form>();

  const itemComponents = products.map(({ product, quantity }) => {
    const { id, name, price } = product;

    return (
      <ResumeFieldWrapper key={id}>
        <ProductInfo>
          {quantity}x - {name}
        </ProductInfo>
        <Price
          value={toFloat(price)}
          displayType="text"
          thousandSeparator="."
          decimalSeparator=","
          prefix="R$ "
          fixedDecimalScale
          decimalScale={2}
        />
      </ResumeFieldWrapper>
    );
  });

  const subTotal = products.reduce(
    (acc, { product, quantity }) => acc + toFloat(product.price) * quantity,
    0,
  );
  const total = subTotal + shipping;

  const paymentMethodName = formatPaymentMethod(values.paymentMethod);

  return (
    <ResumeWrapper>
      <ResumeSectionWrapper>
        <TitleWrapper>
          <Title>Resumo</Title>
        </TitleWrapper>
        {itemComponents}
        <Divider />
        <PriceField label="Sub total" price={subTotal} />
        <PriceField label="Frete" price={shipping} />
        <PriceField label="Total" price={total} />
        {paymentMethodName && <PaymentMethod>{paymentMethodName}</PaymentMethod>}
        <AcceptanceTerms>
          <CheckboxFormik name="termsOfAcceptance" />
          <AcceptanceTermsText>Aceito os termos e condições</AcceptanceTermsText>
          <ErrorMessageWrapper>
            <ErrorMessage name="termsOfAcceptance" />
          </ErrorMessageWrapper>
        </AcceptanceTerms>
        {isSubmitting && (
          <SpinnerCentralized>
            <Spinner />
          </SpinnerCentralized>
        )}
        {!isMobile && (
          <BuyButtonWrapper type="submit" onClick={submitForm} disabled={isSubmitting}>
            <BuyButton>FINALIZAR COMPRA</BuyButton>
          </BuyButtonWrapper>
        )}
      </ResumeSectionWrapper>
    </ResumeWrapper>
  );
};

export const ResumeSectionModal = (props: Props) => {
  const { setModalStyles } = useModal();

  const componentHeight = '400px';
  useEffect(() => {
    setModalStyles(({ overlay, content }) => ({
      overlay,
      content: {
        ...content,
        padding: 0,
        transform: `translateY(-${componentHeight})`,
        height: componentHeight,
      },
    }));
  }, [setModalStyles]);

  return <ResumeSection {...props} />;
};

export default ResumeSection;
