import React, { useState, useMemo, useEffect } from 'react';
import { CART_KEY } from 'utils/constants';
import { DiscountType } from '../@types/woocommerce';

interface Coupon {
  code: string;
  amount: number;
  discountType: DiscountType;
}

export interface Zipcode {
  cep: string;
  state: string;
  city: string;
  street: string;
  neighborhood: string;
}

export interface CartItem {
  productId: number;
  quantity: number;
}

interface CartState {
  items: CartItem[];
  zipcode: Zipcode;
  shipping: number;
  coupon: Coupon;
}

export interface CartContextValues {
  zipcode?: Zipcode;
  setZipcode: React.Dispatch<React.SetStateAction<Zipcode | undefined>>;
  shipping: number;
  setShipping: React.Dispatch<React.SetStateAction<number>>;
  items: CartItem[];
  setItems: React.Dispatch<React.SetStateAction<CartItem[]>>;
  coupon?: Coupon;
  setCoupon: React.Dispatch<React.SetStateAction<Coupon | undefined>>;
}

export const CartContext = React.createContext<CartContextValues | null>(null);

const Provider = ({ children }: { children: React.ReactNode }) => {
  const [items, setItems] = useState<CartItem[]>([]);
  const [zipcode, setZipcode] = useState<Zipcode>();
  const [shipping, setShipping] = useState(0);
  const [coupon, setCoupon] = useState<Coupon | undefined>();

  useEffect(() => {
    const stringifiedData = localStorage.getItem(CART_KEY);

    if (stringifiedData) {
      const data: CartState = JSON.parse(stringifiedData);

      setItems(data.items || []);
      setZipcode(data.zipcode);
      setShipping(data.shipping || 0);
      setCoupon(data.coupon);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem(CART_KEY, JSON.stringify({ items, zipcode, shipping, coupon }));
  }, [items, zipcode, shipping, coupon]);

  const context = useMemo(
    () => ({ items, shipping, setShipping, zipcode, setZipcode, setItems, coupon, setCoupon }),
    [items, shipping, zipcode, coupon],
  );

  return <CartContext.Provider value={context}>{children}</CartContext.Provider>;
};

export default Provider;
