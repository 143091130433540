import styled from 'styled-components';
import { generateSpacing } from 'styles/spaces';
import { Title as TitleCSS } from 'styles/typographies';
import colors from 'styles/colors';
import buyButtonStyles from 'components/BuyButton';
import NeutralButton from 'components/NeutralButton';
import TextInput from 'components/Input/TextInput';
import TotalPriceTab from 'components/TotalPriceTab';
import { PageWrapper } from '../styles';

export const CartPageWrapper = styled(PageWrapper)`
  width: 90%;

  max-width: 1050px;

  @media (max-width: 900px) {
    padding-bottom: 140px;
  }
`;

export const PaymentSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const Fallback = styled.div`
  font-family: 'Montserrat', 'sans-serif';
  font-weight: 500;

  margin-bottom: ${generateSpacing(20)};
`;

export const Actions = styled.div`
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;

  @media (min-width: 623px) {
    margin-top: ${generateSpacing(18)};
  }

  @media (min-width: 1000px) {
    justify-content: space-between;
    flex-direction: row;
    align-items: flex-start;
  }
`;

export const ActionTitle = styled.span`
  display: inline-block;
  font-family: 'Montserrat', 'sans-serif';
  font-weight: 600;
  font-size: 1.1rem;
  margin-bottom: ${generateSpacing(10)};
`;

export const ActionSection = styled.div`
  height: 240px;
  flex: 1 1 270px;
  max-width: 290px;
  padding: ${generateSpacing(12)};
  border: 1px solid ${colors.border};
  border-radius: 8px;

  @media (max-width: 622px) {
    margin-bottom: ${generateSpacing(10)};
    max-width: 400px;
  }

  @media (min-width: 800px) and (max-width: 1000px) {
    flex: initial;
    margin-bottom: ${generateSpacing(10)};
    min-width: 350px;
  }
`;

export const ActionInputLabel = styled.label`
  display: block;
  font-family: 'Montserrat', 'sans-serif';
  margin-bottom: ${generateSpacing(7)};
  font-weight: 500;
`;

export const ActionInput = styled(TextInput)`
  width: 100%;
`;

export const ActionSectionButton = styled(NeutralButton)`
  border-color: ${colors.borderButton};
  color: ${colors.emptyText};
  margin-top: ${generateSpacing(7)};
  width: 100%;
  font-size: 1rem;
  font-family: 'Montserrat', 'sans-serif';
  font-weight: 500;
`;

export const ErrorText = styled.span`
  color: #ff0000;
`;

export const ClosedTotalPriceTab = styled(TotalPriceTab)`
  margin-bottom: ${generateSpacing(6)};
`;

export const BuyButton = styled.div`
  ${buyButtonStyles}
  border-radius: 8px;
  padding: ${generateSpacing(6, 0)};
  width: 100%;
  color: #fff;
  text-align: center;
  background-color: #13ab87;

  @media (max-width: 320px) {
    min-width: 110px;
  }

  @media (min-width: 321px) {
    min-width: 160px;
  }

  @media (min-width: 500px) and (max-width: 900px) {
    min-width: 300px;
  }
`;
