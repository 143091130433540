import { nanoid } from 'nanoid';
import React, { useState } from 'react';
import { ICategoryItem } from '../@types';
import {
  CategoryItemContainer,
  Text,
  MoreCategoryContainer,
  MoreCategoryButtonWrapper,
  PopUpWrapper,
} from './styles';

interface CategoryItemProps {
  category: ICategoryItem;
}
interface MoreCategoriesProps {
  categories: Array<ICategoryItem>;
}

const CategoryItem = ({ category }: CategoryItemProps) => {
  return (
    <CategoryItemContainer onClick={category.onClick}>
      <Text>{category.name.toUpperCase()}</Text>
    </CategoryItemContainer>
  );
};

const MoreCategoriesItem = ({ category }: CategoryItemProps) => {
  return (
    <CategoryItemContainer onClick={category.onClick}>
      <Text>{category.name.toUpperCase()}</Text>
    </CategoryItemContainer>
  );
};

export const MoreCategories = ({ categories }: MoreCategoriesProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const mappedCategories = categories.map(category => (
    <MoreCategoriesItem key={nanoid()} category={category} />
  ));
  return (
    <>
      <MoreCategoryContainer
        onMouseEnter={() => setIsOpen(true)}
        onMouseLeave={() => setIsOpen(false)}
      >
        <Text>MAIS</Text>
        {isOpen && (
          <PopUpWrapper onMouseEnter={() => setIsOpen(true)} onMouseLeave={() => setIsOpen(false)}>
            {mappedCategories}
          </PopUpWrapper>
        )}
      </MoreCategoryContainer>
    </>
  );
};

export default CategoryItem;
