import React from 'react';
import { Link } from 'react-router-dom';
import { BottomTabItemContainer, LabelWrapper, Label, IconWrapper } from './styles';

export interface BottomTabItemProps {
  route: string;
  name: string;
  Icon: React.ReactNode;
  SelectedIcon: React.ReactNode;
  isFocused?: boolean;
}

const BottomTabItem = ({ route, name, Icon, SelectedIcon, isFocused }: BottomTabItemProps) => {
  return (
    <Link to={route}>
      <BottomTabItemContainer>
        <IconWrapper>{isFocused ? SelectedIcon : Icon}</IconWrapper>
        <LabelWrapper>
          <Label isFocused={isFocused}>{name}</Label>
        </LabelWrapper>
      </BottomTabItemContainer>
    </Link>
  );
};

export default BottomTabItem;
