import React from 'react';
import { toFloat } from 'utils/normalizeProduct';
import { NormalizedProduct } from '../../../@types/woocommerce';
import { ProductNameWrapper } from '../ProductCard/styles';
import { ProductImage, ImageWrapper, ProductPrice } from '../styles';
import { ProductItemWrapper } from './styles';

type Props = {
  product: NormalizedProduct;
  onClick: () => void;
};

const ProductItem = ({ product, onClick }: Props) => (
  <ProductItemWrapper onClick={onClick}>
    <ImageWrapper>
      <ProductImage src={product.imageUrl} alt={`Produto ${product.name}`} />
    </ImageWrapper>
    <div>
      <ProductNameWrapper>
        <span>{product.name}</span>
      </ProductNameWrapper>
      <div>
        <ProductPrice
          value={toFloat(product.price)}
          defaultValue="0"
          displayType="text"
          thousandSeparator="."
          decimalSeparator=","
          prefix="R$ "
          fixedDecimalScale
          decimalScale={2}
        />
      </div>
    </div>
  </ProductItemWrapper>
);

export default ProductItem;
