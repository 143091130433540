import styled from 'styled-components';
import ButtonReset from 'styles/buttonReset';
import colors from 'styles/colors';

const NeutralButton = styled(ButtonReset)`
  border: 1px solid ${({ disabled }) => (disabled ? colors.border : colors.focusedBorder)};
  width: 46px;
  height: 36px;
  border-radius: 6px;
`;

export default NeutralButton;
