import * as yup from 'yup';
import { emailRegex, phoneNumberRegex, portugueseLocale } from 'utils/constants';

export interface IForm {
  'your-name': string;
  'your-subject': string;
  'your-email': string;
  'your-phone': string;
  'your-message': string;
}

yup.setLocale(portugueseLocale);

const schema = yup.object().shape({
  'your-name': yup.string().required(),
  'your-subject': yup.string().required(),
  'your-email': yup.string().required().matches(emailRegex),
  'your-phone': yup.string().required().matches(phoneNumberRegex),
  'your-message': yup.string().required(),
});

export default schema;
