import styled from 'styled-components';
import { BaseButton } from 'components/Button';
import colors from 'styles/colors';

import { generateSpacing } from '../../../styles/spaces';
import { RegularText } from '../../../styles/typographies';

import ABLALogoSVG from '../../../assets/icons/logo_abla.svg';
import ABLANameSVG from '../../../assets/icons/name_abla.svg';
import CartIconSVG from '../../../assets/icons/icon_cart.svg';
import ProfileIconSVG from '../../../assets/icons/icon_account.svg';
import ArrowIconSVG from '../../../assets/icons/icon_arrow_down.svg';

export const NavBarContainer = styled.div`
  width: 90%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  padding: ${generateSpacing(8, 0)};
`;

export const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;

  margin-right: ${generateSpacing(24)};
`;
export const SearchWrapper = styled.div`
  flex: 1;
  /* margin-right: ${generateSpacing(42)}; */
`;
export const CartWrapper = styled.div`
  margin-right: ${generateSpacing(16)};
`;
export const ProfileWrapper = styled.div`
  display: flex;
  flex-direction: row;

  padding: ${generateSpacing(2, 2)};

  cursor: pointer;
`;

export const ABLALogoImage = styled.img.attrs({
  src: ABLALogoSVG,
})``;

export const ABLANameImage = styled.img.attrs({
  src: ABLANameSVG,
})`
  margin-left: ${generateSpacing(7)};
`;

export const CartButton = styled(BaseButton)`
  display: flex;
  flex-direction: row;
  align-items: center;

  padding: ${generateSpacing(3, 3)};

  border: 1px solid #848484;
  border-radius: 6px;
`;
export const CartIcon = styled.img.attrs({
  src: CartIconSVG,
})``;
export const CartText = styled(RegularText)`
  margin-left: ${generateSpacing(2)};
`;
export const ProfileIcon = styled.img.attrs({
  src: ProfileIconSVG,
})``;
export const ProfileText = styled(RegularText)`
  margin-left: ${generateSpacing(2)};
`;
export const ArrowDownIcon = styled.img.attrs({
  src: ArrowIconSVG,
})`
  margin-left: ${generateSpacing(2)};
`;
export const RightContentWrapper = styled.div`
  flex: 1;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;
export const LeftContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (max-width: 900px) {
    justify-content: center;
  }
`;

export const MenuWrapper = styled.div`
  position: relative;
`;

export const MenuBox = styled.div`
  position: absolute;
  top: 40px;
  right: 0;
  border: 1px solid ${colors.border};
  border-radius: 8px;
  box-shadow: 5px 5px 10px 0px #f5f5f5;
  padding: ${generateSpacing(10, 9, 0)};
  width: 200px;
  background-color: #fff;
  display: ${({ isOpen }: { isOpen?: boolean }) => (isOpen ? 'initial' : 'none')};
`;
