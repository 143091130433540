import React from 'react';
import { useHistory } from 'react-router-dom';
import { ReactComponent as ArrowLeftIcon } from 'assets/icons/icon_arrow_left.svg';
import { ArrowLeftIconWrapper, HeaderText, NestedHeaderWrapper } from './styles';

type Props = {
  name: string;
};

const NestedHeader = ({ name }: Props) => {
  const history = useHistory();

  return (
    <NestedHeaderWrapper>
      <ArrowLeftIconWrapper onClick={() => history.goBack()}>
        <ArrowLeftIcon />
      </ArrowLeftIconWrapper>
      <HeaderText>{name}</HeaderText>
    </NestedHeaderWrapper>
  );
};

export default NestedHeader;
