import { BaseButton } from 'components/Button';
import styled from 'styled-components';
import { generateSpacing } from 'styles/spaces';

export const CategoryItemContainer = styled(BaseButton)`
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  min-width: 200px;
  min-height: 56px;

  padding: ${generateSpacing(5, 10)};

  border-radius: ${({ theme }) => theme.spaces.defaultBorderRadius};

  &:hover {
    background: #c4c4c450;

    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    width: 100%;
  }
`;

export const MoreCategoryContainer = styled(BaseButton)`
  flex: 1;
  position: relative;
  display: inline-block;
  align-items: center;
  justify-content: center;

  padding: ${generateSpacing(3, 10)};
  border-radius: ${({ theme }) => theme.spaces.defaultBorderRadius};
`;

export const Text = styled.div`
  text-align: center;
`;

export const MoreCategoryButtonWrapper = styled(BaseButton)``;

export const PopUpWrapper = styled.div`
  position: absolute;
  z-index: 9999;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  /* height: 400px; */

  background-color: #fff;

  transform: translateY(20px);
`;
