// filter modal types
export const CATEGORY_FILTER = 'CATEGORY_FILTER';
export const PRICE_FILTER = 'PRICE_FILTER';

// local storage keys
export const CART_KEY = 'CART_KEY';
export const USER_KEY = 'USER_KEY';
export const TOKEN_KEY = 'TOKEN_KEY';
export const WISHLIST_KEY = 'WISHLIST_KEY';

// regex validation
export const cnpjRegex = /^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/;
export const zipcodeRegex = /^\d{5}-\d{3}$/;
export const phoneNumberRegex = /^\(\d{2}\) \d{4,5} \d{4}$/;
export const emailRegex = /^[^\s@]+@[^\s@]+$/;

// yup locale
export const portugueseLocale = {
  mixed: {
    required: 'Este campo é obrigatório',
    oneOf: 'Este campo é obrigatório',
  },
  string: {
    matches: 'Formato inválido',
  },
};
