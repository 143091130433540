import React, { useState } from 'react';
import NumberFormat from 'react-number-format';
import useWindowDimensions from 'hooks/useWindowDimensions';
import useWishlist from 'hooks/useWishlist';
import useCompare from 'hooks/useCompare';
import { toFloat } from 'utils/normalizeProduct';
import { NormalizedProduct } from '../../@types/woocommerce';

import {
  ProductSlideItemContainer,
  ProductImage,
  ProductTitleWrapper,
  ProductPriceWrapper,
  ProductVendorWrapper,
  ProductTitleText,
  ProductPriceText,
  ProductVendorText,
  ProductHeader,
  ProductCompareWrapper,
  CompareImage,
  CompareTextWrapper,
  CompareText,
  ProductHeartWrapper,
  HeartImage,
  HeartFillImage,
  ImageSelectorWrapper,
  ProductImageSelect,
  OtherProductImage,
  ProductTitleSemiBoldText,
  ProductPromotionalPriceText,
  ProductRegularPriceText,
  ProductImageWrapper,
  ProductSlideItemWithDetailsContainer,
} from './styles';

interface ProductSlideItemProps {
  product: NormalizedProduct;
}

interface ProductSlideItemWithDatailsProps {
  product: NormalizedProduct;
}

const ProductSlideItem = ({ product }: ProductSlideItemProps) => {
  const { id, imageUrl, name, price, vendor } = product;
  const { openModal } = useCompare();
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const { wishlist, addWish, removeWish } = useWishlist();
  const { isMobile } = useWindowDimensions();

  const wishItem = wishlist.find(({ productId }) => productId === id);
  const isInWishlist = !!wishItem;
  const handleWishClick = async () => {
    setIsLoading(true);
    await (!wishItem ? addWish(id) : removeWish(wishItem.itemId));
    setIsLoading(false);
  };

  function handleOnFocus(value: boolean) {
    setIsFocused(value);
  }

  return (
    <ProductSlideItemContainer
      onMouseEnter={() => handleOnFocus(true)}
      onMouseLeave={() => handleOnFocus(false)}
    >
      <ProductHeader>
        <ProductCompareWrapper isVisible={isFocused || isMobile} onClick={() => openModal(product)}>
          <CompareImage />
          <CompareTextWrapper>
            <CompareText>Comparar</CompareText>
          </CompareTextWrapper>
        </ProductCompareWrapper>

        <ProductHeartWrapper
          disabled={isLoading}
          onClick={() => handleWishClick()}
          isVisible={isInWishlist || isFocused || isMobile}
        >
          {isInWishlist ? <HeartFillImage /> : <HeartImage />}
        </ProductHeartWrapper>
      </ProductHeader>
      <ProductImageWrapper to={`/produtos/${id}`}>
        <ProductImage src={imageUrl} />
      </ProductImageWrapper>
      <ProductTitleWrapper to={`/produtos/${id}`}>
        <ProductTitleText>{name}</ProductTitleText>
      </ProductTitleWrapper>
      <ProductPriceWrapper>
        <NumberFormat
          value={toFloat(price) || undefined}
          defaultValue="0"
          displayType="text"
          thousandSeparator="."
          decimalSeparator=","
          prefix="R$ "
          fixedDecimalScale
          decimalScale={2}
          renderText={value => <ProductPriceText>{value}</ProductPriceText>}
        />
      </ProductPriceWrapper>
      <ProductVendorWrapper isVisible={isFocused || isMobile}>
        <ProductVendorText>{vendor}</ProductVendorText>
      </ProductVendorWrapper>
    </ProductSlideItemContainer>
  );
};

export const ProductSlideItemWithDatails = ({ product }: ProductSlideItemWithDatailsProps) => {
  const { id, images, name, price, regularPrice, vendor } = product;
  const [isLoading, setIsLoading] = useState(false);
  const { wishlist, addWish, removeWish } = useWishlist();
  const { openModal } = useCompare();
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [currentImageSelected, setCurrentImageSelected] = useState<string>(images[0].src || '');
  const { isMobile } = useWindowDimensions();
  const wishItem = wishlist.find(({ productId }) => productId === id);
  const isInWishlist = !!wishItem;
  const handleWishClick = async () => {
    setIsLoading(true);
    await (!wishItem ? addWish(id) : removeWish(wishItem.itemId));
    setIsLoading(false);
  };
  function handleOnFocus(value: boolean) {
    setIsFocused(value);
  }

  function isSelected(imageUrl: string) {
    return imageUrl === currentImageSelected;
  }

  const regularPriceView = (
    <NumberFormat
      value={toFloat(regularPrice) || undefined}
      defaultValue="0"
      displayType="text"
      thousandSeparator="."
      decimalSeparator=","
      prefix="R$ "
      fixedDecimalScale
      decimalScale={2}
      renderText={value => <ProductRegularPriceText>{value}</ProductRegularPriceText>}
    />
  );

  const hasPromotion = toFloat(price) < toFloat(regularPrice);

  return (
    <ProductSlideItemWithDetailsContainer
      onMouseEnter={() => handleOnFocus(true)}
      onMouseLeave={() => handleOnFocus(false)}
    >
      <ProductHeader>
        <ProductCompareWrapper isVisible={isFocused || isMobile} onClick={() => openModal(product)}>
          <CompareImage />
          <CompareTextWrapper>
            <CompareText>Comparar</CompareText>
          </CompareTextWrapper>
        </ProductCompareWrapper>

        <ProductHeartWrapper
          disabled={isLoading}
          onClick={handleWishClick}
          isVisible={isFocused || isMobile}
        >
          {isInWishlist ? <HeartFillImage /> : <HeartImage />}
        </ProductHeartWrapper>
      </ProductHeader>
      <ProductImageWrapper to={`/produtos/${id}`}>
        <ProductImage src={currentImageSelected} />
      </ProductImageWrapper>
      <ImageSelectorWrapper>
        {images.slice(0, 3).map(image => (
          <ProductImageSelect
            key={image.id}
            isSelected={isSelected(image.src)}
            onClick={() => setCurrentImageSelected(image.src)}
          >
            <OtherProductImage src={image.src} />
          </ProductImageSelect>
        ))}
      </ImageSelectorWrapper>
      <ProductTitleWrapper to={`/produtos/${id}`}>
        <ProductTitleSemiBoldText>{name}</ProductTitleSemiBoldText>
      </ProductTitleWrapper>
      <ProductPriceWrapper center>
        {hasPromotion && regularPriceView}
        <ProductPromotionalPriceText>
          <NumberFormat
            value={toFloat(price) || undefined}
            defaultValue="0"
            displayType="text"
            thousandSeparator="."
            decimalSeparator=","
            prefix="R$ "
            fixedDecimalScale
            decimalScale={2}
            renderText={value => <ProductPromotionalPriceText>{value}</ProductPromotionalPriceText>}
          />
        </ProductPromotionalPriceText>
      </ProductPriceWrapper>
      <ProductVendorWrapper center isVisible={isFocused || isMobile}>
        <ProductVendorText>{vendor}</ProductVendorText>
      </ProductVendorWrapper>
    </ProductSlideItemWithDetailsContainer>
  );
};

export default ProductSlideItem;
