import React, { InputHTMLAttributes } from 'react';

import { SearchContainer, TextInput, SearchIconImage, Button } from './styles';

type SearchProps = {
  onSearch: (event: React.FormEvent) => void;
};

const Search = ({ onSearch, ...props }: InputHTMLAttributes<HTMLInputElement> & SearchProps) => {
  return (
    <SearchContainer onSubmit={onSearch}>
      <TextInput type="text" {...props} />

      <Button type="submit">
        <SearchIconImage />
      </Button>
    </SearchContainer>
  );
};

export default Search;
