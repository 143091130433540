import React from 'react';
import { SearchTitle, CapitalizedText } from './styles';

type SearchHeaderProps = { categoryName?: string; search: string | null };

const SearchHeader = ({ categoryName, search }: SearchHeaderProps) => {
  if (search) {
    return <SearchTitle>Resultados da busca &quot;{search}&quot;</SearchTitle>;
  }

  return categoryName ? (
    <SearchTitle>
      <CapitalizedText>{categoryName.toLowerCase()}</CapitalizedText>
    </SearchTitle>
  ) : null;
};

SearchHeader.defaultProps = {
  categoryName: '',
};

export default SearchHeader;
