import React from 'react';
import NumberFormat from 'react-number-format';
import formatPhoneNumber from 'utils/formatPhoneNumber';
import { FieldWrapper, PaymentInput, Divider } from '../../styles';
import PaymentField from '../PaymentField';

const Contact = () => (
  <>
    <FieldWrapper>
      <PaymentField
        label="Telefone"
        name="telephone"
        placeholder="(99) 9999 9999"
        format={formatPhoneNumber}
        as={NumberFormat}
        customInput={PaymentInput}
      />
      <PaymentField
        label="Celular (opcional)"
        name="cellphone"
        placeholder="(99) 99999 9999"
        format={formatPhoneNumber}
        as={NumberFormat}
        customInput={PaymentInput}
      />
    </FieldWrapper>
    <FieldWrapper>
      <PaymentField
        label="E-mail"
        name="email"
        as={PaymentInput}
        placeholder="lucas@exemplo.com.br"
      />
    </FieldWrapper>
    <Divider />
  </>
);

export default Contact;
