import styled from 'styled-components';
import colors from 'styles/colors';
import { generateSpacing } from 'styles/spaces';

export const SpecificationsWrapper = styled.div`
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const SpecificationWrapper = styled.div`
  display: flex;
  align-items: center;
  font-family: 'Montserrat', 'sans-serif';
  font-weight: 500;

  @media (max-width: 700px) {
    flex-direction: column;
    margin-bottom: ${generateSpacing(7)};
  }
`;

export const SpecificationName = styled.div`
  background-color: ${colors.backgroundPrimary};
  border: 1px solid ${colors.backgroundPrimary};
  border-radius: 4px 0 0 4px;
  padding: ${generateSpacing(3)};
  width: 110px;

  @media (min-width: 400px) {
    width: 160px;
    padding: ${generateSpacing(3, 8)};
  }

  @media (min-width: 700px) {
    min-width: auto;
    width: 200px;
    padding: ${generateSpacing(3, 8)};
  }
`;

export const SpecificationContent = styled.div`
  border: 1px solid #c4c4c4;
  color: #747474;
  border-radius: 0 4px 4px 0;
  padding: ${generateSpacing(3, 5)};
  min-width: 110px;
  font-size: 0.7rem;

  @media (min-width: 400px) {
    padding: ${generateSpacing(3, 8)};
    min-width: 160px;
    font-size: 0.9rem;
  }
`;
