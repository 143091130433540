import NeutralButton from 'components/NeutralButton';
import Textarea from 'components/Textarea';
import styled from 'styled-components';
import colors from 'styles/colors';
import { generateSpacing } from 'styles/spaces';
import { SectionTitle } from 'styles/typographies';

export const SpinnerCentralized = styled.div`
  text-align: center;
`;

export const Reviews = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: ${generateSpacing(15)};
`;

export const ReviewWrapper = styled.div`
  padding: ${generateSpacing(12)};
  flex: 1 1 450px;
  flex-wrap: wrap;
  min-width: 250px;
  max-width: 450px;
  border: 1px solid ${colors.border};
  border-radius: 8px;
  height: 190px;
  margin: 0 auto;

  @media (max-width: 1111px) {
    margin-bottom: ${generateSpacing(10)};
  }
`;

export const ReviewHeader = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: ${generateSpacing(3)};
`;

export const Reviewer = styled(SectionTitle)``;

export const ReviewBody = styled.div`
  color: ${colors.emptyText};
  line-break: anywhere;
`;

export const CommentSectionTitle = styled.div`
  margin-bottom: ${generateSpacing(10)};
`;

export const CommentFieldWrapper = styled.div`
  margin-bottom: ${generateSpacing(6)};
`;

export const Comment = styled(Textarea)`
  width: 100%;
  height: 120px;
  padding: ${generateSpacing(8)};
`;

export const StarsReview = styled.div`
  @media (min-width: 310px) {
    line-height: 0;
  }
`;

export const StarsAvaliationText = styled.div`
  display: inline-block;
  margin-right: ${generateSpacing(8)};
  color: ${colors.emptyText};
`;

export const StarsWrapper = styled.div`
  display: inline-block;
  vertical-align: middle;
  font-size: 1.7rem;

  & div:not(:last-child) {
    margin-right: ${generateSpacing(2)};
  }
`;

export const ReviewSubmit = styled.div`
  margin-top: ${generateSpacing(8)};
`;

export const ReviewSubmitButton = styled(NeutralButton).attrs({ type: 'submit' })`
  width: 110px;
  height: 45px;
`;

export const ReviewSubmitButtonText = styled(SectionTitle)`
  font-size: 0.9rem;
`;
