import React from 'react';

import { PartnerItemContainer, PartnerImage } from './styles';

export interface PartnerProps {
  imageUrl: string;
}
interface PartnerItemProps {
  partner: PartnerProps;
}
const PartnerItem = ({ partner }: PartnerItemProps) => {
  const { imageUrl } = partner;
  return (
    <PartnerItemContainer>
      <PartnerImage src={imageUrl} />
    </PartnerItemContainer>
  );
};

export default PartnerItem;
