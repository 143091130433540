import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { RouteComponentProps } from 'react-router-dom';
import WooCommerceApi from 'woocommerceApi/base';
import normalizeProduct, { toFloat } from 'utils/normalizeProduct';
import useCart from 'hooks/useCart';
import useWindowDimensions from 'hooks/useWindowDimensions';
import NestedHeader from 'components/Header/NestedHeader';
import Badges from 'components/Badges';
import Specifications from 'components/Specifications';
import QuantityButtons from 'components/QuantityButtons';
import ScreenLoading from '../Home/components/ScreenLoading';
import { NormalizedProduct, Product } from '../../@types/woocommerce';
import { PageWrapper } from '../styles';
import {
  ProductImagesInfo,
  ProductInfoSection,
  ProductTextInfo,
  ProductName,
  DetailsText,
  InfoTextWrapper,
  SubSectionWrapper,
  PriceText,
  ProductRegularPriceText,
  Description,
  InjectedHTMLWrapper,
  AddCartSubSection,
} from './styles';
import ProductHeader from './components/ProductHeader';
import ImageSelector from './components/ImageSelector';
import SectionSelector from './components/SectionSelector';
import Ratings from './components/Ratings';
import RecommendedCarousel from './components/RecommendedCarousel';
import CartButton from './components/CartButton';
import Shipping from './components/Shipping';
import BottomTab from './components/BottomTab';
import BuyNowButton from './components/BuyNowButton';

interface Params {
  id: string;
}

const MetaSection = ({
  product: { name, vendor, sku, regularPrice, price },
}: {
  product: NormalizedProduct;
}) => {
  const regularPriceView = (
    <InfoTextWrapper>
      <ProductRegularPriceText
        value={toFloat(regularPrice) || undefined}
        defaultValue="0"
        displayType="text"
        thousandSeparator="."
        decimalSeparator=","
        prefix="R$ "
        fixedDecimalScale
        decimalScale={2}
      />
    </InfoTextWrapper>
  );
  const hasPromotion = toFloat(price) < toFloat(regularPrice);

  return (
    <>
      <InfoTextWrapper>
        <ProductName>{name}</ProductName>
      </InfoTextWrapper>
      <InfoTextWrapper>
        <DetailsText>Vendedor: {vendor}</DetailsText>
      </InfoTextWrapper>
      <InfoTextWrapper>
        <DetailsText>SKU: {sku || 'nenhum SKU informado'}</DetailsText>
      </InfoTextWrapper>
      <SubSectionWrapper>
        {hasPromotion && regularPriceView}
        <PriceText
          value={toFloat(price) || undefined}
          defaultValue="0"
          displayType="text"
          thousandSeparator="."
          decimalSeparator=","
          prefix="R$ "
          fixedDecimalScale
          decimalScale={2}
        />
      </SubSectionWrapper>
    </>
  );
};

const ProductPage = ({ match }: RouteComponentProps<Params>) => {
  const [selectedImage, setSelectedImage] = useState<string>();
  const { isMobile, width } = useWindowDimensions();
  const { items } = useCart();

  const { id: productId } = match.params;
  const productIdInt = parseInt(productId, 10);
  const cartItem = items.find(item => item.productId === productIdInt);

  const [quantity, setQuantity] = useState<number | ''>(cartItem ? cartItem.quantity : 0);

  const { data, isLoading, error } = useQuery<Product>(['product', productId], () =>
    WooCommerceApi.get(`products/${productId}`).then(response => response.data),
  );

  const hasBottomTab = width <= 541;

  const handleQuantityOnChange = (newQuantity: number | '') => setQuantity(newQuantity);
  const handleQuantityControlsOnChange = (quantityIncrement: number) =>
    setQuantity(prevState => (prevState || 0) + quantityIncrement);

  useEffect(() => {
    setSelectedImage(undefined);
  }, [productId]);

  useEffect(() => {
    setQuantity(prevState => cartItem?.quantity || prevState);
  }, [cartItem]);

  if (isLoading || error || !data) {
    return <ScreenLoading />;
  }

  const normalizedProduct = normalizeProduct(data);
  const {
    imageUrl,
    images,
    description,
    dimensions,
    weight,
    metaData,
    price,
    regularPrice,
  } = normalizedProduct;

  const productFeatures = metaData.find(({ key }) => key === 'product_features_desc');

  const selectSections = [
    {
      name: 'descrição',
      component: <Description dangerouslySetInnerHTML={{ __html: description }} />,
    },
    {
      name: 'especificações',
      component: <Specifications dimensions={dimensions} weight={weight} />,
    },
    { name: 'avaliações', component: <Ratings productId={productIdInt} /> },
  ];

  const cartButtonProps = {
    productId: productIdInt,
    quantity: quantity || 0,
    quantityOnChange: handleQuantityOnChange,
  };

  return (
    <>
      {isMobile && <NestedHeader name="Produtos" />}
      <PageWrapper>
        <ProductInfoSection>
          <ProductImagesInfo>
            <ImageSelector
              initialImage={imageUrl}
              images={images}
              selectedImage={selectedImage}
              onChange={(src: string) => setSelectedImage(src)}
            />
          </ProductImagesInfo>
          <ProductTextInfo>
            <ProductHeader product={normalizedProduct} />
            <MetaSection product={normalizedProduct} />
            {productFeatures && (
              <SubSectionWrapper>
                <InjectedHTMLWrapper dangerouslySetInnerHTML={{ __html: productFeatures.value }} />
              </SubSectionWrapper>
            )}
            <SubSectionWrapper>
              <QuantityButtons
                minQuantity={cartItem ? 1 : 0}
                quantity={quantity}
                onChange={handleQuantityOnChange}
                onChangeControls={handleQuantityControlsOnChange}
              />
            </SubSectionWrapper>
            <AddCartSubSection>
              {!hasBottomTab && <CartButton {...cartButtonProps} />}
              <BuyNowButton productId={productIdInt} quantity={quantity || 0} />
            </AddCartSubSection>
            <Shipping />
          </ProductTextInfo>
        </ProductInfoSection>
        <SectionSelector selects={selectSections} />
        <RecommendedCarousel />
        <Badges />
      </PageWrapper>
      <BottomTab
        price={price}
        regularPrice={regularPrice}
        CartButton={<CartButton {...cartButtonProps} />}
      />
    </>
  );
};

export default ProductPage;
