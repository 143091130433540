import React from 'react';
import ReactModal from 'react-modal';
import { Link } from 'react-router-dom';
import { toFloat } from 'utils/normalizeProduct';
import useFilterModal from 'hooks/useFilterModal';
import useCart from 'hooks/useCart';
import { ReactComponent as ArrowUpIcon } from '../../assets/icons/icon_arrow_up.svg';
import { ReactComponent as ArrowDownIcon } from '../../assets/icons/icon_arrow_down.svg';
import { NormalizedProduct } from '../../@types/woocommerce';
import {
  PriceSection,
  BottomTabWrapper,
  BottomTabPrice,
  TotalTitle,
  ModalButton,
  BuyButton,
} from './styles';

ReactModal.setAppElement('#root');

type Props = {
  actionComponent?: React.ReactNode;
  products: { product: NormalizedProduct; quantity: number }[];
  className?: string;
};

const TotalPriceTab = ({ products, className, actionComponent }: Props) => {
  const { shipping } = useCart();
  const { isOpen, openModal, closeModal } = useFilterModal();

  const subTotal = products.reduce(
    (acc, { product, quantity }) => acc + toFloat(product.price) * quantity,
    0,
  );
  const total = subTotal + shipping;

  return (
    <BottomTabWrapper className={className}>
      <PriceSection>
        <ModalButton type="button" onClick={() => (isOpen ? closeModal() : openModal())}>
          {isOpen ? <ArrowDownIcon /> : <ArrowUpIcon />}
        </ModalButton>
        <div>
          <div>
            <TotalTitle>TOTAL</TotalTitle>
          </div>
          <BottomTabPrice
            value={total}
            displayType="text"
            thousandSeparator="."
            decimalSeparator=","
            prefix="R$ "
            fixedDecimalScale
            decimalScale={2}
          />
        </div>
      </PriceSection>
      {actionComponent}
    </BottomTabWrapper>
  );
};

TotalPriceTab.defaultProps = {
  className: undefined,
  actionComponent: (
    <Link to="faturamento">
      <BuyButton>CONTINUAR</BuyButton>
    </Link>
  ),
};

export default TotalPriceTab;
