import styled, { css } from 'styled-components';
import colors from './colors';

export const RegularText = styled.span`
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
  font-weight: 400;
`;

export const SemiBoldText = styled.span`
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 1rem;
`;

export const BoldText = styled.span`
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  font-size: 1rem;
`;

export const SectionTitle = styled.span`
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 1.3rem;
`;

export const Title = css`
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 1.5rem;
`;

export const detailsText = css`
  font-size: 0.8rem;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  color: ${colors.emptyText};
`;

export const filterItem = css`
  font-family: 'Montserrat', sans-serif;
  text-transform: capitalize;
  font-size: 0.9rem;
`;
