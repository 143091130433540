import React from 'react';
import { useField } from 'formik';
import { FieldWrapper, PaymentInput } from '../../styles';
import Address from '../Address';
import PaymentField from '../PaymentField';

const ShippingAddress = () => {
  const [hasShippingAddress] = useField('hasShippingAddress');
  const [zipcodeField] = useField('shipping.zipcode');

  if (!hasShippingAddress.value) {
    return null;
  }

  return (
    <>
      <FieldWrapper>
        <PaymentField
          label="Nome"
          name="shipping.name"
          as={PaymentInput}
          placeholder="Digite aqui seu nome..."
        />
        <PaymentField
          label="Sobrenome"
          name="shipping.lastName"
          as={PaymentInput}
          placeholder="Digite aqui seu sobrenome..."
        />
      </FieldWrapper>
      <FieldWrapper>
        <PaymentField
          label="Nome da empresa (opcional)"
          name="shipping.company"
          as={PaymentInput}
          placeholder="Digite aqui o nome da empresa..."
        />
      </FieldWrapper>
      <Address fieldSuffix="shipping" zipcode={zipcodeField.value} />
    </>
  );
};

export default ShippingAddress;
