import styled from 'styled-components';
import { generateSpacing } from 'styles/spaces';
import ButtonReset from 'styles/buttonReset';
import SearchIconSVG from '../../../assets/icons/icon_search.svg';

export const SearchContainer = styled.form`
  flex: 1;
  display: flex;
  flex-direction: row;

  background-color: #f8f8f8;
  border-radius: '12px';

  padding: ${generateSpacing(3, 8)};
`;
export const TextInput = styled.input`
  width: 100%;

  border: none;
  background: #f8f8f8;
`;

export const SearchIconImage = styled.img.attrs({
  src: SearchIconSVG,
})``;

export const Button = styled(ButtonReset)`
  margin-left: ${generateSpacing(3)};
  display: inline-flex;
  align-items: center;
`;
