import React, { useState, createContext, useMemo, useEffect } from 'react';
import ReactModal from 'react-modal';
import { createAction, ActionType } from 'typesafe-actions';
import { CATEGORY_FILTER, PRICE_FILTER } from 'utils/constants';
import { Category } from '../@types/woocommerce';

export const openModalActions = {
  openPriceFilter: createAction(PRICE_FILTER)<void>(),
  openCategoryFilter: createAction(CATEGORY_FILTER)<Category[]>(),
};

export type ModalEvent = ActionType<typeof openModalActions>;

interface FilterModalContextValues {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  modal: ModalEvent | undefined;
  setModal: React.Dispatch<React.SetStateAction<ModalEvent | undefined>>;
  modalStyles: ReactModal.Styles;
  setModalStyles: React.Dispatch<React.SetStateAction<ReactModal.Styles>>;
  hasAnimation: boolean;
  setHasAnimation: React.Dispatch<React.SetStateAction<boolean>>;
}

const initialStyles: ReactModal.Styles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    width: '100%',
  },
  content: {
    right: '0',
    left: '0',
    top: '100%',
    margin: '0 auto',
    width: '100%',
    padding: '25px',
    borderRadius: '12px 12px 0 0',
    transform: 'translateY(0px)',
    transition: 'transform 0.3s',
  },
};

export const FilterModalContext = createContext<FilterModalContextValues | null>(null);

const Provider = ({ children }: { children: React.ReactNode }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [hasAnimation, setHasAnimation] = useState(false);
  const [modal, setModal] = useState<ModalEvent>();
  const [modalStyles, setModalStyles] = useState<ReactModal.Styles>(initialStyles);

  useEffect(() => {
    if (!isOpen) {
      if (hasAnimation) {
        setModalStyles(({ overlay, content }) => ({
          overlay,
          content: {
            ...content,
            transform: 'translateY(0px)',
          },
        }));
      } else {
        setModalStyles(initialStyles);
      }
    }
  }, [isOpen, hasAnimation]);

  const context = useMemo(
    () => ({
      isOpen,
      setIsOpen,
      modal,
      setModal,
      modalStyles,
      setModalStyles,
      hasAnimation,
      setHasAnimation,
    }),
    [isOpen, modal, modalStyles, hasAnimation],
  );

  return <FilterModalContext.Provider value={context}>{children}</FilterModalContext.Provider>;
};

export default Provider;
