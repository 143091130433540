import styled from 'styled-components';
import TextInput from 'components/Input/TextInput';
import colors from 'styles/colors';
import { generateSpacing } from 'styles/spaces';

export const ShippingHeader = styled.div`
  margin: ${generateSpacing(10, 0, 7)};
`;

export const ShippingText = styled.span`
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  color: ${colors.emptyText};
`;

export const ShippingTextInput = styled(TextInput)`
  vertical-align: top;
  text-align: start;
  width: 120px;
  margin-right: ${generateSpacing(10)};
`;

export const NotCEPButton = styled.a`
  display: inline-block;
  margin-left: ${generateSpacing(5)};
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  color: ${colors.emptyText};
`;
