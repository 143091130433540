import styled from 'styled-components';
import { generateSpacing } from 'styles/spaces';

export const Input = styled.input`
  border: none;
  border-radius: 6px;
  padding: ${generateSpacing(0, 7)};
  height: 100%;
  width: 100%;
  position: absolute;
  background-color: #f8f8f8;
`;

export const InputWrapper = styled.div`
  display: inline-block;
  position: relative;
  height: 36px;
  width: 46px;
`;
