import React from 'react';
import { SectionTitle } from 'styles/typographies';
import {
  FeaturedProductsContainer,
  SectionTitleWrapper,
  ProductsWrapper,
  FeaturedProductItemContainer,
  FeaturedProductImage,
} from './styles';

interface FeaturedProduct {
  id: number;
  imageUrl: string;
  onPress: () => void;
}
const FeaturedProductItem = ({ id, imageUrl, onPress }: FeaturedProduct) => {
  return (
    <FeaturedProductItemContainer>
      <FeaturedProductImage src={imageUrl} />
    </FeaturedProductItemContainer>
  );
};

interface FeaturedProductsProps {
  featuredBanners: Array<{
    src: string;
    onClick: () => void;
    id: number;
  }>;
}

const FeaturedProducts = ({ featuredBanners }: FeaturedProductsProps) => {
  const mappedFeaturedProducts = featuredBanners.map(product => (
    <FeaturedProductItem
      key={product.id}
      id={product.id}
      imageUrl={product.src}
      onPress={product.onClick}
    />
  ));
  return (
    <FeaturedProductsContainer>
      <SectionTitleWrapper>
        <SectionTitle>Destaque</SectionTitle>
      </SectionTitleWrapper>
      <ProductsWrapper>{mappedFeaturedProducts}</ProductsWrapper>
    </FeaturedProductsContainer>
  );
};

export default FeaturedProducts;
