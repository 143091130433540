const DEFAULT_SPACE = 2;

export const generateSpacing = (
  multiplier1 = 1,
  multiplier2?: number,
  multiplier3?: number,
  multiplier4?: number,
) => {
  if (multiplier1 >= 0) {
    let paddingConstructor = '';
    paddingConstructor += `${DEFAULT_SPACE * multiplier1}px`;

    if (multiplier2 !== undefined && multiplier2 >= 0) {
      paddingConstructor += ` ${DEFAULT_SPACE * multiplier2}px`;
    }

    if (multiplier3 !== undefined && multiplier3 >= 0) {
      paddingConstructor += ` ${DEFAULT_SPACE * multiplier3}px`;
    }

    if (multiplier4 !== undefined && multiplier4 >= 0) {
      paddingConstructor += ` ${DEFAULT_SPACE * multiplier4}px`;
    }
    return paddingConstructor;
  }
  return '0px';
};

export default {
  defaultBorderRadius: '12px',
};
