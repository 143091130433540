import styled from 'styled-components';
import NumberFormat from 'react-number-format';
import buyButtonStyles from 'components/BuyButton';
import buttonReset from 'styles/buttonReset';
import { generateSpacing } from 'styles/spaces';

export const BottomTabWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: ${generateSpacing(8, 0)};
`;

export const PriceSection = styled.div`
  display: flex;
`;

export const BottomTabPrice = styled(NumberFormat)`
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 1rem;

  @media (max-width: 300px) {
    font-size: 0.8rem;
  }
`;

export const TotalTitle = styled.span`
  font-family: 'Montserrat', 'sans-serif';
  font-weight: 500;
  font-size: 0.8rem;
`;

export const ModalButton = styled(buttonReset)`
  margin-right: ${generateSpacing(5)};
`;

export const BuyButton = styled.div`
  ${buyButtonStyles}
  border-radius: 8px;
  padding: ${generateSpacing(6, 2)};
  color: #fff;
  text-align: center;
  background-color: #13ab87;

  @media (max-width: 320px) {
    width: 110px;
    font-size: 0.8rem;
  }

  @media (min-width: 321px) {
    min-width: 160px;
  }

  @media (min-width: 500px) and (max-width: 900px) {
    min-width: 300px;
  }
`;
