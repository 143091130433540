import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { PageWrapper } from 'pages/styles';
import LogoIconSVG from 'assets/icons/logo_abla.svg';
import LogoNameSVG from 'assets/icons/name_abla.svg';
import colors from 'styles/colors';
import { generateSpacing } from 'styles/spaces';

export const NotFoundPageWrapper = styled(PageWrapper)`
  margin: auto;
  height: 83%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const LogoWrapper = styled.div`
  margin: ${generateSpacing(20, 0, 30)};
  text-align: center;
`;

export const LogoIcon = styled.img.attrs({ src: LogoIconSVG })`
  width: 100%;
  max-width: 100px;
`;

export const LogoName = styled.img.attrs({ src: LogoNameSVG })`
  width: 100%;
  max-width: 150px;
`;

export const EmphaticText = styled.span`
  font-family: 'Montserrat', 'sans-serif';
  font-weight: 600;
  font-size: 6.5rem;
  color: ${colors.placeholder};
`;

export const NotFoundText = styled.span`
  font-family: 'Montserrat', 'sans-serif';
  font-weight: 500;
  font-size: 1.1rem;
  color: ${colors.quaternary};
`;

export const HomeLinkWrapper = styled.div`
  margin-top: ${generateSpacing(12)};
`;

export const HomeLink = styled(Link)`
  color: ${colors.emptyText};
  font-size: 1rem;
  text-decoration: underline;
`;
