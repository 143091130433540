import React, { useEffect, useRef } from 'react';
import { Field, ErrorMessageProps, useFormikContext } from 'formik';
import useModal from 'hooks/useFilterModal';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { FieldColumn, PaymentLabel } from 'pages/Payment/styles';
import ErrorMessage from 'components/ErrorMessage';
import { initialValues } from '../../form';

interface GenericProps {
  [k: string]: unknown;
}

type ErrorMessageWithRefEffectProps = {
  onErrorRefEffect: (ref: React.MutableRefObject<HTMLDivElement | null>) => unknown;
} & ErrorMessageProps;

interface Props extends GenericProps {
  label: string;
  name: string;
  as: React.ReactNode;
  id?: string;
}

const ErrorMessageWithScroll = ({ onErrorRefEffect, ...props }: ErrorMessageWithRefEffectProps) => {
  const { name } = props;
  const errorRef = useRef(null);
  const { errors, isSubmitting, isValidating } = useFormikContext();

  useEffect(() => {
    const errorKeys = Object.keys(errors);

    if (!isSubmitting || isValidating || errorKeys.length < 0) {
      return;
    }

    const sortedErrorKeys = Object.keys(initialValues).filter(key => errorKeys.includes(key));

    if (sortedErrorKeys.length > 0 && sortedErrorKeys[0] === name) {
      onErrorRefEffect(errorRef);
    }
  }, [errors, name, onErrorRefEffect, isSubmitting, isValidating]);

  return <ErrorMessage {...props} ref={errorRef} />;
};

const PaymentField = ({ label, name, as: is, id, ...props }: Props) => {
  const { isOpen, closeModal } = useModal();
  const { isMobile } = useWindowDimensions();

  const scrollOnError = (ref: React.MutableRefObject<HTMLDivElement | null>) => {
    if (ref.current) {
      const top = ref.current.getBoundingClientRect().top + window.scrollY - 100;
      window.scrollTo({ top, behavior: 'smooth' });
      if (isOpen) {
        closeModal();
      }
    }
  };

  return (
    <FieldColumn>
      <PaymentLabel htmlFor={id}>{label}</PaymentLabel>
      <Field name={name} as={is} id={id} {...props} />
      {isMobile ? (
        <ErrorMessageWithScroll name={name} onErrorRefEffect={scrollOnError} />
      ) : (
        <ErrorMessage name={name} />
      )}
    </FieldColumn>
  );
};

PaymentField.defaultProps = {
  id: undefined,
};

export default PaymentField;
