import styled from 'styled-components';
import buttonReset from 'styles/buttonReset';
import colors from 'styles/colors';
import { generateSpacing } from 'styles/spaces';

export const SearchWrapper = styled.div`
  margin-bottom: ${generateSpacing(6)};
`;

export const SectionTitleWrapper = styled.div`
  margin-bottom: ${generateSpacing(12)};
`;

export const ProductsList = styled.div`
  padding-right: ${generateSpacing(7)};

  @media (min-width: 600px) {
    height: 100%;
    overflow-y: auto;
  }
`;

export const EmptyProductWrapper = styled(buttonReset)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${generateSpacing(4)};
  border: 1px solid ${colors.border};
  border-radius: 7px;
  flex: 1;
`;

export const PlusWrapper = styled.div`
  margin-bottom: ${generateSpacing(7)};
`;

export const AddProduct = styled.span`
  font-size: 1.1rem;
  font-family: 'Montserrat', 'sans-serif';
  font-weight: 500;
`;
