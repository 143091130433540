import React from 'react';
import { useQuery } from 'react-query';
import WooCommerceApi from 'woocommerceApi/base';
import useCart from 'hooks/useCart';
import useWindowDimensions from 'hooks/useWindowDimensions';
import useModal from 'hooks/useFilterModal';
import ScreenLoading from 'pages/Home/components/ScreenLoading';
import FilterModalProvider from 'contexts/FilterModalContext';
import TotalPriceTab from 'components/TotalPriceTab';
import BottomTabNavigation from 'components/BottomTabNavigation';
import { BottomTabNavigationContainer } from 'components/BottomTabNavigation/styles';
import { NormalizedProduct, Product } from '../../@types/woocommerce';
import { TitleWrapper, Title } from '../styles';
import { CartPageWrapper, Fallback, Actions, ClosedTotalPriceTab } from './styles';
import CartItem, { DesktopCartItem, MobileCartItem } from './components/CartItem';
import TotalPriceSection from './components/TotalPriceSection';
import CouponSection from './components/CouponSection';
import ZipcodeSection from './components/ZipcodeSection';
import TotalPriceModal from './components/TotalPriceModal';

const CartBottomTab = ({
  products,
}: {
  products: { product: NormalizedProduct; quantity: number }[];
}) => {
  const { isOpen } = useModal();

  if (isOpen) {
    return (
      <BottomTabNavigationContainer>
        <TotalPriceTab products={products} />
      </BottomTabNavigationContainer>
    );
  }

  return (
    <BottomTabNavigation>
      <ClosedTotalPriceTab products={products} />
    </BottomTabNavigation>
  );
};

const CartPage = () => {
  const { isMobile } = useWindowDimensions();
  const { items, count, reduceFetchedProducts } = useCart();

  const itemIds = items.map(({ productId }) => productId);

  const { data, isLoading, error } = useQuery<Product[]>(['cartProducts', count], () =>
    count === 0
      ? []
      : WooCommerceApi.get('products', { include: itemIds }).then(response => response.data),
  );

  if (isLoading || error || !data) {
    return <ScreenLoading />;
  }

  const transformedItems = items.reduce<{ product: NormalizedProduct; quantity: number }[]>(
    (acc, currentItem) => reduceFetchedProducts(acc, currentItem, data),
    [],
  );

  const ResponsiveCartItem = isMobile ? MobileCartItem : DesktopCartItem;

  const cartItems = transformedItems.map(({ product, quantity }) => (
    <CartItem
      render={props => <ResponsiveCartItem {...props} />}
      key={product.id}
      product={product}
      quantity={quantity}
    />
  ));

  return (
    <FilterModalProvider>
      <TotalPriceModal products={transformedItems} />
      <CartPageWrapper>
        <TitleWrapper>
          <Title>Carrinho</Title>
        </TitleWrapper>
        {count ? (
          <div>{cartItems}</div>
        ) : (
          <Fallback>Nenhum produto foi adicionado ao carrinho</Fallback>
        )}
        <Actions>
          <ZipcodeSection />
          <CouponSection />
          {!isMobile && <TotalPriceSection products={transformedItems} />}
        </Actions>
      </CartPageWrapper>
      {isMobile && <CartBottomTab products={transformedItems} />}
    </FilterModalProvider>
  );
};

export default CartPage;
