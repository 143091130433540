import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { toFloat } from 'utils/normalizeProduct';
import useCart from 'hooks/useCart';
import useFilterModal from 'hooks/useFilterModal';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { Title } from 'pages/styles';
import { NormalizedProduct } from '../../../../@types/woocommerce';
import { BuyButton } from '../../styles';
import { Price, PriceDescription } from '../CartItem/styles';
import { PriceFieldWrapper, SectionWrapper, TitleWrapper } from './styles';

type Props = {
  products: { product: NormalizedProduct; quantity: number }[];
};

type PriceFieldProps = {
  label: string;
  price: number;
};

export const PriceField = ({ label, price }: PriceFieldProps) => {
  return (
    <PriceFieldWrapper>
      <div>
        <PriceDescription>{label}</PriceDescription>
      </div>
      <div>
        <Price
          value={price || undefined}
          defaultValue="-"
          displayType="text"
          thousandSeparator="."
          decimalSeparator=","
          prefix="R$ "
          fixedDecimalScale
          decimalScale={2}
        />
      </div>
    </PriceFieldWrapper>
  );
};

const TotalPriceSection = ({ products }: Props) => {
  const { shipping } = useCart();
  const { isMobile } = useWindowDimensions();

  const subTotal = products.reduce(
    (acc, { product, quantity }) => acc + toFloat(product.price) * quantity,
    0,
  );
  const total = subTotal + shipping;

  return (
    <SectionWrapper>
      <TitleWrapper>
        <Title>Resumo</Title>
      </TitleWrapper>
      <div>
        <PriceField label="Sub total" price={subTotal} />
        <PriceField label="Frete" price={shipping} />
        <PriceField label="Total" price={total} />
      </div>
      {!isMobile && (
        <Link to="/faturamento">
          <BuyButton>CONTINUAR</BuyButton>
        </Link>
      )}
    </SectionWrapper>
  );
};

export const TotalPriceSectionModalContent = ({ products }: Props) => {
  const { setModalStyles } = useFilterModal();

  const componentHeight = '320px';
  useEffect(() => {
    setModalStyles(({ overlay, content }) => ({
      overlay,
      content: {
        ...content,
        padding: 0,
        transform: `translateY(-${componentHeight})`,
        height: componentHeight,
      },
    }));
  }, [setModalStyles]);

  return <TotalPriceSection products={products} />;
};

export default TotalPriceSection;
