import styled from 'styled-components';
import { generateSpacing } from 'styles/spaces';

type ImageSelectProps = {
  isSelected?: boolean;
};

export const ProductImageWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100%;
  min-height: 250px;
  margin: ${generateSpacing(5, 0)};

  @media (min-width: 650px) {
    margin: 0;
  }
`;

export const ProductImage = styled.img`
  width: 250px;
  padding: ${generateSpacing(0, 10)};

  @media (min-width: 456px) {
    width: 300px;
  }

  @media (min-width: 512px) {
    width: 340px;
  }
`;

export const ImagesList = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: ${generateSpacing(15)};

  & div:not(:last-child) {
    margin-right: ${generateSpacing(5)};
  }

  @media (min-width: 720px) {
    margin-bottom: 0;
  }
`;

export const ImageSelectWrapper = styled.div<ImageSelectProps>`
  border: 1px solid ${({ isSelected }) => (isSelected ? '#c4c4c4' : 'transparent')};
  border-radius: 4px;
  padding: ${generateSpacing(3)};
  flex: 0 0 90px;
  display: flex;
  align-items: center;
  cursor: pointer;

  @media (min-width: 900px) {
    flex-basis: 110px;
  }
`;

export const ImageSelect = styled.img`
  width: 100%;
`;
