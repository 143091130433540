import { PageWrapper } from 'pages/styles';
import styled from 'styled-components';
import buttonReset from 'styles/buttonReset';
import colors from 'styles/colors';
import { generateSpacing } from 'styles/spaces';

export const ProductsMobileWrapper = styled(PageWrapper)`
  margin: ${generateSpacing(10)} auto ${generateSpacing(20)};
`;

export const SearchInputWrapper = styled.div`
  margin: ${generateSpacing(15, 0, 9)};
`;

export const FilterBar = styled.div`
  margin-bottom: ${generateSpacing(9)};
  height: 50px;
  overflow-x: scroll;
  white-space: nowrap;
`;

export const FiltersWrapper = styled.div`
  margin: 0 auto;
  width: min-content;
`;

export const FilterBarItem = styled(buttonReset)`
  border-radius: 12px;
  padding: ${generateSpacing(4, 6)};
  font-family: 'Montserrat', 'sans-serif';
  font-weight: 500;
  font-size: 0.8rem;
  background-color: ${({ color }: { color?: string }) => color || colors.resultListItembackground};

  &:not(:last-child) {
    margin-right: ${generateSpacing(4)};
  }
`;
