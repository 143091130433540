import { BaseButton } from 'components/Button';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import colors from 'styles/colors';
import { generateSpacing } from 'styles/spaces';
import { RegularText } from 'styles/typographies';

import CompareIconSVG from '../../assets/icons/icon_compare.svg';
import HeartIconFillSVG from '../../assets/icons/icon_heart_fill.svg';
import HeartIconSVG from '../../assets/icons/icon_heart.svg';

interface ProductImageSelectProps {
  isSelected?: boolean;
}

interface ProductPriceWrapperProps {
  center?: boolean;
}

interface VisibilityProps {
  isVisible?: boolean;
}

export const ProductSlideItemContainer = styled.div`
  max-height: 375px;
  padding: ${generateSpacing(8, 6)};
  margin-right: auto;
  border: 1px solid #fff;
  display: flex;
  flex-direction: column;
  border-radius: ${({ theme }) => theme.spaces.defaultBorderRadius};

  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.primary};
  }

  @media (max-width: 900px) {
    width: 90%;
    border: 1px solid ${colors.border};

    margin-bottom: 16px;
  }

  @media (max-width: 445px) {
    max-width: 180px;
    margin-left: auto;
  }

  @media (max-width: 590px) {
    max-width: 200px;
  }

  @media (min-width: 590px) {
    max-width: 220px;
    margin-right: 10px;
  }
`;

export const ProductSlideItemWithDetailsContainer = styled(ProductSlideItemContainer)`
  min-width: 250px;
  max-height: 600px;
  display: flex;
  align-items: center;

  @media (min-width: 320px) and (max-width: 420px) {
    min-width: 280px;
  }

  @media (min-width: 420px) {
    min-width: 370px;
  }

  @media (min-width: 450px) {
    min-width: 400px;
  }
`;

export const ProductImageWrapper = styled(Link)`
  min-height: 180px;
  align-self: center;
  display: flex;
  cursor: pointer;
`;

export const ProductImage = styled.img`
  width: 160px;
  align-self: center;
`;

export const ProductTitleWrapper = styled(Link)`
  color: #000;
  margin-bottom: 6px;
  min-height: 42px;
  display: flex;
`;
export const ProductTitleText = styled.span`
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: 1rem;
`;
export const ProductPriceWrapper = styled.div<ProductPriceWrapperProps>`
  width: 100%;
  margin-bottom: 9px;
  display: flex;

  ${({ center }) => center && 'justify-content: center;'};
`;
export const ProductPriceText = styled.span`
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 1rem;
`;
export const ProductVendorWrapper = styled.div<ProductPriceWrapperProps & VisibilityProps>`
  display: flex;
  ${({ center }) => center && 'justify-content: center;'};
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
`;
export const ProductVendorText = styled(RegularText)`
  font-size: 0.7rem;
`;
export const ProductHeader = styled.div<VisibilityProps>`
  align-self: stretch;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 36px;
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
`;
export const ProductCompare = styled.div``;
export const ProductCompareWrapper = styled(BaseButton)<VisibilityProps>`
  display: flex;
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
`;
export const CompareImage = styled.img.attrs({
  src: CompareIconSVG,
})``;
export const CompareTextWrapper = styled.div`
  padding-left: ${generateSpacing(10)};
`;
export const CompareText = styled.span`
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 0.9rem;

  color: #757575;
`;

export const HeartImage = styled.img.attrs({
  src: HeartIconSVG,
})``;
export const HeartFillImage = styled.img.attrs({
  src: HeartIconFillSVG,
})``;
export const ProductHeartWrapper = styled(BaseButton)<VisibilityProps>`
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
`;
export const ImageSelectorWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
`;
export const ProductImageSelect = styled(BaseButton)<ProductImageSelectProps>`
  border: ${({ isSelected }) => (isSelected ? '1px solid #c4c4c4' : '1px solid transparent')};
  border-radius: 4px;
  padding: ${generateSpacing(2)};

  margin-bottom: ${generateSpacing(8)};
  width: 85px;
  height: 85px;
`;
export const OtherProductImage = styled.img`
  width: 100%;
`;

export const ProductTitleSemiBoldText = styled.span`
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  font-size: 1rem;

  text-align: center;
  color: #757575;
`;
export const ProductPromotionalPriceText = styled.span`
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 1rem;
`;
export const ProductRegularPriceText = styled.span`
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 0.9rem;
  color: #c4c4c4;
  text-decoration-line: line-through;
  margin-right: ${generateSpacing(5)};
`;
