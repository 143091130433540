import React from 'react';
import { useQuery } from 'react-query';
import normalizeProducts from 'utils/normalizeProduct';
import { defineIsLoading, getFirstError } from 'utils/useQueryUtils';
import useQueryParam from 'hooks/useQueryParam';
import useWindowDimensions from 'hooks/useWindowDimensions';
import WooCommerceApi from 'woocommerceApi/base';
import wordPressApi from 'wpApi/base';
import { Category, Product } from '../@types/woocommerce';
import { Post } from '../@types/wordpress';

import Home from './Home';
import ScreenLoading from './Home/components/ScreenLoading';

const ConnectedHome = () => {
  const setQueryParam = useQueryParam();
  const { width } = useWindowDimensions();

  const categoriesQuery = useQuery<Category[]>('categories', () =>
    WooCommerceApi.get('products/categories', { per_page: 100 }).then(response => response.data),
  );
  const bestSellingProductsQuery = useQuery<Product[]>('bestSellingProducts', () =>
    WooCommerceApi.get('products?orderby=popularity&order=desc', { per_page: 15 }).then(
      response => response.data,
    ),
  );

  const mainBannerQuery = useQuery('mainBanner', () =>
    wordPressApi.posts().slug(`banner-principal-site${width < 500 ? '-mobile' : ''}`),
  );

  const featuredBanner1Query = useQuery('featuredBanner1', () =>
    wordPressApi.posts().slug('destaque-1'),
  );

  const featuredBanner2Query = useQuery('featuredBanner2', () =>
    wordPressApi.posts().slug('destaque-2'),
  );

  const partnersQuery = useQuery('partners', () =>
    wordPressApi.getPostsByCategoryName('parceiros'),
  );

  const smallBannerQuery = useQuery('smallBanner', () =>
    wordPressApi.posts().slug(`last-banner${width < 500 ? '-mobile' : ''}`),
  );
  const testimonyQuery = useQuery('testimony', () =>
    wordPressApi.getPostsByCategoryName('Depoimentos'),
  );

  const isLoading = defineIsLoading(
    categoriesQuery,
    bestSellingProductsQuery,
    mainBannerQuery,
    featuredBanner1Query,
    featuredBanner2Query,
    partnersQuery,
    smallBannerQuery,
    testimonyQuery,
  );

  const error = getFirstError(
    categoriesQuery,
    bestSellingProductsQuery,
    mainBannerQuery,
    featuredBanner1Query,
    featuredBanner2Query,
    partnersQuery,
    smallBannerQuery,
    testimonyQuery,
  );

  const categories = categoriesQuery.data;
  const bestSellingProducts = bestSellingProductsQuery.data;
  const mainBanner = (mainBannerQuery.data as unknown) as Post[];
  const featuredBanner1 = (featuredBanner1Query.data as unknown) as Post[];
  const featuredBanner2 = (featuredBanner2Query.data as unknown) as Post[];
  const partners = (partnersQuery.data as unknown) as Post[];
  const smallBanner = (smallBannerQuery.data as unknown) as Post[];
  const testimonies = (testimonyQuery.data as unknown) as Post[];

  if (isLoading) return <ScreenLoading />;
  if (error) return <ScreenLoading />;

  const transfomedCategories = (categories || []).map(category => ({
    ...category,
    onClick: () => {
      setQueryParam([{ category: `${category.id}` }], '/produtos');
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
  }));

  const transformedBestSellingProducts = (bestSellingProducts || []).map(normalizeProducts);
  const transformedMainBanner = {
    src: mainBanner[0].better_featured_image.source_url,
    onClick: () => {},
  };
  const transformedfeaturedBanner1 = {
    ...featuredBanner1[0],
    src: featuredBanner1[0].better_featured_image.source_url,
    onClick: () => {},
  };
  const transformedfeaturedBanner2 = {
    ...featuredBanner2[0],
    src: featuredBanner2[0].better_featured_image.source_url,
    onClick: () => {},
  };

  const transformedSmallBanner = {
    ...smallBanner[0],
    src: smallBanner[0].better_featured_image.source_url,
    onClick: () => {},
  };

  const transformedPartners = (partners || [])
    .filter(partner => partner.better_featured_image)
    .map(partner => ({
      imageUrl: partner.better_featured_image.source_url,
    }));

  const extractDataFromContentPost = (content: string, indexOfResultArray: number) => {
    const regExp = /<p>(.*?)<\/p>/gm;
    const result = content
      .match(regExp)
      ?.map(value => value.replace('<p>', '').replace('</p>', ''));
    if (result?.length) {
      if (indexOfResultArray < result?.length) {
        return result[indexOfResultArray];
      }
    }
    return '';
  };
  const transformedTestimonies = (testimonies || [])
    .filter(testimony => testimony.better_featured_image)
    .map(testimony => ({
      imageUrl: testimony.better_featured_image.source_url,
      title: extractDataFromContentPost(testimony.content.rendered, 0),
      subtitle: extractDataFromContentPost(testimony.content.rendered, 1),
      content: extractDataFromContentPost(testimony.content.rendered, 2),
    }));

  return (
    <Home
      mainBannerData={transformedMainBanner}
      bestSellingProducts={transformedBestSellingProducts}
      categories={transfomedCategories}
      featuredBanner1={transformedfeaturedBanner1}
      featuredBanner2={transformedfeaturedBanner2}
      partners={transformedPartners}
      smallBanner={transformedSmallBanner}
      testimonies={transformedTestimonies}
    />
  );
};

export { ConnectedHome };
