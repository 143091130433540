import { useRef } from 'react';

const useDebounce = <T extends unknown[]>(func: (...args: T) => unknown, wait: number) => {
  const timeout = useRef<number | null>(null);

  return (...args: T) => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
    timeout.current = window.setTimeout(() => {
      timeout.current = null;
      func(...args);
    }, wait);
  };
};

export default useDebounce;
