import FormButton from 'components/FormButton';
import { useField } from 'formik';
import React from 'react';
import { StyledComponentProps, DefaultTheme } from 'styled-components';
import { RadioInputInvisible, RadioLabel, CustomRadio, RadioLabelText } from './styles';

type Props = {
  labelText: string;
} & StyledComponentProps<'input', DefaultTheme, { type: 'radio' }, 'type'>;

type RadioFormikProps = { name: string } & Props;

const Radio = ({ labelText, className, ...props }: Props) => {
  const { checked, id, onClick } = props;

  return (
    <FormButton className={className} onClick={onClick} isChecked={checked}>
      <RadioInputInvisible {...props} />
      <RadioLabel htmlFor={id} isSelected={checked}>
        <CustomRadio isSelected={checked} /> <RadioLabelText>{labelText}</RadioLabelText>
      </RadioLabel>
    </FormButton>
  );
};

export const RadioFormik = (props: RadioFormikProps) => {
  const { name, value } = props;
  const [field, , helpers] = useField(name);

  const onClick = () => {
    helpers.setValue(value);
  };

  return <Radio {...props} {...field} checked={field.value === value} onClick={onClick} />;
};

export default Radio;
