import { User } from '../@types/wordpress';

export interface NormalizedUser {
  id: number;
  email: string;
  username: string;
}

const normalizeUser = ({
  id,
  user_display_name,
  user_email,
}: Pick<User, Exclude<keyof User, 'token'>> & { id: number }) => ({
  id,
  username: user_display_name,
  email: user_email,
});

export default normalizeUser;
