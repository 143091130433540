import { FilterModalContext, ModalEvent } from 'contexts/FilterModalContext';
import { useContext } from 'react';

const useModal = () => {
  const context = useContext(FilterModalContext);

  if (context === null) {
    throw new Error('useModal must be within a ModalProvider');
  }

  const { setIsOpen, setModal, setHasAnimation } = context;

  const openModal = (modalEvent?: ModalEvent, animation = true) => {
    setModal(modalEvent);
    setIsOpen(true);
    setHasAnimation(animation);
  };
  const closeModal = () => {
    setIsOpen(false);
  };

  return { ...context, openModal, closeModal };
};

export default useModal;
