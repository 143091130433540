import styled from 'styled-components';
import { Form } from 'formik';
import TextInput from 'components/Input/TextInput';
import Textarea from 'components/Textarea';
import { RadioFormik } from 'components/Radio';
import { generateSpacing } from 'styles/spaces';
import { Title as TitleCSS } from 'styles/typographies';
import buttonReset from 'styles/buttonReset';
import { PageWrapper } from '../styles';

export const PaymentPageWrapper = styled(PageWrapper)`
  position: relative;
  margin-top: ${generateSpacing(14)};
`;

export const TitleWrapper = styled.div`
  margin-bottom: ${generateSpacing(18)};
`;

export const Title = styled.span`
  ${TitleCSS}
`;

export const PaymentForm = styled(Form)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
`;

export const FieldsWrapper = styled.div`
  width: 100%;

  @media (min-width: 700px) {
    flex: 0 1 58%;
    min-width: 660px;
  }
`;

export const FieldWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`;

export const FieldColumn = styled.div`
  flex: 1 1 48%;
  max-width: 48%;

  @media (max-width: 580px) {
    flex: 1 1 420px;
    max-width: 420px;
  }

  @media (min-width: 720px) {
    flex: 1 1 320px;
    max-width: 320px;
  }
`;

export const Divider = styled.div`
  margin: ${generateSpacing(12, 0)};
  border-bottom: 1px solid #c4c4c4;
`;

export const PaymentLabel = styled.label`
  display: block;
  font-family: 'Montserrat', 'sans-serif';
  font-weight: 500;
  margin: ${generateSpacing(6, 0)};
`;

export const PaymentInput = styled(TextInput)`
  width: 100%;
`;

export const PaymentTextarea = styled(Textarea)`
  width: 100%;
  height: 100px;
  padding: ${generateSpacing(5, 8)};
`;

export const PaymentRadio = styled(RadioFormik)`
  width: 100%;
  max-width: 320px;
`;

export const ErrorMessageWrapper = styled.div`
  color: #ff0000;
  height: 20px;
`;

export const BuyButtonWrapper = styled(buttonReset)`
  display: block;
  min-width: 100%;

  @media (max-width: 320px) {
    min-width: 110px;
  }

  @media (min-width: 320px) and (max-width: 500px) {
    min-width: 180px;
  }

  @media (min-width: 500px) and (max-width: 900px) {
    min-width: 300px;
  }
`;
