import React, { useState } from 'react';
import CategoryItem, { MoreCategories } from './CategoryItem';

import { CategorySelectorContainer, Text } from './styles';
import { ICategoryItem } from './@types';
import { MoreCategoryContainer } from './CategoryItem/styles';

interface CategorySelectorProps {
  categories: Array<ICategoryItem>;
}
const CategorySelector = ({ categories }: CategorySelectorProps) => {
  const [isShowMoreCategories, setIsShowMoreCategories] = useState<boolean>(false);

  const initialCategories = categories
    .slice(0, isShowMoreCategories ? undefined : 5)
    .map(category => <CategoryItem key={category.id} category={category} />);

  return (
    <CategorySelectorContainer onMouseLeave={() => setIsShowMoreCategories(false)}>
      {initialCategories}
      {!isShowMoreCategories && (
        <MoreCategoryContainer
          onMouseEnter={() => setIsShowMoreCategories(true)}
          onMouseLeave={() => setIsShowMoreCategories(false)}
        >
          <Text>MAIS</Text>
        </MoreCategoryContainer>
      )}
    </CategorySelectorContainer>
  );
};

export default CategorySelector;
