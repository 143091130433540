import styled from 'styled-components';

export default styled.button`
  display: inline-block;
  border: none;
  margin: 0;
  text-decoration: none;
  font-family: inherit;
  font-size: 14px;
  cursor: pointer;
  text-align: center;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: transparent;

  &:active {
    transform: scale(0.99);
  }
`;
