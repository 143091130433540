import React, { useState } from 'react';
import ReactModal from 'react-modal';
import ModalProvider from 'contexts/FilterModalContext';
import WooCommerceApi from 'woocommerceApi/base';
import { ReactComponent as TrashIcon } from 'assets/icons/icon_trash.svg';
import { SectionTitle } from 'styles/typographies';
import useCompare from 'hooks/useCompare';
import useWindowDimensions from 'hooks/useWindowDimensions';
import normalizeProduct from 'utils/normalizeProduct';
import { ReactComponent as CloseIcon } from 'assets/icons/icon_close.svg';
import { NormalizedProduct } from '../../@types/woocommerce';
import {
  CompareBoxWrapper,
  ProductComparedWrapper,
  CompareWrapper,
  ProductTitleWrapper,
  ProductTitle,
  CloseIconWrapper,
  CloseTextWrapper,
  CloseText,
  TrashIconWrapper,
} from './styles';
import ProductCard from './ProductCard';
import ProductSearch from './ProductSearch';

const getBaseStyles = (isMobile: boolean) => ({
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    zIndex: 1001,
  },
  content: {
    overflow: isMobile ? 'auto' : 'initial',
    left: 0,
    right: 0,
    margin: '0 auto',
    top: '50%',
    width: '100%',
    maxWidth: '900px',
    height: isMobile ? '100%' : '500px',
    padding: isMobile ? '12px' : '25px',
    borderRadius: isMobile ? '' : '12px',
    transform: 'translateY(-50%)',
  },
});

const CompareBox = () => {
  const { product, closeModal } = useCompare();
  const [comparedProduct, setComparedProduct] = useState<NormalizedProduct>();
  const [isSelected, setIsSelected] = useState(false);
  const { width } = useWindowDimensions();

  const handleClickOnProduct = (productId: number) => {
    setIsSelected(true);
    WooCommerceApi.get(`products/${productId}`).then(response => {
      setComparedProduct(normalizeProduct(response.data));
    });
  };

  const handleClickOnTrash = () => {
    setIsSelected(false);
    setComparedProduct(undefined);
  };

  return (
    <CompareBoxWrapper>
      {width < 700 && (
        <CloseIconWrapper onClick={closeModal}>
          <CloseIcon />
        </CloseIconWrapper>
      )}
      <SectionTitle>Comparar produtos</SectionTitle>
      <CompareWrapper>
        <ProductComparedWrapper>
          <ProductTitleWrapper>
            <ProductTitle>Produto 1</ProductTitle>
          </ProductTitleWrapper>
          <ProductCard product={product} />
        </ProductComparedWrapper>
        <ProductComparedWrapper>
          <ProductTitleWrapper>
            <ProductTitle>Produto 2</ProductTitle>
            {comparedProduct && (
              <TrashIconWrapper onClick={handleClickOnTrash}>
                <TrashIcon />
              </TrashIconWrapper>
            )}
          </ProductTitleWrapper>
          {isSelected ? (
            <ProductCard product={comparedProduct} />
          ) : (
            <ModalProvider>
              <ProductSearch onClickItem={handleClickOnProduct} />
            </ModalProvider>
          )}
        </ProductComparedWrapper>
      </CompareWrapper>
    </CompareBoxWrapper>
  );
};

const CompareModal = () => {
  const { isOpen, closeModal } = useCompare();
  const { width } = useWindowDimensions();

  return (
    <ReactModal isOpen={isOpen} onRequestClose={closeModal} style={getBaseStyles(width < 700)}>
      {width > 700 && (
        <CloseTextWrapper onClick={closeModal}>
          <CloseText>fechar</CloseText>
        </CloseTextWrapper>
      )}
      <CompareBox />
    </ReactModal>
  );
};

export default CompareModal;
