import styled from 'styled-components';
import { generateSpacing } from 'styles/spaces';

export const CouponInfoWrapper = styled.div`
  margin-top: ${generateSpacing(10)};
  padding: ${generateSpacing(3, 5)};
  border-radius: 8px;
  background-color: #f8f8f8;
`;

export const CouponInfoText = styled.span`
  font-family: 'Montserrat', 'sans-serif';
  font-weight: 500;
`;

export const MarkIconWrapper = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-right: ${generateSpacing(5)};
  border-radius: 50%;
  width: 21px;
  height: 21px;
  font-size: 13px;
  background-color: #13ab87;
`;
