/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-tag-spacing */
import TextInput from 'components/Input/TextInput';
import React from 'react';
import { MinusIcon, QuantityButton, QuantityButtonsWrapper, PlusIcon } from './styles';

type Props = {
  minQuantity: number;
  quantity: number | '';
  onChange: (quantity: number | '') => void;
  onChangeControls: (quantityToIncrement: number) => void;
};

type NumberInput = { type: 'number' };

const inputStyles: React.CSSProperties = { textAlign: 'center' };

const QuantityButtons = ({ minQuantity, quantity, onChange, onChangeControls }: Props) => {
  const increment = () => onChangeControls(1);
  const decrement = () => onChangeControls(quantity ? -1 : minQuantity);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.includes('-')) {
      return;
    }

    const value = parseInt(event.target.value, 10);
    onChange(Number.isNaN(value) ? '' : value);
  };

  return (
    <QuantityButtonsWrapper>
      <QuantityButton disabled={quantity <= minQuantity} onClick={decrement}>
        <MinusIcon />
      </QuantityButton>
      <TextInput<NumberInput>
        type="number"
        value={quantity}
        onChange={handleChange}
        inputStyles={inputStyles}
      />
      <QuantityButton onClick={increment}>
        <PlusIcon />
      </QuantityButton>
    </QuantityButtonsWrapper>
  );
};

QuantityButtons.defaultProps = {
  minQuantity: 0,
};

export default QuantityButtons;
