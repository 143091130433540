import React, { useEffect } from 'react';
import ReactModal from 'react-modal';
import UserMenu from 'components/UserMenu';
import useModal from 'hooks/useFilterModal';
import useUser from 'hooks/useUser';
import { SectionTitle } from 'styles/typographies';
import { UserEmail, UserInfo, Wrapper } from './styles';

const UserModalBox = () => {
  const { setModalStyles } = useModal();
  const { user } = useUser();

  const componentHeight = '210px';

  useEffect(() => {
    setModalStyles(prevState => ({
      overlay: { ...prevState.overlay, zIndex: 1001 },
      content: {
        ...prevState.content,
        transform: `translateY(-${componentHeight})`,
        height: componentHeight,
      },
    }));
  }, [setModalStyles]);

  return (
    <Wrapper>
      <UserInfo>
        <div>
          <SectionTitle>{user?.username}</SectionTitle>
        </div>
        <div>
          <UserEmail>{user?.email}</UserEmail>
        </div>
      </UserInfo>
      <UserMenu />
    </Wrapper>
  );
};

const UserModal = () => {
  const { isOpen, closeModal, modalStyles } = useModal();

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={() => closeModal()}
      style={modalStyles}
      closeTimeoutMS={350}
    >
      <UserModalBox />
    </ReactModal>
  );
};

export default UserModal;
