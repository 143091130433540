import React, { useState } from 'react';
import NumberFormat from 'react-number-format';
import cep, { CEP } from 'cep-promise';
import NeutralButton from 'components/NeutralButton';
import useCart from 'hooks/useCart';
import { ShippingHeader, ShippingText, ShippingTextInput, NotCEPButton } from './styles';

const Shipping = () => {
  const { zipcode, setZipcode } = useCart();
  const [zipcodeText, setZipcodeText] = useState(zipcode?.cep || '');

  const handleShippingInputOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setZipcodeText(event.target.value);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    cep(zipcodeText).then(({ cep: newCep, street, city, state, neighborhood }: CEP) => {
      const formattedCep = `${newCep.slice(0, 5)}-${newCep.slice(5)}`;
      setZipcode({ cep: formattedCep, street, city, state, neighborhood });
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <ShippingHeader>
        <ShippingText>Calcular frete</ShippingText>
      </ShippingHeader>
      <NumberFormat
        value={zipcodeText}
        onChange={handleShippingInputOnChange}
        customInput={ShippingTextInput}
        placeholder="00000-000"
        format="#####-###"
        mask="_"
      />
      <NeutralButton>Ok</NeutralButton>
      <NotCEPButton href="https://buscacepinter.correios.com.br/app/endereco/" target="_blank">
        Não sei o CEP
      </NotCEPButton>
    </form>
  );
};

export default Shipping;
