import { useContext } from 'react';
import { UserContext } from 'contexts/UserContext';
import wp from 'wpApi/base';

const useUser = () => {
  const context = useContext(UserContext);

  if (context === null) {
    throw new Error('useUser must be within a UserProvider');
  }

  const { setUser } = context;

  const logout = () => {
    localStorage.clear();
    wp.setHeaders({ Authorization: '' });
    setUser(null);
  };

  return { ...context, logout };
};

export default useUser;
