import styled from 'styled-components';
import { generateSpacing } from 'styles/spaces';
import colors from 'styles/colors';
import { ResponsiveActionButton } from '../CartButton/styles';

export const BuyButton = styled(ResponsiveActionButton)`
  margin-top: ${generateSpacing(8)};
  background-color: ${colors.primary};
  color: #000;

  @media (max-width: 541px) {
    width: 195px;
  }
`;
