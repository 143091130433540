import React from 'react';
import { useQuery } from 'react-query';
import WooCommerceApi from 'woocommerceApi/base';
import normalizeProduct from 'utils/normalizeProduct';
import RecommendedProducts from 'pages/Home/components/RecommendedProducts';
import { Spinner } from 'pages/Home/components/ScreenLoading/styles';
import { Product } from '../../../../@types/woocommerce';
import { SpinnerCentralized } from '../Ratings/styles';

const RecommendedCarousel = () => {
  const { data, isLoading, error } = useQuery<Product[]>('bestSellingProducts', () =>
    WooCommerceApi.get('products?orderby=popularity&order=desc', { per_page: 15 }).then(
      response => response.data,
    ),
  );

  if (isLoading || error || !data) {
    return (
      <SpinnerCentralized>
        <Spinner />
      </SpinnerCentralized>
    );
  }

  return <RecommendedProducts products={data.map(normalizeProduct)} />;
};

export default RecommendedCarousel;
