import styled from 'styled-components';

const Textarea = styled.textarea`
  background-color: #f8f8f8;
  border: none;
  border-radius: 6px;
  resize: none;
`;

export default Textarea;
