import styled from 'styled-components';
import colors from 'styles/colors';
import { generateSpacing } from 'styles/spaces';

export const RadioInputInvisible = styled.input.attrs({ type: 'radio' })`
  display: none;
`;

export const RadioLabel = styled.label`
  display: inline-block;
  font-family: 'Montserrat', 'sans-serif';
  font-weight: ${({ isSelected }: { isSelected?: boolean }) => (isSelected ? '700' : '500')};
  line-height: 0;
`;

export const RadioLabelText = styled.span`
  vertical-align: middle;
`;

export const CustomRadio = styled.div`
  position: relative;
  display: inline-flex;
  vertical-align: middle;
  justify-content: center;
  align-items: center;
  border: 1px solid
    ${({ isSelected }: { isSelected?: boolean }) =>
      isSelected ? colors.focusedBorder : colors.borderButton};
  border-radius: 50%;
  width: 18px;
  height: 18px;
  margin-right: ${generateSpacing(5)};

  &::after {
    content: '';
    position: absolute;
    display: ${({ isSelected }: { isSelected?: boolean }) =>
      isSelected ? 'inline-block' : 'none'};
    border-radius: 50%;
    width: 8px;
    height: 8px;
    background-color: #000;
  }
`;
