import styled from 'styled-components';
import { generateSpacing } from 'styles/spaces';

import TruckIcon from 'assets/icons/icon_truck.svg';
import ReturnIcon from 'assets/icons/icon_return.svg';
import ShieldIcon from 'assets/icons/icon_shield.svg';
import SuportIcon from 'assets/icons/icon_suport.svg';

export type IconProps = 'truck' | 'return' | 'shield' | 'suport';

export const BadgeItemContainer = styled.div`
  display: flex;
  flex-direction: column;

  max-width: 300px;

  align-items: center;

  @media (max-width: 900px) {
    width: 100%;
  }
`;
export const TruckImage = styled.img.attrs({
  src: TruckIcon,
})``;
export const ReturnImage = styled.img.attrs({
  src: ReturnIcon,
})``;
export const ShieldImage = styled.img.attrs({
  src: ShieldIcon,
})``;
export const SuportImage = styled.img.attrs({
  src: SuportIcon,
})``;
export const ContentWrapper = styled.div`
  background: #f8f8f8;
  display: flex;
  flex-direction: column;

  align-items: center;

  padding: ${generateSpacing(12, 8)};
  border-radius: ${({ theme }) => theme.spaces.defaultBorderRadius};

  @media (max-width: 900px) {
    width: 100%;
  }
`;
export const ImageWrapper = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 40px;

  display: flex;
  align-items: center;
  justify-content: center;
  background: #212121;

  transform: translateY(10px);
`;
export const ContentTitleWrapper = styled.div``;
export const ContentTitle = styled.span`
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 1rem;
  text-align: center;
`;
export const ContentSubTitleWrapper = styled.div`
  margin-top: 8px;
  display: flex;
`;
export const ContentSubTitle = styled.span`
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 0.9rem;
  color: #757575;
  text-align: center;
`;
