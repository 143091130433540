import React from 'react';

import {
  FooterContainer,
  ContentWrapper,
  CompanyInfo,
  PhoneSalesWrapper,
  PhoneImage,
  PhoneSalesTitle,
  PhoneSalesText,
} from './styles';

const Footer = () => {
  return (
    <FooterContainer>
      <ContentWrapper>
        <CompanyInfo>
          © 2021 Associação Brasileira das Locadoras de Automóveis. Todos os Direitos Reservados.
        </CompanyInfo>

        <PhoneSalesWrapper>
          <PhoneImage />
          <PhoneSalesTitle>Televendas</PhoneSalesTitle>
          <PhoneSalesText>0800-123456</PhoneSalesText>
        </PhoneSalesWrapper>
      </ContentWrapper>
    </FooterContainer>
  );
};

export default Footer;
