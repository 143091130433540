import WpApi from 'wpapi';

const wp = new WpApi({ endpoint: `${process.env.REACT_APP_WOOCOMERCE_URL}/wp-json` as string });

export const getPostsByCategoryName = (categoryName: string) =>
  wp
    .categories()
    .slug(categoryName)
    .then(categories => wp.posts().categories(categories[0].id));
wp.getPostsByCategoryName = getPostsByCategoryName;

wp.login = wp.registerRoute('jwt-auth/v1', '/token');
wp.validate = wp.registerRoute('jwt-auth/v1/token', '/validate');

wp.sendEmail = wp.registerRoute('contact-form-7/v1/contact-forms/4971', '/feedback');

export default wp;
