import { useField } from 'formik';
import React from 'react';
import { StyledComponentProps, DefaultTheme } from 'styled-components';
import { CustomCheckbox, CustomCheckboxWrapper, InvisibleCheckbox } from './styles';

type Props = StyledComponentProps<
  'input',
  DefaultTheme,
  {
    type: 'checkbox';
  },
  'type'
>;

const Checkbox = (props: Props) => {
  const { checked, onClick } = props;

  return (
    <CustomCheckboxWrapper checked={checked}>
      <InvisibleCheckbox {...props} />
      <CustomCheckbox checked={checked} onClick={onClick} />
    </CustomCheckboxWrapper>
  );
};

export const CheckboxFormik = (props: Props) => {
  const { name = '' } = props;

  const [field, , helpers] = useField(name);

  const handleClickOnCheckbox = () => {
    helpers.setValue(!field.value);
    helpers.setTouched(true);
  };

  return <Checkbox {...field} checked={field.value} onClick={handleClickOnCheckbox} />;
};

export default Checkbox;
