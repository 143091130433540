export default (value: string) => {
  let firstHalf;
  let secondHalf;
  const ddd = value.substring(0, 2);

  if (value.length < 11) {
    firstHalf = value.substring(2, 6);
    secondHalf = value.substring(6);
  } else {
    firstHalf = value.substring(2, 7);
    secondHalf = value.substring(7, 11);
  }

  return `(${ddd}) ${firstHalf} ${secondHalf}`;
};
