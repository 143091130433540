import React, { useEffect } from 'react';
import { StyledComponentProps, DefaultTheme } from 'styled-components';
import { useLocation } from 'react-router-dom';
import useQueryParam from 'hooks/useQueryParam';
import useModal from 'hooks/useFilterModal';
import { RadioInputInvisible } from 'components/Radio/styles';
import { ItemName } from 'pages/Products/styles';
import { Category } from '../../../../../@types/woocommerce';
import { RadioWrapper, CategoryRadioLabel, CategoryCustomRadio } from './styles';
import { FilterNameWrapper, FilterName } from '../styles';

type Props = {
  categories: Category[];
};

const CategoryRadio = ({
  categoryName,
  ...props
}: StyledComponentProps<'input', DefaultTheme, { type: 'radio' }, 'type'> & {
  categoryName: string;
}) => {
  const { checked, id, onClick } = props;

  return (
    <RadioWrapper onClick={onClick} isChecked={checked}>
      <CategoryRadioLabel htmlFor={id} isSelected={checked}>
        <RadioInputInvisible {...props} />
        <ItemName isSelected={!!checked}>{categoryName.toLowerCase()}</ItemName>
        {checked && <CategoryCustomRadio isSelected />}
      </CategoryRadioLabel>
    </RadioWrapper>
  );
};

const CategoriesFilterModal = ({ categories }: Props) => {
  const location = useLocation();
  const setQueryParam = useQueryParam();
  const { setModalStyles, closeModal } = useModal();
  const query = new URLSearchParams(location.search);
  const currentCategory = parseInt(query.get('category') || '0', 10);

  const componentHeight = '400px';

  useEffect(() => {
    setModalStyles(({ overlay, content }) => ({
      overlay,
      content: {
        ...content,
        transform: `translateY(-${componentHeight})`,
        height: componentHeight,
      },
    }));
  }, [setModalStyles]);

  const handleClick = (categoryId: string | number = '') => {
    setQueryParam([{ category: `${categoryId}` }], '/produtos');
    closeModal();
  };

  const mappedCategories = categories.map(({ id, name }) => {
    return (
      <CategoryRadio
        key={id}
        id={`${id}`}
        name={name}
        categoryName={name}
        checked={currentCategory === id}
        onClick={() => handleClick(id)}
        onChange={() => handleClick(id)}
      />
    );
  });

  return (
    <>
      <FilterNameWrapper>
        <FilterName>Categorias</FilterName>
      </FilterNameWrapper>
      <CategoryRadio
        key={0}
        id="0"
        name="AllCategories"
        categoryName="Todas as categorias"
        checked={currentCategory === 0}
        onChange={() => handleClick()}
        onClick={() => handleClick()}
      />
      {mappedCategories}
    </>
  );
};

export default CategoriesFilterModal;
