import styled from 'styled-components';
import colors from 'styles/colors';
import { generateSpacing } from 'styles/spaces';
import HeartIconSVG from 'assets/icons/icon_heart.svg';
import HeartIconFillSVG from 'assets/icons/icon_heart_fill.svg';
import buttonReset from 'styles/buttonReset';

export const ProductInfoHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: ${generateSpacing(10)};
`;

export const ReviewsInfo = styled.div`
  display: flex;
  align-items: center;
`;

export const StarsWrapper = styled.div`
  font-size: 1.7rem;
  line-height: 0;

  & div:not(:last-child) {
    margin-right: ${generateSpacing(2)};
  }
`;

export const ReviewsCounter = styled.div`
  margin-left: ${generateSpacing(5)};
  color: ${colors.emptyText};
  font-size: 1.3rem;

  @media (min-width: 660px) {
    font-size: 1rem;
  }
`;

export const HeaderActions = styled.div`
  min-width: 180px;
  display: flex;
  justify-content: space-between;
`;

export const WishButton = styled(buttonReset)``;

export const HeartIcon = styled.img.attrs({ src: HeartIconSVG })`
  width: 25px;
`;

export const HeartIconFill = styled.img.attrs({ src: HeartIconFillSVG })`
  width: 25px;
`;
