import { NormalizedProduct, Product } from '../@types/woocommerce';

export const toFloat = (value: string) => parseFloat(value.replace(',', '.'));

export default (product: Product): NormalizedProduct => {
  let { price } = product;

  if (product.sale_price === '') {
    price = product.regular_price;
  }

  return {
    ...product,
    price,
    imageUrl: product.images[0].src,
    name: product.name,
    vendor: product.store.first_name + product.store.last_name,
    averageRating: parseInt(product.average_rating, 10),
    ratingCount: product.rating_count,
    regularPrice: product.regular_price,
    metaData: product.meta_data,
  };
};
