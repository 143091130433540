import React from 'react';
import { Image } from '../../../../@types/woocommerce';
import {
  ProductImageWrapper,
  ProductImage,
  ImagesList,
  ImageSelectWrapper,
  ImageSelect,
} from './styles';

type Props = {
  initialImage: string;
  selectedImage: string | undefined;
  onChange: (src: string) => void;
  images: Image[];
};

const ImageSelector = ({ initialImage, selectedImage, onChange, images }: Props) => {
  const currentImage = selectedImage || initialImage;

  return (
    <>
      <ProductImageWrapper>
        <ProductImage src={currentImage} />
      </ProductImageWrapper>
      <ImagesList>
        {images.map(({ id, src }) => (
          <ImageSelectWrapper
            isSelected={currentImage === src}
            key={id}
            onClick={() => onChange(src)}
          >
            <ImageSelect src={src} />
          </ImageSelectWrapper>
        ))}
      </ImagesList>
    </>
  );
};

export default ImageSelector;
