import styled from 'styled-components';
import { generateSpacing } from 'styles/spaces';

export const CategorySelectorContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 90%;

  flex-wrap: wrap;

  padding: ${generateSpacing(5, 0)};

  transform: translateY(-30px);

  background: #fff;
  box-shadow: 0px 15px 20px -20px #d2dde6;

  border-radius: ${({ theme }) => theme.spaces.defaultBorderRadius};
`;

export const Text = styled.div`
  text-align: center;
`;
