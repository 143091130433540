import styled from 'styled-components';
import { ReactComponent as SpinnerSVG } from '../../../../assets/icons/icon_spinner.svg';

export const ScreenLoadingContainer = styled.div`
  width: 100vw;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const Spinner = styled(SpinnerSVG)`
  font-size: 65px;

  animation-name: spin;
  animation-duration: 800ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;
