import React from 'react';
import { ScreenLoadingContainer, Spinner } from './styles';

const ScreenLoading = () => {
  return (
    <ScreenLoadingContainer>
      <Spinner />
      <span>Carregando...</span>
    </ScreenLoadingContainer>
  );
};

export default ScreenLoading;
