import React, { useState } from 'react';
import { Field, Form, Formik, useFormikContext } from 'formik';
import NumberFormat from 'react-number-format';
import wp from 'wpApi/base';
import formatPhoneNumber from 'utils/formatPhoneNumber';
import { BoldText } from 'styles/typographies';
import { ReactComponent as MarkIcon } from 'assets/icons/check_mark_white.svg';
import BottomTabNavigation from 'components/BottomTabNavigation';
import NestedHeader from 'components/Header/NestedHeader';
import ErrorMessage from 'components/ErrorMessage';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { SpinnerCentralized } from 'pages/ProductPage/components/Ratings/styles';
import { Spinner } from 'pages/Home/components/ScreenLoading/styles';
import { ReactComponent as IconPhone } from '../../assets/icons/icon_phone.svg';
import { Title, TitleWrapper } from '../styles';
import schema, { IForm } from './form';
import {
  ContactInput,
  ContactLabel,
  ContactTextarea,
  ContactWrapper,
  MoreContactsWrapper,
  SubmitButton,
  MoreContactsText,
  FieldWrapper,
  MarkIconWrapper,
  SuccessfulMessageWrapper,
  SuccessfulMessageText,
} from './styles';

const initialValues = {
  'your-name': '',
  'your-email': '',
  'your-subject': '',
  'your-phone': '',
  'your-message': '',
};

const SucessfulMessage = () => (
  <SuccessfulMessageWrapper>
    <MarkIconWrapper>
      <MarkIcon />
    </MarkIconWrapper>
    <SuccessfulMessageText>Mensagem enviada! Em breve retornaremos!</SuccessfulMessageText>
  </SuccessfulMessageWrapper>
);

const ContactButton = () => {
  const { isSubmitting } = useFormikContext();

  if (isSubmitting) {
    return (
      <SpinnerCentralized>
        <Spinner />
      </SpinnerCentralized>
    );
  }

  return <SubmitButton type="submit">Enviar Mensagem</SubmitButton>;
};

const ContactForm = () => {
  const [isSuccessful, setIsSuccessful] = useState(false);

  const handleSubmit = async (values: IForm) => {
    const formData = new FormData();

    Object.entries(values).forEach(([key, value]) => {
      formData.append(key, value);
    });

    try {
      await wp.sendEmail().create(formData);
      setIsSuccessful(true);
    } catch (error) {
      console.warn(error);
    }
  };

  return (
    <Formik initialValues={initialValues} validationSchema={schema} onSubmit={handleSubmit}>
      <Form>
        <FieldWrapper>
          <ContactLabel>Nome completo</ContactLabel>
          <Field as={ContactInput} name="your-name" placeholder="Digite seu nome aqui..." />
          <ErrorMessage name="your-name" />
        </FieldWrapper>
        <FieldWrapper>
          <ContactLabel>E-mail</ContactLabel>
          <Field as={ContactInput} name="your-email" placeholder="Digite seu email aqui..." />
          <ErrorMessage name="your-email" />
        </FieldWrapper>
        <FieldWrapper>
          <ContactLabel>Telefone</ContactLabel>
          <Field
            name="your-phone"
            placeholder="(99) 99999 9999"
            format={formatPhoneNumber}
            as={NumberFormat}
            customInput={ContactInput}
          />
          <ErrorMessage name="your-phone" />
        </FieldWrapper>
        <FieldWrapper>
          <ContactLabel>Assunto</ContactLabel>
          <Field as={ContactInput} name="your-subject" placeholder="Digite seu o assunto..." />
          <ErrorMessage name="your-subject" />
        </FieldWrapper>
        <FieldWrapper>
          <ContactLabel>Mensagem</ContactLabel>
          <Field
            as={ContactTextarea}
            name="your-message"
            placeholder="Digite sua mensagem aqui..."
          />
          <ErrorMessage name="your-message" />
        </FieldWrapper>
        {isSuccessful ? <SucessfulMessage /> : <ContactButton />}
      </Form>
    </Formik>
  );
};

const Contact = () => {
  const { isMobile } = useWindowDimensions();

  return (
    <>
      {isMobile && <NestedHeader name="Contato" />}
      <ContactWrapper>
        <TitleWrapper>{!isMobile && <Title>Contato</Title>}</TitleWrapper>
        <ContactForm />
        <MoreContactsWrapper>
          <IconPhone />
          <MoreContactsText>
            Ou entre em contato com o nosso televendas pelo número <BoldText>0800-12345</BoldText>
          </MoreContactsText>
        </MoreContactsWrapper>
      </ContactWrapper>
    </>
  );
};

export default Contact;
