import { useHistory, useLocation } from 'react-router-dom';

interface genericObject {
  [k: string]: string;
}

export default () => {
  const history = useHistory();
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  return (queries: genericObject[], pathname = location.pathname) => {
    let hasChanged = false;

    queries.forEach(currentQuery => {
      const key = Object.keys(currentQuery)[0];
      const value = Object.values(currentQuery)[0];
      const actualValue = query.get(key);

      if (actualValue !== value) {
        query.set(key, value);
        hasChanged = true;
      }
    });
    if (hasChanged) {
      history.push({ pathname, search: `?${query.toString()}` });
    }
  };
};
