import React from 'react';
import { useHistory } from 'react-router-dom';
import useCart from 'hooks/useCart';
import { BuyButton } from './styles';

type Props = {
  productId: number;
  quantity: number;
};

const BuyNowButton = ({ productId, quantity }: Props) => {
  const history = useHistory();
  const { upsertItem } = useCart();

  const handleBuyButtonOnClick = () => {
    upsertItem({ productId, quantity: quantity || 1 });
    history.push('/faturamento');
  };

  return (
    <div>
      <BuyButton onClick={handleBuyButtonOnClick}>COMPRAR AGORA</BuyButton>
    </div>
  );
};

export default BuyNowButton;
