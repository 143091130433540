import styled from 'styled-components';
import colors from 'styles/colors';
import { generateSpacing } from 'styles/spaces';

export const SelectorWrapper = styled.div`
  margin: 0 auto ${generateSpacing(40)};
  width: 100%;
  min-height: 200px;

  @media (min-width: 400px) {
    width: 90%;
  }
`;
export const SelectorHeader = styled.div`
  text-align: center;
  white-space: nowrap;
  overflow-x: auto;
  margin: 0 auto ${generateSpacing(8)} auto;
  padding: ${generateSpacing(5, 0)};

  @media (min-width: 900px) {
    box-shadow: 0px 10px 10px -5px #efefef;
    margin-bottom: ${generateSpacing(20)};
  }
`;

export const SectionName = styled.div`
  display: inline-block;
  margin-right: ${generateSpacing(15)};
  padding: ${generateSpacing(6)};
  border-radius: 8px;
  cursor: pointer;

  font-family: 'Montserrat', sans-serif;
  font-size: 0.87rem;
  font-weight: ${({ isSelected }: { isSelected: boolean }) => (isSelected ? '700' : '500')};
  text-transform: uppercase;

  background-color: ${({ isSelected }: { isSelected: boolean }) =>
    isSelected ? 'rgba(196, 196, 196, 0.2)' : 'transparent'};

  @media (max-width: 900px) {
    padding: ${generateSpacing(3, 11)};
    margin-right: ${generateSpacing(5)};
    font-weight: 500;
    font-size: 0.8rem;
    border-radius: 25px;
    background-color: ${({ isSelected }: { isSelected: boolean }) =>
      isSelected ? colors.primary : 'transparent'};
  }
`;
