import React from 'react';
import ReactModal from 'react-modal';
import useFilterModal from 'hooks/useFilterModal';
import { NormalizedProduct } from '../../../../@types/woocommerce';
import { ResumeSectionModal } from '../ResumeSection';

ReactModal.setAppElement('#root');

type Props = {
  products: { product: NormalizedProduct; quantity: number }[];
};

const ResumeModal = ({ products, ...props }: Partial<ReactModal.Props> & Props) => {
  const { isOpen, closeModal, modalStyles } = useFilterModal();

  return (
    <ReactModal
      {...props}
      isOpen={isOpen}
      onRequestClose={() => closeModal()}
      style={modalStyles}
      closeTimeoutMS={350}
    >
      <ResumeSectionModal products={products} />
    </ReactModal>
  );
};

export default ResumeModal;
