import React from 'react';
import useCart from 'hooks/useCart';
import { toFloat } from 'utils/normalizeProduct';
import QuantityButtons from 'components/QuantityButtons';
import { ReactComponent as TrashIcon } from 'assets/icons/icon_trash.svg';
import { NormalizedProduct } from '../../../../@types/woocommerce';
import {
  CartItemWrapper,
  CartItemDesktopWrapper,
  StrongText,
  DetailText,
  ProductImage,
  ProductInfo,
  TextInfo,
  PriceDescription,
  Price,
  TrashIconWrapper,
  ProductImageWrapper,
  PriceSection,
  CartItemQuantity,
} from './styles';

type Props = {
  product: NormalizedProduct;
  quantity: number;
};

type ResponsiveCartItem = {
  deleteItem: () => void;
  handleQuantityOnChange: (newQuantity: number | '') => void;
  handleQuantityControlsOnChange: (quantityToIncrement: number) => void;
} & Props;

type RenderProp = {
  render: (props: Props & ResponsiveCartItem) => JSX.Element;
};

export const MobileCartItem = ({
  product,
  quantity,
  deleteItem,
  handleQuantityOnChange,
  handleQuantityControlsOnChange,
}: ResponsiveCartItem) => {
  const { id, imageUrl, name, sku, vendor, price } = product;
  const finalPrice = parseFloat(price) * quantity;

  return (
    <CartItemWrapper key={id}>
      <TrashIconWrapper onClick={deleteItem}>
        <TrashIcon />
      </TrashIconWrapper>
      <ProductInfo>
        <ProductImageWrapper>
          <ProductImage src={imageUrl} />
        </ProductImageWrapper>
        <TextInfo>
          <div>
            <StrongText>{name}</StrongText>
          </div>
          <div>
            <DetailText>Vendido por: {vendor}</DetailText>
          </div>
          <div>
            <DetailText>SKU: {sku}</DetailText>
          </div>
        </TextInfo>
      </ProductInfo>
      <CartItemQuantity>
        <QuantityButtons
          minQuantity={1}
          quantity={quantity}
          onChange={handleQuantityOnChange}
          onChangeControls={handleQuantityControlsOnChange}
        />
      </CartItemQuantity>
      <PriceSection>
        <div>
          <div>
            <PriceDescription>Valor unidade</PriceDescription>
          </div>
          <div>
            <Price
              value={toFloat(price)}
              defaultValue="0"
              displayType="text"
              thousandSeparator="."
              decimalSeparator=","
              prefix="R$ "
              fixedDecimalScale
              decimalScale={2}
            />
          </div>
        </div>
        <div>
          <div>
            <PriceDescription>Total</PriceDescription>
          </div>
          <div>
            <Price
              value={finalPrice}
              displayType="text"
              thousandSeparator="."
              decimalSeparator=","
              prefix="R$ "
              fixedDecimalScale
              decimalScale={2}
            />
          </div>
        </div>
      </PriceSection>
    </CartItemWrapper>
  );
};

export const DesktopCartItem = ({
  product,
  quantity,
  deleteItem,
  handleQuantityOnChange,
  handleQuantityControlsOnChange,
}: ResponsiveCartItem) => {
  const { id, imageUrl, name, sku, vendor, price } = product;
  const finalPrice = parseFloat(price) * quantity;

  return (
    <CartItemDesktopWrapper key={id}>
      <ProductInfo>
        <ProductImageWrapper>
          <ProductImage src={imageUrl} />
        </ProductImageWrapper>
        <TextInfo>
          <div>
            <StrongText>{name}</StrongText>
          </div>
          <div>
            <DetailText>Vendido por: {vendor}</DetailText>
          </div>
          <div>
            <DetailText>SKU: {sku}</DetailText>
          </div>
        </TextInfo>
      </ProductInfo>
      <PriceSection>
        <div>
          <PriceDescription>Valor unidade</PriceDescription>
        </div>
        <div>
          <Price
            value={toFloat(price)}
            defaultValue="0"
            displayType="text"
            thousandSeparator="."
            decimalSeparator=","
            prefix="R$ "
            fixedDecimalScale
            decimalScale={2}
          />
        </div>
      </PriceSection>
      <QuantityButtons
        minQuantity={1}
        quantity={quantity}
        onChange={handleQuantityOnChange}
        onChangeControls={handleQuantityControlsOnChange}
      />
      <PriceSection>
        <div>
          <PriceDescription>Total</PriceDescription>
        </div>
        <div>
          <Price
            value={finalPrice}
            displayType="text"
            thousandSeparator="."
            decimalSeparator=","
            prefix="R$ "
            fixedDecimalScale
            decimalScale={2}
          />
        </div>
      </PriceSection>
      <TrashIconWrapper onClick={deleteItem}>
        <TrashIcon />
      </TrashIconWrapper>
    </CartItemDesktopWrapper>
  );
};

const CartItem = ({ product, quantity, render }: Props & RenderProp) => {
  const { removeItem, updateItem } = useCart();
  const { id } = product;
  const currentItem = { productId: id, quantity };

  const deleteItem = () => removeItem(currentItem);

  const handleQuantityOnChange = (newQuantity: number | '') =>
    updateItem(currentItem, { quantity: newQuantity || 1 });

  const handleQuantityControlsOnChange = (quantityToIncrement: number) =>
    updateItem(currentItem, { quantity: quantity + quantityToIncrement });

  const cartItemProps = {
    product,
    quantity,
    deleteItem,
    handleQuantityOnChange,
    handleQuantityControlsOnChange,
  };

  return render(cartItemProps);
};

export default CartItem;
