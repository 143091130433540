import React from 'react';
import NumberFormat from 'react-number-format';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { toFloat } from 'utils/normalizeProduct';
import { ProductRegularPriceText } from 'components/ProductSlideItem/styles';
import { BottomTabProduct, BottomTabPrice } from './styles';

type Props = {
  price: string;
  regularPrice: string;
  CartButton: JSX.Element;
};

const BottomTab = ({ CartButton, price, regularPrice }: Props) => {
  const { width } = useWindowDimensions();

  if (width > 541) {
    return null;
  }

  return (
    <BottomTabProduct>
      <div>
        <div>
          <NumberFormat
            value={toFloat(regularPrice) || undefined}
            defaultValue="-"
            displayType="text"
            thousandSeparator="."
            decimalSeparator=","
            prefix="R$ "
            fixedDecimalScale
            decimalScale={2}
            renderText={value => <ProductRegularPriceText>{value}</ProductRegularPriceText>}
          />
        </div>
        <div>
          <BottomTabPrice
            value={toFloat(price) || undefined}
            defaultValue="-"
            displayType="text"
            thousandSeparator="."
            decimalSeparator=","
            prefix="R$ "
            fixedDecimalScale
            decimalScale={2}
          />
        </div>
      </div>
      {CartButton}
    </BottomTabProduct>
  );
};

export default BottomTab;
