import styled from 'styled-components';
import colors from 'styles/colors';
import { generateSpacing } from 'styles/spaces';

import QuotesIcon from '../../../../../assets/icons/icon_quotes.svg';

export const ClientFeedbackItemContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 500px;

  /* background-color: red; */
  @media (max-width: 900px) {
    max-width: 98%;
  }
`;
export const ClientFeedbackItemImage = styled.img`
  width: 80px;
  height: 80px;

  border-radius: 40px;

  background-color: #6a6d7122;

  transform: translateY(40%);
`;
export const ContentWrapper = styled.div`
  width: 100%;
  flex: 1;

  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid ${colors.border};
  border-radius: 8px;
  padding: ${generateSpacing(10, 12)};
`;
export const ContentTitleWrapper = styled.div`
  margin-top: 18px;
`;
export const ContentTitle = styled.span`
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 1rem;
`;
export const ContentSubTitleWrapper = styled.div`
  margin-top: 4px;
`;
export const ContentSubTitle = styled.div`
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 1rem;
  color: #757575;
  text-align: center;
`;
export const ContentTextWrapper = styled.div`
  margin-top: 24px;
  padding: 0 40px;
  width: 100%;
  overflow: hidden;

  @media (max-width: 900px) {
    padding: 0 8px;
  }
`;
export const ContentText = styled.span`
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 0.9rem;
  color: #757575;
`;
export const ContentQuotesWrapper = styled.div`
  align-self: flex-start;
`;
export const ContentQuotesImage = styled.img.attrs({
  src: QuotesIcon,
})``;
