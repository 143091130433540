import styled from 'styled-components';
import { generateSpacing } from 'styles/spaces';

export const SectionWrapper = styled.div`
  border-radius: 8px;
  padding: ${generateSpacing(10, 15)};
  min-width: 350px;

  @media (min-width: 900px) {
    background-color: #f8f8f8;
  }

  @media (min-width: 1100px) {
    min-width: 400px;
  }
`;

export const TitleWrapper = styled.div`
  margin-bottom: ${generateSpacing(10)};
`;

export const PriceFieldWrapper = styled.div`
  margin-bottom: ${generateSpacing(8)};
`;
