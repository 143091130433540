import { BaseButton } from 'components/Button';
import styled from 'styled-components';
import { RegularText } from 'styles/typographies';

interface LabelWrapperProps {
  isFocused?: boolean;
}

export const BottomTabItemContainer = styled(BaseButton)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const LabelWrapper = styled.div``;

export const Label = styled(RegularText)<LabelWrapperProps>`
  color: ${({ isFocused }) => (isFocused ? '#000' : '#757575')};

  @media (max-width: 315px) {
    font-size: 0.8rem;
  }
`;
export const IconWrapper = styled.div`
  min-height: 26px;
  display: flex;
`;
