import { BaseButton } from 'components/Button';
import styled from 'styled-components';
import { generateSpacing } from 'styles/spaces';

export const ClientsFeedbackContainer = styled.div`
  width: 100%;

  padding-bottom: ${generateSpacing(3)};
  /* background-color: red; */
`;
export const SectionTitleWrapper = styled.div`
  margin-bottom: 23px;
`;
export const ProductsWrapper = styled.div`
  margin-bottom: 23px;
  display: flex;
  justify-content: space-evenly;
`;
