import React, { useState } from 'react';
import { SelectorWrapper, SelectorHeader, SectionName } from './styles';

type Props = {
  selects: Array<{ name: string; component: React.ReactNode }>;
};

const SectionSelector = ({ selects }: Props) => {
  const [selectedSection, setSelectedSection] = useState(0);
  const [names, components] = selects.reduce<[React.ReactNode[], React.ReactNode[]]>(
    (acc, { name, component }, index) => {
      const isSelected = index === selectedSection;
      const handleClickOnChange = () => setSelectedSection(index);

      return [
        [
          ...acc[0],
          <SectionName key={name} isSelected={isSelected} onClick={handleClickOnChange}>
            {name}
          </SectionName>,
        ],
        [...acc[1], component],
      ];
    },
    [[], []],
  );

  return (
    <SelectorWrapper>
      <SelectorHeader>{names}</SelectorHeader>
      <div>{components[selectedSection]}</div>
    </SelectorWrapper>
  );
};

export default SectionSelector;
