import * as yup from 'yup';
import {
  cnpjRegex,
  zipcodeRegex,
  phoneNumberRegex,
  emailRegex,
  portugueseLocale,
} from 'utils/constants';

const paymentMethods = ['cod'];

export interface Form {
  paymentMethod: string;
  name: string;
  lastName: string;
  company: string;
  cnpj: string;
  zipcode: string;
  address: string;
  addressNumber: string;
  additionalInfo: string;
  neighborhood: string;
  city: string;
  state: string;
  telephone: string;
  cellphone: string;
  email: string;
  orderNotes: string;
  hasShippingAddress: boolean;
  termsOfAcceptance: boolean;
  shipping: {
    name: string;
    lastName: string;
    company: string;
    address: string;
    additionalInfo: string;
    addressNumber: string;
    zipcode: string;
    neighborhood: string;
    city: string;
    state: string;
  };
}

export const initialValues = {
  paymentMethod: 'cod',
  name: '',
  lastName: '',
  company: '',
  cnpj: '',
  zipcode: '',
  address: '',
  addressNumber: '',
  additionalInfo: '',
  neighborhood: '',
  city: '',
  state: '',
  telephone: '',
  cellphone: '',
  email: '',
  orderNotes: '',
  hasShippingAddress: false,
  termsOfAcceptance: false,
  shipping: {
    name: '',
    lastName: '',
    company: '',
    address: '',
    additionalInfo: '',
    addressNumber: '',
    zipcode: '',
    neighborhood: '',
    city: '',
    state: '',
  },
};

yup.setLocale(portugueseLocale);

export const addressSchema = yup.object().shape({
  name: yup.string().required(),
  lastName: yup.string().required(),
  company: yup.string(),
  address: yup.string().required(),
  additionalInfo: yup.string(),
  addressNumber: yup.string().required(),
  zipcode: yup.string().required().matches(zipcodeRegex),
  neighborhood: yup.string().required(),
  city: yup.string().required(),
  state: yup.string().required(),
});

export const schema = yup.object().shape({
  paymentMethod: yup.string().required().oneOf(paymentMethods),
  name: yup.string().required(),
  lastName: yup.string().required(),
  company: yup.string(),
  cnpj: yup.string().required().matches(cnpjRegex),
  zipcode: yup.string().required().matches(zipcodeRegex),
  address: yup.string().required(),
  addressNumber: yup.string().required(),
  additionalInfo: yup.string(),
  neighborhood: yup.string().required(),
  city: yup.string().required(),
  state: yup.string().required(),
  telephone: yup.string().required().matches(phoneNumberRegex),
  cellphone: yup.string().matches(phoneNumberRegex),
  email: yup.string().required().matches(emailRegex),
  orderNotes: yup.string(),
  hasShippingAddress: yup.boolean(),
  shipping: yup.object().when('hasShippingAddress', { is: true, then: addressSchema }),
  termsOfAcceptance: yup.boolean().oneOf([true]),
});
