import styled from 'styled-components';
import colors from 'styles/colors';
import { ReactComponent as IconPlusSVG } from 'assets/icons/icon_plus.svg';
import { ReactComponent as IconMinusSVG } from 'assets/icons/icon_minus.svg';
import NeutralButton from 'components/NeutralButton';

export const QuantityButtonsWrapper = styled.div`
  display: flex;
  align-items: center;

  width: 160px;
  justify-content: space-between;
`;

export const QuantityButton = styled(NeutralButton)`
  display: flex;
  justify-content: center;
  align-items: center;

  svg path {
    fill: ${({ disabled }) => (disabled ? colors.border : colors.focusedBorder)};
  }
`;

export const PlusIcon = styled(IconPlusSVG)``;

export const MinusIcon = styled(IconMinusSVG)``;
