import React from 'react';
import styled from 'styled-components';

export const BaseButton = styled.button`
  cursor: pointer;
  border: none;

  background: #fff;
`;

export const ButtonWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.secondary};
`;

export const InnerButton = styled(BaseButton)`
  color: ${({ theme }) => theme.colors.secondary};
`;

interface ButtonProps {
  children: React.ReactNode;
}

export const Button = ({ children }: ButtonProps) => {
  return (
    <ButtonWrapper>
      <InnerButton>{children}</InnerButton>
    </ButtonWrapper>
  );
};
