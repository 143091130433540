import React, { useState, useMemo, useEffect } from 'react';
import { TOKEN_KEY, USER_KEY } from 'utils/constants';
import { NormalizedUser } from 'utils/normalizeUser';
import wp from 'wpApi/base';

export interface UserContextValues {
  user: NormalizedUser | null;
  setUser: React.Dispatch<React.SetStateAction<NormalizedUser | null>>;
  isLoading: boolean;
}

export const UserContext = React.createContext<UserContextValues | null>(null);

const Provider = ({ children }: { children: React.ReactNode }) => {
  const [user, setUser] = useState<NormalizedUser | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loadUser = async () => {
      const stringifiedData = localStorage.getItem(USER_KEY);
      const token = localStorage.getItem(TOKEN_KEY);

      if (!stringifiedData || !token) {
        return;
      }

      const parsedUser: NormalizedUser = JSON.parse(stringifiedData);

      wp.setHeaders({ Authorization: `Bearer ${token}` });

      try {
        await wp.validate().create();
        setUser(prevState => ({ ...prevState, ...parsedUser }));
      } catch (error) {
        if (error.code === 'jwt_auth_invalid_token') {
          setUser(null);
          wp.setHeaders({ Authorization: '' });
          localStorage.clear();
        }
      }
    };

    loadUser().finally(() => {
      setIsLoading(false);
    });
  }, []);

  useEffect(() => {
    localStorage.setItem(USER_KEY, JSON.stringify(user));
  }, [user]);

  const context = useMemo(() => ({ user, setUser, isLoading }), [user, isLoading]);

  return <UserContext.Provider value={context}>{children}</UserContext.Provider>;
};

export default Provider;
