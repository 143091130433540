import React from 'react';
import { useHistory } from 'react-router-dom';
import useUser from 'hooks/useUser';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { MenuItem, LogoutIcon, PhoneIcon, HeartIcon } from './styles';

export const UserMenu = () => {
  const { isMobile } = useWindowDimensions();
  const { logout } = useUser();
  const history = useHistory();

  const goToContact = () => history.push('/contato');
  const goToWishlist = () => history.push('/favoritos');

  const baseItems = [
    { name: 'Contato', onClick: goToContact, Icon: PhoneIcon },
    { name: 'Sair', onClick: logout, Icon: LogoutIcon },
  ];

  const desktopItems = [
    { name: 'Favoritos', onClick: goToWishlist, Icon: HeartIcon },
    ...baseItems,
  ];

  const items = isMobile ? baseItems : desktopItems;

  return (
    <>
      {items.map(({ name, onClick, Icon }) => (
        <MenuItem key={name} onClick={onClick}>
          <Icon />
          {name}
        </MenuItem>
      ))}
    </>
  );
};

export default UserMenu;
