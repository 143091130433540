import React from 'react';
import { useQuery } from 'react-query';
import WooCommerceRestApi from 'woocommerceApi/base';
import useWishlist from 'hooks/useWishlist';
import normalizeProduct from 'utils/normalizeProduct';
import ProductSlideItem from 'components/ProductSlideItem';
import BottomTabNavigation from 'components/BottomTabNavigation';
import ScreenLoading from 'pages/Home/components/ScreenLoading';
import { NormalizedProduct } from '../../@types/woocommerce';
import { PageWrapper, TitleWrapper, Title } from '../styles';
import { WishesWrapper } from './styles';

type WishesProps = { wishIds: number[] };

const Wishes = ({ wishIds }: WishesProps) => {
  const { data, isLoading } = useQuery<NormalizedProduct[]>(['cartProducts', wishIds.length], () =>
    WooCommerceRestApi.get('products', { include: wishIds }).then(response =>
      response.data.map(normalizeProduct),
    ),
  );

  if (isLoading || !data) {
    return <ScreenLoading />;
  }

  return (
    <WishesWrapper>
      {data.map(product => (
        <ProductSlideItem key={product.id} product={product} />
      ))}
    </WishesWrapper>
  );
};

const Wishlist = () => {
  const { wishlist } = useWishlist();
  const wishIds = wishlist.map(({ productId }) => productId);

  return (
    <PageWrapper>
      <TitleWrapper>
        <Title>Favoritos</Title>
      </TitleWrapper>
      {wishlist.length ? (
        <Wishes wishIds={wishIds} />
      ) : (
        <span>Nenhum produto adicionado aos favoritos.</span>
      )}
      <BottomTabNavigation />
    </PageWrapper>
  );
};

export default Wishlist;
