import { css } from 'styled-components';
import { generateSpacing } from 'styles/spaces';

const buyButtonStyles = css`
  border-radius: 8px;
  padding: ${generateSpacing(6, 0)};
  width: 100%;
  color: #fff;
  font-size: 0.9rem;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  text-align: center;
  background-color: #13ab87;
`;

export default buyButtonStyles;
