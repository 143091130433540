export default {
  primary: '#FFF200',
  secondary: '#212121',
  terciary: '#FFFFFF',
  quaternary: '#454851',
  // Button auxiliar colors
  primaryPressed: '#EF5151',
  secondaryPressed: '#53BEFA',
  terciaryPressed: '#E0E0E0',
  borderButton: '#E0E0E0',
  buttonText: '#FFFFFF',
  buttonTextTerciary: '#424242',
  linkText: '#454851',
  titleText: '#454851',
  filterBackground: '#D9DADE',
  seletedBackground: '#454851',
  notSeletedBackground: '#D9DADE',
  disabledBackground: '#D9DADE',
  healthyBackground: '#26B99A',
  healthyTransparentBackground: '#26B99A1A',
  headerStackBackground: '#454851',
  // Text auxiliar colors
  emptyText: '#757575',
  titleBottomSheetText: '#424242',
  infoCardMessageText: '#757575',
  infoCardTitleText: '#424242',
  headerTitleStackGray: '#454851',
  headerTitleStackWhite: '#FFFFFF',
  // Input auxiliar colors
  border: '#E5E5E5',
  focusedBorder: '#424242',
  error: '#D60000',
  placeholder: '#9E9E9E',
  inputText: '#212121',
  // Background colors
  backgroundPrimary: '#E5E5E5',
  backgroundSecondary: '#FFFFFF',
  backgroundTerciary: '#00000066',
  resultListItembackground: '#F5F5F5',
  pendingBackground: '#F29C1F',
  // Inspection colors
  onTime: '#26B99A',
  delayed: '#FF7979',
  finished: '#26B99A',
  // AnomalyTreatments colors
  completed: '#26B99A',
  pending: '#FF7979',
  // Inspection colors
  synchronized: '#26B99A',
  incomplet: '#FF7979',
  notSynchronized: '#454851',
};
