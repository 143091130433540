import useWindowDimensions from 'hooks/useWindowDimensions';
import React from 'react';

import {
  TopHeaderContainer,
  LeftContent,
  TruckIcon,
  TextWrapper,
  Text,
  BoldedText,
  CenterContent,
  PointIcon,
  PhoneIcon,
} from './styles';

const TopHeader = () => {
  const { isMobile } = useWindowDimensions();

  return (
    <TopHeaderContainer>
      <LeftContent>
        <TruckIcon />

        <TextWrapper>
          <Text>
            <BoldedText>Entrega grátis </BoldedText>
            para pedidos acima de R$ 499,90!
          </Text>
        </TextWrapper>
      </LeftContent>

      {!isMobile && (
        <CenterContent>
          <PointIcon />
        </CenterContent>
      )}

      <LeftContent>
        <PhoneIcon />

        <TextWrapper>
          <Text>
            <BoldedText>Televendas </BoldedText>
            0800-123456
          </Text>
        </TextWrapper>
      </LeftContent>
    </TopHeaderContainer>
  );
};

export default TopHeader;
