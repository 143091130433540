import styled from 'styled-components';
import colors from 'styles/colors';
import { generateSpacing } from 'styles/spaces';
import MarkIcon from '../../assets/icons/check_mark.svg';

export const InvisibleCheckbox = styled.input.attrs({
  type: 'checkbox',
})`
  display: none;
`;

export const CustomCheckboxWrapper = styled.div<{ checked: boolean | undefined }>`
  display: inline-block;
  background-color: ${({ checked }) => (checked ? colors.primary : colors.border)};
  margin-right: ${generateSpacing(5)};
  border-radius: 4px;
  padding: 3px;
  vertical-align: middle;
`;

export const CustomCheckbox = styled.div<{ checked: boolean | undefined }>`
  width: 11px;
  height: 11px;
  cursor: pointer;
  background-image: ${({ checked }) => (checked ? `url(${MarkIcon})` : 'none')};
  background-size: center;
  background-repeat: no-repeat;
`;
