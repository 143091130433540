import React from 'react';
import { useLocation } from 'react-router-dom';
import BottomTabNavigation from 'components/BottomTabNavigation';
import { ReactComponent as MarkIcon } from 'assets/icons/check_mark_white.svg';
import {
  InstructionParagraph,
  Instructions,
  MarkIconWrapper,
  SuccessfulOrderWrapper,
  Title,
  TitleWrapper,
} from './styles';

const SuccessfulOrder = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  return (
    <>
      <SuccessfulOrderWrapper>
        <div>
          <MarkIconWrapper>
            <MarkIcon />
          </MarkIconWrapper>
          <TitleWrapper>
            <Title>Seu pedido foi faturado com sucesso!</Title>
          </TitleWrapper>
          <Instructions>
            <InstructionParagraph>
              <span>
                Te notificaremos sobre o andamento da entrega através de seu email{' '}
                {query.get('email') || ''}. Ou você pode consultar através do menu no topo, em
                Rastrear Meu Pedido.
              </span>
            </InstructionParagraph>
            <InstructionParagraph>
              Lembre-se que o pagamento deverá ser feito no ato da entrega.
            </InstructionParagraph>
          </Instructions>
        </div>
      </SuccessfulOrderWrapper>
      <BottomTabNavigation />
    </>
  );
};

export default SuccessfulOrder;
