import React from 'react';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { Switch, Route, RouteProps as RouterRouteProps } from 'react-router-dom';
import { ConnectedHome } from 'pages';
import Products from 'pages/Products';
import ProductsMobile from 'pages/Products/mobile';
import Product from 'pages/ProductPage';
import CartPage from 'pages/CartPage';
import Payment from 'pages/Payment';
import SuccessfulOrder from 'pages/SuccessfulOrder';
import Login from 'pages/Login';
import Contact from 'pages/Contact';
import NotFoundPage from 'pages/NotFoundPage';
import Wishlist from 'pages/Wishlist';
import ScrollToTop from 'components/ScrollToTop';
import PrivateRoute, { Props as PrivateRouteProps } from 'components/PrivateRoute';

type RouteProps = { isPrivate?: boolean } & RouterRouteProps & PrivateRouteProps;

const routes = (isMobile: boolean): RouteProps[] => {
  const defaultConfig = {
    isPrivate: true,
    exact: true,
    hasExtraComponents: !isMobile,
  };

  return [
    { path: '/', hasExtraComponents: true, isPrivate: true, exact: true, component: ConnectedHome },
    { ...defaultConfig, path: '/produtos', component: isMobile ? ProductsMobile : Products },
    { ...defaultConfig, path: '/produtos/:id', component: Product },
    { ...defaultConfig, path: '/carrinho', component: CartPage },
    { ...defaultConfig, path: '/faturamento', component: Payment },
    { ...defaultConfig, path: '/pedido-sucesso', component: SuccessfulOrder },
    { ...defaultConfig, path: '/contato', component: Contact },
    { ...defaultConfig, path: '/favoritos', component: Wishlist },
    { path: '/login', exact: true, component: Login },
    { path: '/', component: NotFoundPage },
  ];
};

export default function Routes() {
  const { isMobile } = useWindowDimensions();

  return (
    <>
      <ScrollToTop />
      <Switch>
        {routes(isMobile).map(({ hasExtraComponents, isPrivate, exact, ...rest }, index) =>
          isPrivate ? (
            <PrivateRoute
              key={index}
              exact={!!exact}
              hasExtraComponents={hasExtraComponents}
              {...rest}
            />
          ) : (
            <Route key={index} exact={!!exact} {...rest} />
          ),
        )}
      </Switch>
    </>
  );
}
