import React, { useState } from 'react';
import cep, { CEP } from 'cep-promise';
import NumberFormat from 'react-number-format';
import useCart from 'hooks/useCart';
import { Zipcode } from 'contexts/CartContext';
import {
  ActionSection,
  ActionInput,
  ActionTitle,
  ActionInputLabel,
  ActionSectionButton,
  ErrorText,
} from '../../styles';
import { DetailText } from '../CartItem/styles';

const formatZipcode = ({ city, street, neighborhood, state }: Zipcode) =>
  `${street}, ${neighborhood}, ${city}/${state} - ${'Brasil'}`;

const ZipcodeSection = () => {
  const [zipcodeText, setZipcodeText] = useState('');
  const [error, setError] = useState<string>();
  const { zipcode, setZipcode } = useCart();

  const handleZipcodeInputOnChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setZipcodeText(event.target.value);

  const handleButtonOnClick = () => {
    cep(zipcodeText)
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .then(({ service: _, cep: actualCep, ...newZipcode }: CEP) => {
        const newCep = `${actualCep.slice(0, 5)}-${actualCep.slice(5)}`;
        setZipcode({ ...newZipcode, cep: newCep });
        setError(undefined);
      })
      .catch(() => {
        setError('CEP não encontrado');
      });
  };

  return (
    <ActionSection>
      <ActionTitle>Prazo e frete</ActionTitle>
      <ActionInputLabel>CEP:</ActionInputLabel>
      <NumberFormat
        customInput={ActionInput}
        value={zipcode ? zipcode.cep : zipcodeText}
        disabled={!!zipcode}
        onChange={handleZipcodeInputOnChange}
        placeholder="00000-000"
        format="#####-###"
        mask="_"
      />
      <ErrorText>{error}</ErrorText>
      {zipcode ? (
        <DetailText>{formatZipcode(zipcode)}</DetailText>
      ) : (
        <ActionSectionButton onClick={handleButtonOnClick}>Atualizar</ActionSectionButton>
      )}
    </ActionSection>
  );
};

export default ZipcodeSection;
