import styled from 'styled-components';
import colors from 'styles/colors';
import { generateSpacing } from 'styles/spaces';
import { SectionTitle, filterItem } from 'styles/typographies';
import ButtonReset from 'styles/buttonReset';
import { PageWrapper } from '../styles';

export const BadgesWrapper = styled.div`
  margin-bottom: ${generateSpacing(20)};
`;

export const ProductsWrapper = styled(PageWrapper)`
  min-height: 100%;
  display: flex;
  justify-content: space-between;
`;

export const Sidebar = styled.div``;

export const ProductsLoadingWrapper = styled.div`
  margin-top: ${generateSpacing(30)};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Body = styled.div`
  flex-basis: 65%;
  margin-left: 20px;
`;

export const NotFoundText = styled.span`
  font-family: 'Montserrat', 'sans-serif';
  font-weight: 500;
`;

export const ProductsList = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
`;

export const FilterWrapper = styled.div`
  border: 1px solid ${colors.border};
  border-radius: 12px;
  padding: ${generateSpacing(14, 14)};
  margin-bottom: ${generateSpacing(14)};
`;

export const FilterName = styled(SectionTitle)`
  font-size: 1.1rem;
`;

export const ItemName = styled.div<{ isSelected: boolean }>`
  ${filterItem}
  display: inline-block;
  font-weight: ${({ isSelected }) => (isSelected ? '600' : '500')};

  @media (max-width: 900px) {
    font-size: 0.85rem;
  }
`;

export const ItemLabel = styled.label<{ isSelected: boolean }>`
  ${filterItem}
  font-weight: ${({ isSelected }) => (isSelected ? '600' : '500')};
`;

export const FilterField = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${generateSpacing(8)};
`;

export const SeeMoreButton = styled(ButtonReset).attrs({ type: 'button' })`
  margin-top: ${generateSpacing(10)};
  width: 100%;
  text-align: center;
  color: ${colors.emptyText};
`;

export const SeeMoreProductsButton = styled(ButtonReset)`
  display: block;
  margin: ${generateSpacing(40)} auto 0;
  padding: ${generateSpacing(4)};
  border: 1px solid ${colors.emptyText};
  border-radius: 6px;

  font-size: 1rem;
`;

export const PriceFilterValues = styled.div`
  display: flex;
  justify-content: space-between;

  color: #757575;
`;
