import React, { useState } from 'react';
import colors from 'styles/colors';
import SearchInput from 'components/Input/Search';
import BottomTabNavigation from 'components/BottomTabNavigation';
import useQueryParam from 'hooks/useQueryParam';
import useModal from 'hooks/useFilterModal';
import { getWindowDimensions } from 'hooks/useWindowDimensions';
import { openModalActions, ModalEvent } from 'contexts/FilterModalContext';
import FilterModal from '../components/FilterModal';
import SearchHeader from '../components/SearchHeader';
import { NotFoundText, ProductsList, SeeMoreProductsButton } from '../styles';
import { PageProps, ProductsLoading, ProductsPage } from '..';
import {
  FilterBar,
  FilterBarItem,
  FiltersWrapper,
  ProductsMobileWrapper,
  SearchInputWrapper,
} from './styles';

const ProductsMobile = ({
  categories,
  currentCategory,
  searchQuery,
  hasLoaded,
  mappedProducts,
  isFetchingNextPage,
  fetchNextPage,
  hasNextPage,
}: PageProps) => {
  const [searchText, setSearchText] = useState('');
  const { openModal } = useModal();
  const setQueryParam = useQueryParam();
  const { width } = getWindowDimensions();

  const handleChangeOnSearchInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const handleSearch = (event: React.FormEvent) => {
    event.preventDefault();

    setQueryParam([{ search: searchText }], '/produtos');
  };

  const filters: { name: string; color?: string; modal: ModalEvent; animation?: boolean }[] = [
    {
      name: currentCategory ? currentCategory.name : 'CATEGORIAS',
      color: currentCategory && colors.primary,
      modal: openModalActions.openCategoryFilter(categories),
    },
    { name: 'FAIXA DE PREÇO', modal: openModalActions.openPriceFilter(), animation: width < 550 },
  ];
  const filterItems = filters.map(({ name, color, modal, animation }) => (
    <FilterBarItem key={name} color={color} onClick={() => openModal(modal, animation)}>
      {name}
    </FilterBarItem>
  ));

  return (
    <>
      <FilterModal />
      <ProductsMobileWrapper>
        <SearchInputWrapper>
          <SearchInput
            value={searchText}
            onChange={handleChangeOnSearchInput}
            onSearch={handleSearch}
            placeholder="Procure por código, nome, marca..."
          />
        </SearchInputWrapper>
        <FilterBar>{!hasLoaded && <FiltersWrapper>{filterItems}</FiltersWrapper>}</FilterBar>
        <SearchHeader categoryName={currentCategory?.name} search={searchQuery} />
        {hasLoaded ? <ProductsLoading /> : <ProductsList>{mappedProducts}</ProductsList>}
        {isFetchingNextPage && <ProductsLoading />}
        {hasNextPage && !isFetchingNextPage ? (
          <SeeMoreProductsButton onClick={fetchNextPage}>Veja mais</SeeMoreProductsButton>
        ) : null}
        {!hasLoaded && !mappedProducts.length ? (
          <div>
            <NotFoundText>Nenhum resultado foi encontrado</NotFoundText>
          </div>
        ) : null}
      </ProductsMobileWrapper>
      <BottomTabNavigation />
    </>
  );
};

export default () => <ProductsPage>{pageProps => <ProductsMobile {...pageProps} />}</ProductsPage>;
