import React from 'react';
import { useMutation, useQuery } from 'react-query';
import * as yup from 'yup';
import {
  ErrorMessage,
  Field,
  Form,
  Formik,
  FormikHelpers,
  useField,
  useFormikContext,
} from 'formik';
import WooCommerceApi from 'woocommerceApi/base';
import getStars from 'components/Stars';
import { SectionTitle } from 'styles/typographies';
import useUser from 'hooks/useUser';
import { portugueseLocale } from 'utils/constants';
import { Spinner } from 'pages/Home/components/ScreenLoading/styles';
import { ErrorMessageWrapper } from 'pages/Login/styles';
import { Review } from '../../../../@types/woocommerce';
import {
  CommentSectionTitle,
  Comment,
  ReviewBody,
  Reviewer,
  ReviewHeader,
  Reviews,
  ReviewWrapper,
  StarsWrapper,
  SpinnerCentralized,
  StarsReview,
  StarsAvaliationText,
  ReviewSubmit,
  ReviewSubmitButton,
  ReviewSubmitButtonText,
  CommentFieldWrapper,
} from './styles';

const removeHTML = (HTMLString: string) => HTMLString.replace(/(<([^>]+)>)/gi, '');

type Props = {
  productId: number;
};

interface IRatingForm {
  rating: number;
  review: string;
}

interface IError {
  response: {
    data: {
      message: string;
    };
  };
}

yup.setLocale({ ...portugueseLocale, number: { min: 'Selecione sua avaliação' } });

const schema = yup.object().shape({
  rating: yup.number().min(1),
  review: yup.string().required(),
});

const initialValues = {
  rating: 0,
  review: '',
};

const RatingsForm = ({ isSuccess, error }: { isSuccess: boolean; error: IError }) => {
  const { isSubmitting } = useFormikContext();
  const [{ value }, , { setValue }] = useField<number>('rating');

  const handleStarsOnChange = isSuccess ? undefined : (index: number) => setValue(index + 1);

  return (
    <Form>
      <CommentFieldWrapper>
        <Field
          disabled={isSuccess}
          as={Comment}
          name="review"
          placeholder="Escreva aqui sua avaliação..."
        />
        <ErrorMessageWrapper>
          <ErrorMessage name="review" />
        </ErrorMessageWrapper>
      </CommentFieldWrapper>
      <StarsReview>
        <StarsAvaliationText>Avalie o produto:</StarsAvaliationText>
        <StarsWrapper>{getStars(value - 1, handleStarsOnChange)}</StarsWrapper>
      </StarsReview>
      <ErrorMessageWrapper>
        <ErrorMessage name="rating" />
      </ErrorMessageWrapper>
      <ReviewSubmit>
        {isSubmitting && (
          <SpinnerCentralized>
            <Spinner />
          </SpinnerCentralized>
        )}
        {error && <ErrorMessageWrapper>{error.response.data.message}</ErrorMessageWrapper>}
        <ReviewSubmitButton disabled={isSuccess || isSubmitting}>
          <ReviewSubmitButtonText>{isSuccess ? 'AVALIADO' : 'ENVIAR'}</ReviewSubmitButtonText>
        </ReviewSubmitButton>
      </ReviewSubmit>
    </Form>
  );
};

const Ratings = ({ productId }: Props) => {
  const { user } = useUser();
  const mutation = useMutation((values: IRatingForm) =>
    WooCommerceApi.post('products/reviews', {
      ...values,
      product_id: productId,
      reviewer: user?.username,
      reviewer_email: user?.email,
    }),
  );

  const { data, isLoading, error } = useQuery<Review[]>(['ratings', productId], () =>
    WooCommerceApi.get('products/reviews', { per_page: 2, product: productId }).then(
      response => response.data,
    ),
  );

  const handleSubmit = (values: IRatingForm, { setSubmitting }: FormikHelpers<IRatingForm>) => {
    mutation.mutateAsync(values).finally(() => setSubmitting(false));
  };

  if (isLoading || error || !data) {
    return (
      <SpinnerCentralized>
        <Spinner />
      </SpinnerCentralized>
    );
  }

  const reviews = data.map(({ id, rating: reviewRating, reviewer, review }) => (
    <ReviewWrapper key={id}>
      <ReviewHeader>
        <Reviewer>{reviewer}</Reviewer>
        <StarsWrapper>{getStars(reviewRating)}</StarsWrapper>
      </ReviewHeader>
      <ReviewBody>{removeHTML(review)}</ReviewBody>
    </ReviewWrapper>
  ));

  return (
    <>
      {reviews.length ? <Reviews>{reviews}</Reviews> : null}
      <CommentSectionTitle>
        <SectionTitle>Deixe sua avaliação:</SectionTitle>
      </CommentSectionTitle>
      <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={schema}>
        <RatingsForm isSuccess={mutation.isSuccess} error={mutation.error as IError} />
      </Formik>
    </>
  );
};

export default Ratings;
