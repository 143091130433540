import styled from 'styled-components';
import { generateSpacing } from 'styles/spaces';
import { Title as titleCSS } from 'styles/typographies';

export const PageWrapper = styled.div`
  margin: ${generateSpacing(5)} auto;
  width: 90%;
  min-height: 64%;

  @media (max-width: 900px) {
    padding-bottom: 90px;
  }

  @media (min-width: 900px) {
    margin: ${generateSpacing(20)} auto;
  }
`;

export const TitleWrapper = styled.div`
  margin-bottom: ${generateSpacing(18)};
`;

export const Title = styled.span`
  ${titleCSS}
`;
