import React, { useEffect } from 'react';

const useOutsideClick = <T extends HTMLElement>(
  ref: React.MutableRefObject<null | T>,
  callback: (event: MouseEvent) => unknown,
) => {
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      const { current } = ref;
      if (current !== null && event.target instanceof Node) {
        if (!current.contains(event.target)) {
          callback(event);
        }
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, callback]);
};

export default useOutsideClick;
