import React from 'react';

import TopHeader from './TopHeader';
import NavBar from './NavBar';

import { HeaderContainer } from './styles';

const Header = () => {
  return (
    <HeaderContainer>
      <TopHeader />
      <NavBar />
    </HeaderContainer>
  );
};

export default Header;
